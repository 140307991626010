import type { FieldOptionItem } from './../types';
import { InputOptionType } from '@/src/typings/enums/enums';
import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export interface FormElementSelectSettingsData extends BaseFormElementSettingsData {
  option_type?: InputOptionType;
  default_option?: string;
  options_interval?: {
    interval_from: string;
    interval_to: string;
  };
  options?: {
    [key: string]: string;
  };
  options_line?: string;
  paste_options?: string;
  top_countries?: Array<string>;
  disable_default_country?: string;
  select_search_enabled: string;
}

export interface FormElementSelectData extends BaseFormElementData {
  settings?: FormElementSelectSettingsData;
  options?:
    | {
        [key: string]: string;
      }
    | string[];
}

export interface FormElementSelectState extends BaseFormElementState<string> {
  isEmpty: boolean;
  elementMessage: string;
  optionType?: InputOptionType;
  defaultOptionValue?: string;
  optionsInterval?: {
    intervalFrom: number;
    intervalTo: number;
  };
  options?: FieldOptionItem[];
  topCountries?: Array<string>;
  disableDefaultCountry?: boolean;
  selectSearchEnabled?: boolean;
}

export class FormElementSelectModel extends BaseFormElementModel<
  string,
  FormElementSelectData,
  FormElementSelectState
> {
  parseFormElement(data: FormElementSelectData) {
    const state = this.state;
    state.defaultOptionValue = data.settings?.default_option ? data.settings?.default_option : undefined;
    state.optionType = data.settings?.option_type ?? InputOptionType.LINE;
    state.topCountries = data.settings?.top_countries;
    state.disableDefaultCountry = data.settings?.disable_default_country === '1';
    state.selectSearchEnabled = data.settings?.select_search_enabled === '1';

    switch (this.state.optionType) {
      case InputOptionType.INTERVAL:
        if (data.settings?.options_interval) {
          state.optionsInterval = {
            intervalTo: Number(data.settings?.options_interval.interval_to),
            intervalFrom: Number(data.settings?.options_interval.interval_from)
          };

          const options: FieldOptionItem[] = [];

          for (let i = state.optionsInterval.intervalFrom; i <= state.optionsInterval.intervalTo; i++) {
            options.push({
              value: `${i}`,
              label: `${i}`
            });
          }

          state.options = options;
        } else {
          state.options = undefined;
        }
        break;

      case InputOptionType.OPTIONS:
        this.state.options = data.settings?.options ? this.parseFieldOptions(data.settings.options) : undefined;
        break;

      case InputOptionType.LINE:
        this.state.options = data.options
          ? Object.entries(data.options).map<FieldOptionItem>((value) => {
              return {
                value: value[1],
                label: value[1]
              };
            })
          : undefined;
        break;

      case InputOptionType.PASTE:
      case InputOptionType.COUNTRIES:
        if (data.options && !Array.isArray(data.options)) {
          const options: FieldOptionItem[] = [];

          for (const optionIndex in data.options) {
            if (Object.prototype.hasOwnProperty.call(data.options, optionIndex)) {
              options.push({
                value: optionIndex,
                label: data.options[`${optionIndex}`]
              });
            }
          }

          this.state.options = options;
        } else {
          this.state.options = undefined;
        }
        break;

      default:
        this.state.options = undefined;
    }

    state.isEmpty = !state.options || state.options.length === 0;

    if (!state.options) {
      state.options = [
        {
          value: 'No results found',
          label: 'No results found'
        }
      ];
    }
  }

  getInitialValue(): string | undefined {
    const initialStringValue = this.getInitialStringValue();

    if (initialStringValue !== null && initialStringValue !== undefined) {
      const existsInOptions = this.state.options?.some((option) => option.value === initialStringValue) ?? false;

      if (existsInOptions) {
        return initialStringValue;
      }
    }

    return this.state.defaultOptionValue;
  }

  parseStringValue(value: string): string | undefined {
    return value;
  }

  getStringifiedValue(): string | undefined {
    return this.state.value && this.state.value !== '' ? this.state.value : undefined;
  }

  getSerializedPostValue(): string {
    return this.state.value ?? '';
  }

  getSerializedCookieValue(): string {
    return this.state.value ?? '';
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
