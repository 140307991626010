import { getAdminUiEndpoint, getQueryParams } from '@/src/utilities/Url';
import { useUtilityStore } from '@/src/store/utility';
import type { ReplacementTags } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import { BaseModel } from '@/src/models/BaseModel';
import type {
  AddonEditItem,
  CampaignAdvancedData,
  CampaignConfigData,
  CampaignData,
  CampaignLayoutButtonsData,
  CampaignLayoutData,
  CampaignRedirectData,
  ConfigLogoData,
  StylesData
} from '@/src/typings/interfaces/data/campaign';
import useDate from '@/src/hooks/useDate';
import type { SectionData } from '@/src/typings/interfaces/data/section';
import type { DeviceData } from '@/src/hooks/useDevice';
import useDevice, { getDeviceData } from '@/src/hooks/useDevice';
import type { AlignContentType, CookieConsentProvider } from '@/src/typings/enums/enums';
import { CampaignAdsSizeType, CampaignDeviceType, FieldType, SectionType } from '@/src/typings/enums/enums';
import { SectionModel } from '@/src/components/layout/section/SectionModel';
import { FlowpageModel } from '@/src/components/layout/FlowpageModel';
import { PopoverModel } from '@/src/models/PopoverModel';
import {
  adjustBrightness,
  createEditFormUrl,
  formatColor,
  hasNumberValue,
  hexToRGBA,
  inIframe,
  isValidValue,
  replaceFontIdWithFontString,
  setAdsBodyclasses,
  setDesktopBodyClasses,
  setMobileBodyClasses,
  setTabletBodyClasses
} from '@/src/utilities/Utilities';
import useAxios from '@/src/hooks/useAxios';
import type { SectionModelType } from '@/src/typings/types/types';
import { BlockTypes } from '@/src/typings/types/types';
import type { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';
import { initializeConsentProvider } from '@/src/services/cookieConsent';
import type { IntegrationModel } from '@/src/models/integrations/types';
import { IntegrationModelMapping } from '@/src/models/integrations/types';
import type { SaveOrderResponse } from '@/src/typings/interfaces/state/Editing';
import type { FormElementModel } from '@/src/components/addons/registration/types';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';
import MessagesHandlerModel from '@/src/models/messages/MessagesHandlerModel';
import type { AddonGameplayModel } from '@/src/components/addons/gameplay/Model';
import { ControlCodeTypes } from '@/src/components/integrations/bulk-prize-vouchers/Model';
import { formatDate } from '@/src/utilities/DateHelpers';
import { useEditingStore } from '@/src/store/editing';

interface AddSectionResponse {
  page_id: number;
}

interface CampaignButtonState {
  addShadow?: boolean;
  backgroundColor?: string;
  textColor?: string;
  verticalPadding?: number;
  horizontalPadding?: number;
  fontFamily?: string;
  textSize?: number;
  textWeight?: number;
  letterSpacing?: number;
  borderColor?: string;
  borderThickness?: number;
  borderRadius?: number;
  lineHeight?: number;
  buttonWidth?: string;
  backgroundColorHover?: string;
  textColorHover?: string;
  borderColorHover?: string;
  buttonEffect?: string;
  textStyle?: string[];
  hoverTextStyle?: string[];
  shadowBlur?: number;
  shadowColor?: string;
  shadowHorizontal?: number;
  shadowSpread?: number;
  shadowVertical?: number;
  overwrite?: boolean;
}

interface CampaignLayoutButtonsState extends CampaignButtonState {
  secondary?: CampaignButtonState;
}

interface GridState {
  pixelSize?: number;
  align?: AlignContentType;
  typePixel?: boolean;
  typePercent?: boolean;
  percentSize?: number;
}

interface CampaignLayoutScrollIndicatorState {
  enable?: boolean;
  color?: string;
}

type GridDeviceTypes = 'desktop' | 'tablet';

interface CampaignLayoutFlowState {
  transition?: string;
  overwrite?: boolean;
  overlay?: boolean;
}

export interface CampaignLayoutState {
  baseColor?: string;
  backgroundColor?: string;
  textColor?: string;
  buttonTextColor?: string;
  backgroundImage?: string;
  backgroundType?: string;
  backgroundOverlay?: string;
  backgroundAttachmentFixed?: boolean;
  backgroundPosition?: string;
  fontFamily?: string;
  buttonStyle?: string;
  logo?: string;
  flow?: CampaignLayoutFlowState;
  customPopoverColors?: boolean;
  popoverBackgroundColor?: string;
  popoverTextColor?: string;
  overwrite?: boolean;
  scrollIndicator?: CampaignLayoutScrollIndicatorState;
  grid?: GridState;
  styles?: StyleElementState[];
  buttons?: CampaignLayoutButtonsState;
  focus?: {
    color: string;
    width: number;
  };
}

interface StyleElementState {
  element?: string;
  type?: string;
  size?: number;
  color?: string;
  lineHeight?: number;
  weight?: number;
  style?: string[];
}

interface CustomFont {
  id: number;
  css: string;
  fontFamily: string;
  name?: string;
}

export enum CampaignSolutionEnvironment {
  LOCAL,
  DEV,
  STAGING,
  PRODUCTION
}

export interface CampaignCustomSolution {
  id: string;
  environment: CampaignSolutionEnvironment;

  assets: {
    css: boolean;
    js: boolean;
  };

  settings: unknown;
}

interface CampaignConfigState {
  campaignId: number;
  customerId: number;
  title: string;
  name: string;
  gtmId?: string;

  time?: {
    unix: number;
    formatted: string;
  };

  customSolutions: CampaignCustomSolution[];

  campaignApiRoot?: string;
  leadScoreEnabled: boolean;
  gameAlias: string;
  gameIcon: string;
  assetUrl: string;
  favicon?: string;
  apiEndpoint?: string;

  ipstackApi?: string;
  googleMapsApiKey?: string;
  languageCode: string;
  timezone: string;
  activeFrom?: string;
  activeTo?: string;
  globalLayout: boolean;
  enableOnlyAllowViewingInIframe: boolean;
  enableStatistics: boolean;
  enableZoom: boolean;
  expired: boolean;
  uploadProxy?: string;
  ghostUser: boolean;
  deactivateInternalLinkTracking: boolean;
  utmEnabled: boolean;
  utmSource: string;
  utmCampaign: string;
  utmMedium: string;
  utmContent: string;
  customFonts: CustomFont[];
  deactivateGa?: number;
  hasRegistrations: boolean;
  hasLeaderboard: boolean;
  hash: string;
  cookieConsent?: CookieConsentProvider;
  cookiesEnabled: boolean;
  userRecognition?: {
    mapping: Record<string, number>;
    messages: Record<string, MessagesHandlerModel>;

    parameters: string[];

    allowUnrecognisedUsers: boolean;
    waitForTokens: boolean;
  };

  integrations: IntegrationModel[];

  resources: {
    before_body_close?: string;
    before_body_start?: string;
    css?: string;
    head?: string;
  };

  facebookId?: string;
  facebookVerificationId?: string;
  metatags: Record<string, string>[];

  logo?: string;

  replacementTags?: Record<string, string>;

  hasBulkPrizeIntegration?: boolean;
  isWhiteLabel?: boolean;
}

export interface CampaignState {
  id: number;
  name: string;
  contentReady: boolean;
  triggerReloadCampaign: boolean;
  deviceType: CampaignDeviceType;
  adDimensions?: string[];
  adsSizeType?: CampaignAdsSizeType;
  edit?: {
    enabled?: boolean;
    csrfToken?: string;
    addons?: Record<string, AddonEditItem>;
  };
  isEditModeActive: boolean;
  isPreviewModeActive: boolean;
  editLayoutSettingsUrl: string;
  sections: SectionModel[];
  globalLayout?: CampaignLayoutState;
  layout?: CampaignLayoutState;
  config?: CampaignConfigState;
  flowPages: FlowpageModel[];
  redirect?: CampaignRedirectData;
  popovers: PopoverModel[];
  globalStyling?: string;
  campaignStyling: string;

  buttonStylingHover: string;
  buttonStylingHoverGlobal: string;

  advanced: CampaignAdvancedState;

  // messages: Message;
  messages: CampaignMessagesState;
  isPopup: boolean;
}

export interface CampaignMessagesState {
  messageGameLimit: MessagesHandlerModel;
  messageOverallLimit: MessagesHandlerModel;
  messageExpired: MessagesHandlerModel;
  messageExpiredCampaign: MessagesHandlerModel;
  messageCheating: MessagesHandlerModel;
  messagePausedCampaign: MessagesHandlerModel;
  messageCalenderNoFields?: MessagesHandlerModel;
  messageCalenderAllScratched?: MessagesHandlerModel;
  messageJwtExpired: MessagesHandlerModel;
  messageWordRiddleNoActive?: MessagesHandlerModel;
}

interface CampaignAdvancedState {
  javascript?: string;
  css?: {
    css: string;
  };
  externalcss?: {
    name: string;
    url: string;
  };
  preloader?: PreloaderState;
  favicon?: string;
  retention?: RetentionState;
  controlCode: ControlCodeState;
}

export interface PreloaderState {
  type?: string;
  color?: string;
  effect?: string;
  image?: string;
  keepSize?: boolean;
  overlay?: OverlayState;
}

export interface OverlayState {
  isEnabled?: boolean;
  color?: string;
}

export interface RetentionState {
  timing?: string;
}

export interface ControlCodeState {
  label: string;
  backgroundColor?: string;
  enabled?: boolean;
  textColor?: string;
  type?: ControlCodeTypes;
}

function overwriteLookupCheck<E>(checkObj: E & { overwrite?: string }) {
  return checkObj === undefined || typeof checkObj.overwrite === 'undefined' || checkObj.overwrite + '' === '1';
}

export class CampaignModel extends BaseModel<CampaignData, CampaignState> {
  public id: number;
  public hash: string;

  constructor(data: CampaignData) {
    super(data);

    /**
     * Set id and hash on model, so we can inherit this in other models
     */
    this.id = data.config.campaign_id;
    this.hash = data.config.hash;

    const campaignStore = useCampaignStore();
    campaignStore.model = this;

    this.parse(data);

    const state = this.state;

    // Initialize cookie consent after parsing campaign data.
    if (state.config?.cookieConsent) {
      initializeConsentProvider(state.config.cookieConsent);
    }
  }

  shallSkipInitialParse(): boolean {
    return true;
  }

  parse(data: CampaignData) {
    const campaignStore = useCampaignStore();
    const queryParameters = getQueryParams();
    const state = this.state;

    this.id = state.id = data.config.campaign_id;
    this.hash = data.config.hash;

    switch (data.config.device_type) {
      case 'MOBILE_ONLY':
        state.deviceType = CampaignDeviceType.MOBILE_ONLY;
        break;

      case 'ADS':
        state.deviceType = CampaignDeviceType.ADS;
        break;

      default:
        state.deviceType = CampaignDeviceType.ALL_DEVICES;
    }

    if (state.deviceType === CampaignDeviceType.ADS) {
      state.adDimensions = data.config.ad_dimensions;

      switch (data.config.ad_size_type) {
        case 'RESPONSIVE':
          state.adsSizeType = CampaignAdsSizeType.RESPONSIVE;
          break;

        default:
          state.adsSizeType = CampaignAdsSizeType.FIXED;
      }
    } else {
      state.adDimensions = state.adsSizeType = undefined;
    }

    state.contentReady = state.contentReady || false;
    state.isPopup = ['popup', 'popupv2'].includes(data.config.game_alias);
    state.name = data.config?.name;
    state.triggerReloadCampaign = false;
    state.isPreviewModeActive = state.isPreviewModeActive || false;
    state.redirect = data.pages?.redirect;

    // we only set it if we got edit mode / user is logged in
    state.edit = state.edit ?? {};
    if (data.config.edit?.enabled && !inIframe() && !queryParameters.size) {
      if (data.config?.edit?.csrf_token) {
        state.edit.csrfToken = data.config.edit.csrf_token;
      } else {
        state.edit.csrfToken = undefined;
      }
      if (data.config?.edit?.enabled) {
        state.edit.enabled = data.config.edit.enabled;
      } else {
        state.edit.enabled = false;
      }
      if (data.config?.edit?.addons) {
        state.edit.addons = data.config.edit.addons;
      } else {
        state.edit.addons = undefined;
      }

      state.isEditModeActive =
        typeof window !== 'undefined' ? state.edit.enabled && window.location.href.includes('edit=1') : false;
    } else {
      state.edit = undefined;
    }

    state.config = this.constructCampaignConfigState(data.config);

    if (data.global_layout && data.config.global_layout && !state.isPopup) {
      state.globalLayout = this.parseLayoutData(data.global_layout);
    }

    if (data.layout) {
      state.layout = this.parseLayoutData(data.layout);
    }

    CampaignModel.constructCampaignMessagesState(data.advanced, state);

    state.advanced = CampaignModel.constructAdvancedState(data.advanced);

    let layoutKey = 'site--desktop';
    const { isMobile, isTablet, isDesktop } = useDevice();

    if (state.deviceType === CampaignDeviceType.ADS && state.adsSizeType === CampaignAdsSizeType.FIXED) {
      setAdsBodyclasses();
      layoutKey = 'site--ads';
    } else if (isMobile) {
      setMobileBodyClasses(campaignStore.currentDevice !== null);
      layoutKey = 'site--mobile';
    } else if (isTablet) {
      layoutKey = 'site--tablet';
      setTabletBodyClasses(campaignStore.currentDevice !== null);
    } else if (isDesktop) {
      layoutKey = 'site--desktop';
      setDesktopBodyClasses(campaignStore.currentDevice !== null);
    }

    if (state.layout) {
      state.campaignStyling = CampaignModel.constructCampaignStyling(state.layout, state, layoutKey);
      state.buttonStylingHover = CampaignModel.constructButtonHoverStyling(state.layout, layoutKey);
    }

    if (state.globalLayout) {
      state.globalStyling = CampaignModel.constructCampaignStyling(state.globalLayout, state, layoutKey);
      state.buttonStylingHoverGlobal = CampaignModel.constructButtonHoverStyling(state.globalLayout, layoutKey);
    } else {
      state.globalStyling = undefined;
    }

    const layoutSettingsUrl = `/campaign/${state.config.gameAlias}/${state.id}/layout/general`;

    state.editLayoutSettingsUrl = createEditFormUrl(layoutSettingsUrl);

    let logo = this.state.config?.logo ?? '';

    if (this.state.globalLayout?.logo) {
      logo = this.state.globalLayout?.logo;
    }

    if (this.state.layout?.logo) {
      logo = this.state.layout?.logo;
    }

    const replacementTags: ReplacementTags = {
      campaign_id: this.state.config?.campaignId ?? '',
      campaign_name: this.state.config?.name ?? '',
      campaign_url: typeof window !== 'undefined' ? window.location.href : '',
      timestamp_ms: new Date().getTime(),
      logo,
      date: formatDate(useDate().getDate(), 'Y-m-d'),
      ...(this.state.config?.replacementTags ?? {}),
      ...queryParameters
    };

    // Add all of the existing query parameters as replacement tag that includes
    // a URL encoded version which can be used in redirects or links.
    for (const key in queryParameters) {
      if (Object.prototype.hasOwnProperty.call(queryParameters, key)) {
        replacementTags[`${key}_encoded`] = encodeURIComponent(queryParameters[`${key}`]);
      }
    }

    campaignStore.addReplacementTags(replacementTags);

    const sectionsMap: { [key: number]: SectionModel } = {};
    const flowPageMap: { [key: number]: FlowpageModel } = {};
    const popoversMap: { [key: number]: PopoverModel } = {};

    if (state.sections) {
      state.sections.forEach((section) => {
        sectionsMap[section.state.id] = section;
      });
    }
    if (state.flowPages) {
      state.flowPages.forEach((flowPage) => {
        flowPageMap[flowPage.state.id] = flowPage;
      });
    }
    if (state.popovers) {
      state.popovers.forEach((popover) => {
        popoversMap[popover.state.id] = popover;
      });
    }

    state.flowPages =
      data.pages?.flow?.all
        ?.map<FlowpageModel>((flowPage: SectionData) => {
          if (typeof flowPageMap[flowPage.original_id] !== 'undefined') {
            flowPageMap[flowPage.original_id].setData(flowPage);
            return flowPageMap[flowPage.original_id];
          }
          return new FlowpageModel(flowPage, this);
        })
        .filter((flowpage) => {
          return !flowpage.state.config.hidden;
        }) ?? [];

    state.sections =
      data.sections?.map<SectionModel>((section: SectionData) => {
        if (typeof sectionsMap[section.original_id] !== 'undefined') {
          sectionsMap[section.original_id].setData(section);
          return sectionsMap[section.original_id];
        }

        return new SectionModel(section, this);
      }) ?? [];

    state.popovers =
      data.pages?.pages?.map<PopoverModel>((popover: SectionData) => {
        if (typeof popoversMap[popover.original_id] !== 'undefined') {
          popoversMap[popover.original_id].setData(popover);
          return popoversMap[popover.original_id];
        }
        return new PopoverModel(popover, this);
      }) ?? [];
  }

  async reloadCampaign() {
    const campaignStore = useCampaignStore();

    const { fetchData } = useAxios<CampaignData>(`${getAdminUiEndpoint()}/api/v1/campaign/demo/${this.id}`, {
      vue: 1,
      token: campaignStore.demoToken
    });

    const response = await fetchData();

    this.setData(response);
  }

  async onDeleteSection(sectionIndex: number, sectionId: number, type: SectionType) {
    const state = this.state;
    const campaignId = this.state.id;
    const data = this.getData();

    if (type === SectionType.SECTION) {
      data.sections = data.sections.filter((_, index) => {
        return index !== sectionIndex;
      });
      state.sections = state.sections.filter((section) => {
        return section.index !== sectionIndex;
      });
    }

    if (type === SectionType.FLOWPAGE) {
      if (data.pages && data.pages.flow && data.pages.flow.all) {
        data.pages.flow.all = data.pages.flow.all.filter((_, index) => {
          return index !== sectionIndex;
        });
      }

      state.flowPages = state.flowPages.filter((section) => {
        return section.index !== sectionIndex;
      });
    }

    if (type === SectionType.POPOVER) {
      if (data.pages && data.pages.pages) {
        data.pages.pages = data.pages.pages.filter((_, index) => {
          return index !== sectionIndex;
        });
      }

      state.popovers = state.popovers.filter((section) => {
        return section.index !== sectionIndex;
      });
    }

    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/page?campaign_id=${campaignId}`;
    const { postDeleteFormData } = useAxios(postUrl, {
      page_id: sectionId
    });

    await postDeleteFormData();
    return true;
  }

  async onAddSection(block: SectionBaseModel, areaType: BlockTypes, isCopy?: boolean, insertAfter?: number) {
    const data = this.getData();
    const orderMap: number[] = [];
    const campaignId = this.state.id;
    let _block: string | number | undefined;

    if (isCopy) {
      _block = `copy-page-${block.id}`;
    } else {
      _block = block.id;
    }

    let area: string | undefined;

    if (areaType === BlockTypes.SECTIONS) {
      area = 'section';

      if (data.sections.length > 0) {
        for (const section in data.sections) {
          if (String(data.sections[Number(section)].id) !== 'no-sections' && data.sections[Number(section)].id) {
            orderMap.push(parseInt(data.sections[Number(section)].id.toString(), 10));
          }
        }
      }
    } else if (areaType === BlockTypes.PAGES_FLOW) {
      area = 'flow';

      if (data.pages?.flow?.all && data.pages?.flow?.all.length > 0) {
        for (const flowPage of data.pages.flow.all) {
          if (flowPage.id) {
            orderMap.push(parseInt(flowPage.id.toString(), 10));
          }
        }
      }
    } else if (areaType === BlockTypes.PAGES_PAGE) {
      area = 'page';

      if (data.pages?.pages && data.pages.pages.length > 0) {
        for (const page of data.pages.pages) {
          if (page.id) {
            orderMap.push(parseInt(page.id.toString(), 10));
          }
        }
      }
    }

    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/page?campaign_id=${campaignId}`;
    const { postDataFormData } = useAxios<AddSectionResponse>(postUrl, {
      ...(orderMap.length > 0 && { order: orderMap }),
      ...(insertAfter && { insertAfter }),
      block: _block,
      area
    });

    const response = await postDataFormData();

    if (response?.page_id) {
      await this.reloadCampaign();

      if (areaType === BlockTypes.SECTIONS) {
        const newSection = this.state.sections.find((section) => section.id === response.page_id);
        newSection?.activateEditing();
      } else if (areaType === BlockTypes.PAGES_FLOW) {
        const newFlowPage = this.state.flowPages.find((flowPage) => flowPage.id === response.page_id);
        newFlowPage?.activateEditing();
      } else if (areaType === BlockTypes.PAGES_PAGE) {
        const newPopover = this.state.popovers.find((popover) => popover.id === response.page_id);
        newPopover?.activateEditing();
      }
    }
  }

  getFirstFlowPage(): FlowpageModel | undefined {
    return this.state.flowPages[0];
  }

  getFirstSection(): SectionModel | undefined {
    return this.state.sections[0];
  }

  getFirstPopover(): PopoverModel | undefined {
    return this.state.popovers[0];
  }

  // Method used in navigator. Navigator re-orders the state array
  // so we need to re-build the section order from this.
  async reorderPages(type: SectionType) {
    const editingStore = useEditingStore();
    let order: number[] = [];

    switch (type) {
      case SectionType.SECTION:
        order = this.state.sections.map((page) => page.id);
        break;
      case SectionType.FLOWPAGE:
        order = this.state.flowPages.map((page) => page.id);
        break;
      case SectionType.POPOVER:
        order = this.state.popovers.map((page) => page.id);
        break;
    }

    editingStore.loading = true;

    // Communicate the new order to the server
    await this.saveOrder(type, order);

    // Reload the campaign to get fresh data from the server and re-build the state.
    await this.reloadCampaign();

    editingStore.loading = false;
  }

  async saveOrder(type: SectionType, order: number[]) {
    const editingStore = useEditingStore();
    let area = '';

    switch (type) {
      case SectionType.SECTION:
        area = 'section';
        break;

      case SectionType.FLOWPAGE:
        area = 'flow';
        break;

      case SectionType.POPOVER:
        area = 'page';
        break;
    }

    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/pages/saveOrder?campaign_id=${this.state.id}`;
    const { postDataFormData } = useAxios<SaveOrderResponse>(postUrl, {
      order,
      order_original_id: order,
      area
    });

    const response = await postDataFormData();

    // Server may reject the reordering, here we decide to show them a message
    if (!response.answer && response.message.type === 'error') {
      editingStore.showError(response.message.title, response.message.description);
      return false;
    }

    return true;
  }

  private static constructCampaignMessagesState(data: CampaignAdvancedData, state: CampaignState) {
    for (const key in data) {
      if (key !== 'settings') {
        // Convert key into camelCase from eg: message_game_limit
        const keyInCamelCase = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_m, s) => s.toUpperCase());
        state.messages = state.messages ?? {};
        // @ts-ignore
        state.messages[`${keyInCamelCase}`] = new MessagesHandlerModel(data[`${key}`]);
      }
    }
  }

  private static constructAdvancedState(data: CampaignAdvancedData): CampaignAdvancedState {
    const settings = data.settings;
    let controlCodeType: ControlCodeTypes | undefined;

    switch (settings.control_code.type) {
      case 'bottom':
        controlCodeType = ControlCodeTypes.BOTTOM;
        break;

      case 'top':
        controlCodeType = ControlCodeTypes.TOP;
        break;

      case 'overlay':
        controlCodeType = ControlCodeTypes.OVERLAY;
        break;

      case 'replacementtag':
        controlCodeType = ControlCodeTypes.REPLACEMENT_TAG;
        break;

      default:
        controlCodeType = undefined;
        break;
    }

    return {
      ...(data.javascript?.javascript && { javascript: data.javascript.javascript }),
      css: {
        css: data.css?.css || ''
      },
      externalcss: {
        name: data.externalcss?.name || '',
        url: data.externalcss?.url || ''
      },
      controlCode: {
        enabled: settings.control_code.enabled === '1',
        label: settings.control_code.label,
        ...(settings.control_code.type && { type: controlCodeType }),
        ...(settings.control_code.background_color && { backgroundColor: settings.control_code.background_color }),
        ...(settings.control_code.text_color && { textColor: settings.control_code.text_color })
      },
      ...(settings?.favicon && { favicon: settings.favicon }),
      preloader: {
        ...(data.settings?.preloader?.color && {
          color: data?.settings?.preloader.color
        }),
        ...(settings.preloader?.effect && { effect: settings.preloader?.effect }),
        ...(settings.preloader?.image && { image: settings.preloader?.image }),
        overlay: {
          ...(settings?.preloader?.overlay?.enabled && {
            isEnabled: settings.preloader.overlay.enabled === '1',
            color: settings.preloader.overlay.color
          })
        },
        ...(settings?.preloader?.type && {
          type: settings.preloader.type
        }),
        keepSize: settings.preloader?.keep_size === '1'
      },
      retention: {
        ...(settings?.retention?.timing && {
          timing: settings.retention.timing
        })
      }
    };
  }

  private constructCampaignConfigState(data: CampaignConfigData): CampaignConfigState {
    /**
     * Construct a map over previous instantiated integration models so that we can re-use them.
     */
    const integrationMap: { [key: number]: IntegrationModel } = {};

    if (this.state.config?.integrations && this.state.config.integrations.length > 0) {
      this.state.config.integrations.forEach((integration) => {
        integrationMap[integration.state.id] = integration;
      });
    }

    // No need to re-use the models in the state since they're not suppose to be reactive anyway.
    const userRecognitionMessages: { [key: string]: MessagesHandlerModel } = {};

    if (data.user_recognition?.messages) {
      for (const key in data.user_recognition.messages) {
        if (Object.prototype.hasOwnProperty.call(data.user_recognition.messages, key)) {
          userRecognitionMessages[`${key}`] = new MessagesHandlerModel(data.user_recognition.messages[`${key}`]);
        }
      }
    }

    const params = getQueryParams();

    return {
      ...(data.logo && { logo: this.parseDeviceLogoData(data.logo) }),
      ...(data.custom_fonts && { customFonts: data.custom_fonts }),
      ...(data?.ipstack_api && { ipstackApi: data.ipstack_api }),
      ...(data?.google_maps_api_key && { googleMapsApiKey: data.google_maps_api_key }),
      campaignId: data.campaign_id,
      customerId: data.customer_id,
      ...(data.time && { time: { unix: data.time.unix, formatted: data.time.formatted } }),
      ...(data.gtm_id && { gtmId: data.gtm_id }),
      ...(data.campaign_api_root && { campaignApiRoot: data.campaign_api_root }),
      title: data.title,
      name: data.name,

      leadScoreEnabled: data.lead_score_enabled,
      gameAlias: data.game_alias,
      gameIcon: data.game_icon,
      assetUrl: data.asset_url,
      ...(data.favicon && { favicon: data.favicon }),
      ...(data?.api_endpoint && { apiEndpoint: data.api_endpoint }),
      languageCode: data.language_code,
      timezone: data.timezone,
      ...(data?.active_from && { activeFrom: data.active_from }),
      ...(data?.active_to && { activeTo: data.active_to }),

      globalLayout: data.global_layout,
      enableOnlyAllowViewingInIframe: data.enable_only_allow_viewing_in_iframe,
      enableStatistics: data.enable_statistics ?? true,
      enableZoom: data.enable_zoom ?? false,
      expired: data.expired,
      ...(data?.upload_proxy && { uploadProxy: data.upload_proxy }),
      ghostUser: data.ghost_user,
      deactivateInternalLinkTracking: data.deactivate_internal_link_tracking,
      utmEnabled: data.utm_enabled,
      utmSource: data.utm_source,
      utmCampaign: data.utm_campaign,
      utmContent: data.utm_content,
      utmMedium: data.utm_medium,
      ...(data?.deactivate_ga && {
        deactivateGa: data.deactivate_ga
      }),
      hasRegistrations: data.has_registrations,
      hasLeaderboard: data.has_leaderboard ?? false,
      hash: data.hash,
      ...(data?.cookie_consent?.provider && { cookieConsent: data.cookie_consent.provider }),
      cookiesEnabled: data?.cookies_enabled ?? true,
      resources: {
        before_body_close: data.resources?.before_body_close || '',
        before_body_start: data.resources?.before_body_start || '',
        css: data.resources?.css || '',
        head: data.resources?.head || ''
      },
      ...(data.user_recognition && {
        userRecognition: {
          mapping: data.user_recognition.mapping,
          messages: userRecognitionMessages,
          parameters: data.user_recognition.parameters,
          allowUnrecognisedUsers: data.user_recognition.allowUnrecognisedUsers || false,
          waitForTokens: data.user_recognition.waitForTokens
        }
      }),
      integrations:
        data?.integrations?.map((integration) => {
          // Throw an error if integration handler isn't found.
          if (typeof IntegrationModelMapping[integration.namespace] === 'undefined') {
            throw new TypeError(`Unrecognized integration "${integration.namespace}" in list`);
          }

          // If model have already been used - reuse it.
          if (typeof integrationMap[integration.id] !== 'undefined') {
            // @ts-ignore
            integrationMap[integration.id].setData(integration);
            return integrationMap[integration.id];
          }

          // @ts-ignore
          return new IntegrationModelMapping[integration.namespace](integration);
        }) ?? [],
      ...(data.fb_app_id && { facebookId: data.fb_app_id }),
      ...(data.fb_verification_id && { facebookVerificationId: data.fb_verification_id }),
      metatags: data.meta_tags,
      ...(data.replacementtags && { replacementTags: sanitizedTagCollection(data.replacementtags) }),

      customSolutions:
        data.custom_solutions?.map<CampaignCustomSolution>((customSolution) => {
          let environment = CampaignSolutionEnvironment.PRODUCTION;

          switch (customSolution.environment) {
            case 'local':
              environment = CampaignSolutionEnvironment.LOCAL;
              break;

            case 'dev':
              environment = CampaignSolutionEnvironment.DEV;
              break;

            case 'staging':
              environment = CampaignSolutionEnvironment.STAGING;
          }

          // Allow query parameters to override the environment
          if (params.env) {
            switch (params.env) {
              case 'local':
                environment = CampaignSolutionEnvironment.LOCAL;
                break;

              case 'dev':
                environment = CampaignSolutionEnvironment.DEV;
                break;

              case 'staging':
                environment = CampaignSolutionEnvironment.STAGING;
                break;

              case 'production':
                environment = CampaignSolutionEnvironment.PRODUCTION;
            }
          }

          return {
            id: customSolution.id,
            environment,
            assets: {
              css: customSolution.assets.css,
              js: customSolution.assets.js
            },
            settings: customSolution.settings
          };
        }) ?? [],

      hasBulkPrizeIntegration: data.has_bulk_prize_integration ?? false,
      isWhiteLabel: data.is_white_label ?? false
    };
  }

  private parseDeviceLogoData(data: DeviceData<ConfigLogoData>): string | undefined {
    if (data) {
      const useData = getDeviceData<ConfigLogoData>(data);
      if (!useData) {
        return undefined;
      }
      return this.constructDeviceConfigLogo(useData);
    }
  }

  private constructDeviceConfigLogo(data: ConfigLogoData): string {
    return data.src;
  }

  private parseLayoutData(data: DeviceData<CampaignLayoutData>): CampaignLayoutState | undefined {
    if (this.state.deviceType === CampaignDeviceType.ADS) {
      if (!data.desktop) {
        return undefined;
      }

      return this.constructLayoutState(
        data.desktop,
        data.desktop.buttons ? CampaignModel.constructCampaignButtonState(data.desktop.buttons) : undefined,
        data.desktop.styles ? CampaignModel.constructCampaignStylesState(data.desktop.styles) : undefined
      );
    }

    let buttonState: CampaignLayoutButtonsState | undefined;
    let stylesState: StyleElementState[] | undefined;

    if (data) {
      const useData = getDeviceData<CampaignLayoutData>(data, 'overwrite');
      if (!useData) {
        return undefined;
      }

      if (
        data?.desktop?.buttons &&
        !overwriteLookupCheck<CampaignLayoutButtonsData>(data.desktop.buttons) &&
        useDevice().isMobile &&
        data?.mobile?.buttons
      ) {
        buttonState = CampaignModel.constructCampaignButtonState(data.mobile.buttons);
      } else if (data?.desktop?.buttons) {
        buttonState = CampaignModel.constructCampaignButtonState(data.desktop.buttons);
      }

      if (
        data?.desktop?.styles &&
        !overwriteLookupCheck<StylesData>(data.desktop.styles) &&
        useDevice().isMobile &&
        data?.mobile?.styles
      ) {
        stylesState = CampaignModel.constructCampaignStylesState(data.mobile.styles);
      } else if (data?.desktop?.styles) {
        stylesState = CampaignModel.constructCampaignStylesState(data.desktop.styles);
      }

      return this.constructLayoutState(useData, buttonState, stylesState);
    }
  }

  private static constructCampaignStylesState(data: StylesData): StyleElementState[] {
    const stylesCollection: StyleElementState[] = [];
    for (const key in data) {
      let styles: StyleElementState = {};
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[`${key}`];
        styles = {
          ...(element.element && { element: element.element.toString() }),
          ...(element.line_height && { lineHeight: Number(element.line_height) }),
          ...(element.size && { size: Number(element.size) }),
          ...(element.type && { type: element.type.toString() }),
          ...(element.color && { color: element.color }),
          ...(element.weight && { weight: Number(element.weight) }),
          ...(element.style && {
            style: (element.style as string[]).map((v) => {
              return v;
            })
          })
        };
        stylesCollection.push(styles);
      }
    }
    return stylesCollection;
  }

  private static constructCampaignButtonState(data: CampaignLayoutButtonsData): CampaignLayoutButtonsState {
    return {
      ...(data.hover_text_style && data.hover_text_style.length > 0 && { hoverTextStyle: data.hover_text_style }),
      addShadow: data.add_shadow === '1',
      ...(data.background_color && {
        backgroundColor: data.background_color
      }),
      ...(data.text_color && {
        textColor: data.text_color
      }),
      ...(data.vertical_padding && {
        verticalPadding: Number(data.vertical_padding)
      }),
      ...(data.horisontal_padding && {
        horizontalPadding: Number(data.horisontal_padding)
      }),
      ...(data.font_family && {
        fontFamily: replaceFontIdWithFontString(data.font_family)
      }),
      ...(data.text_size && {
        textSize: Number(data.text_size)
      }),
      ...(data.text_weight && {
        textWeight: Number(data.text_weight)
      }),
      ...(data.letter_spacing && {
        letterSpacing: parseFloat(data.letter_spacing)
      }),
      ...(data.border_color && {
        borderColor: data.border_color
      }),
      ...(data.border_thickness && {
        borderThickness: Number(data.border_thickness)
      }),
      ...(isValidValue(data.border_radius) && {
        borderRadius: Number(data.border_radius)
      }),
      ...(data.line_height && {
        lineHeight: parseFloat(data.line_height)
      }),
      ...(data.button_width && {
        buttonWidth: data.button_width
      }),
      ...(data.background_color_hover && {
        backgroundColorHover: data.background_color_hover
      }),
      ...(data.text_color_hover && {
        textColorHover: data.text_color_hover
      }),
      ...(data.border_color_hover && {
        borderColorHover: data.border_color_hover
      }),
      ...(data.button_effect && {
        buttonEffect: data.button_effect
      }),
      ...(data.text_style && {
        textStyle: data.text_style
      }),
      ...(data.shadow_blur && {
        shadowBlur: Number(data.shadow_blur)
      }),
      ...(data.shadow_color && {
        shadowColor: data.shadow_color
      }),
      ...(data.shadow_horizontal && {
        shadowHorizontal: Number(data.shadow_horizontal)
      }),
      ...(data.shadow_spread && {
        shadowSpread: Number(data.shadow_spread)
      }),
      ...(data.shadow_vertical && {
        shadowVertical: Number(data.shadow_vertical)
      }),
      ...(data.secondary && {
        secondary: {
          ...(data.secondary.hover_text_style &&
            data.secondary.hover_text_style && {
              hoverTextStyle: data.secondary.hover_text_style
            }),
          ...(data.secondary.background_color && {
            backgroundColor: data.secondary.background_color
          }),
          ...(data.secondary.text_color && {
            textColor: data.secondary.text_color
          }),
          ...(data.secondary.vertical_padding && {
            verticalPadding: Number(data.secondary.vertical_padding)
          }),
          ...(data.secondary.horisontal_padding && {
            horizontalPadding: Number(data.secondary.horisontal_padding)
          }),
          ...(data.secondary.font_family && {
            fontFamily: data.secondary.font_family
          }),
          ...(data.secondary.text_size && {
            textSize: Number(data.secondary.text_size)
          }),
          ...(data.secondary.text_weight && {
            textWeight: Number(data.secondary.text_weight)
          }),
          ...(data.secondary.letter_spacing && {
            letterSpacing: parseFloat(data.secondary.letter_spacing)
          }),
          ...(data.secondary.border_color && {
            borderColor: data.secondary.border_color
          }),
          ...(data.secondary.border_thickness && {
            borderThickness: Number(data.secondary.border_thickness)
          }),
          ...(data.secondary.border_radius && {
            borderRadius: Number(data.secondary.border_radius)
          }),
          ...(data.secondary.line_height && {
            lineHeight: parseFloat(data.secondary.line_height)
          }),
          ...(data.secondary.button_width && {
            buttonWidth: data.secondary.button_width
          }),
          ...(data.secondary.background_color_hover && {
            backgroundColorHover: data.secondary.background_color_hover
          }),
          ...(data.secondary.text_color_hover && {
            textColorHover: data.secondary.text_color_hover
          }),
          ...(data.secondary.border_color_hover && {
            borderColorHover: data.secondary.border_color_hover
          }),
          ...(data.secondary.button_effect && {
            buttonEffect: data.secondary.button_effect
          }),
          overwrite: data?.secondary?.overwrite === '1',
          ...(data.secondary.text_style && {
            textStyle: data.secondary.text_style
          }),
          ...(data.secondary.shadow_blur && {
            shadowBlur: Number(data.secondary.shadow_blur)
          }),
          ...(data.secondary.shadow_color && {
            shadowColor: data.secondary.shadow_color
          }),
          ...(data.secondary.shadow_horizontal && {
            shadowHorizontal: Number(data.secondary.shadow_horizontal)
          }),
          ...(data.secondary.shadow_spread && {
            shadowSpread: Number(data.secondary.shadow_spread)
          }),
          ...(data.secondary.shadow_vertical && {
            shadowVertical: Number(data.secondary.shadow_vertical)
          }),
          addShadow: data.secondary.add_shadow === '1'
        }
      })
    };
  }

  private constructLayoutState(
    data: CampaignLayoutData,
    buttonState?: CampaignLayoutButtonsState,
    stylesState?: StyleElementState[]
  ): CampaignLayoutState {
    const generateGridState = (device: GridDeviceTypes): GridState => {
      return {
        ...(data.grid &&
          // eslint-disable-next-line security/detect-object-injection
          data?.grid[device]?.pixel_size && {
            // eslint-disable-next-line security/detect-object-injection
            pixelSize: Number(data.grid[device]?.pixel_size)
          }),
        ...(data.grid &&
          // eslint-disable-next-line security/detect-object-injection
          data?.grid[device]?.align && {
            // eslint-disable-next-line security/detect-object-injection
            align: data.grid[device]?.align
          }),
        // eslint-disable-next-line security/detect-object-injection
        typePixel: data.grid ? data?.grid[device]?.type_pixel === '1' : false,
        // eslint-disable-next-line security/detect-object-injection
        typePercent: data.grid ? data?.grid[device]?.type_percent === '1' : false,
        ...(data.grid &&
          // eslint-disable-next-line security/detect-object-injection
          data?.grid[device]?.percent_size && {
            // eslint-disable-next-line security/detect-object-injection
            percentSize: Number(data?.grid[device]?.percent_size)
          })
      };
    };

    let gridState: GridState | undefined;

    if (data.grid) {
      if (useDevice().isDesktop) {
        gridState = generateGridState('desktop');
      } else if (useDevice().isTablet) {
        gridState = generateGridState('tablet');
      } else if (data.overwrite) {
        gridState = generateGridState('desktop');
      }
    }

    let fontFamily: string | undefined;

    if (
      !data.font_family &&
      this.state.config?.globalLayout &&
      this.state.globalLayout?.fontFamily &&
      this.state.globalLayout.fontFamily !== 'Open Sans'
    ) {
      fontFamily = this.state.globalLayout.fontFamily;
    } else {
      fontFamily = data.font_family || 'Open Sans';
    }

    if (data.font_family && data.font_family.startsWith('custom:') && !replaceFontIdWithFontString(data.font_family)) {
      // If custom font does not exists, but we have global layout with a font family on.
      // Then to fall back to the font family on the global layout.
      if (
        this.state.config?.globalLayout &&
        this.state.globalLayout?.fontFamily &&
        this.state.globalLayout.fontFamily !== 'Open Sans'
      ) {
        fontFamily = this.state.globalLayout.fontFamily;
      } else {
        fontFamily = 'Open Sans';
      }
    }

    fontFamily = replaceFontIdWithFontString(fontFamily) || 'Open Sans';
    const utilityStore = useUtilityStore();
    utilityStore.loadFont(fontFamily);

    return {
      ...(data.background_overlay && { backgroundOverlay: data.background_overlay }),
      ...(data.base_color && { baseColor: data.base_color }),
      ...(data.background_color && {
        backgroundColor: data.background_color
      }),
      ...(data.text_color && { textColor: data.text_color }),
      ...(data.button_text_color && {
        buttonTextColor: data.button_text_color
      }),
      ...(data.background_image && {
        backgroundImage: data.background_image
      }),
      ...(data.background_type && {
        backgroundType: data.background_type
      }),
      ...(data.background_attachment_fixed && {
        backgroundAttachmentFixed: data.background_attachment_fixed === '1'
      }),
      ...(data.background_position && {
        backgroundPosition: data.background_position
      }),
      ...(fontFamily && {
        fontFamily
      }),
      ...(data.button_style && { buttonStyle: data.button_style }),
      ...(data.focus && {
        focus: {
          color: data.focus.color,
          width: Number(data.focus.width)
        }
      }),
      ...(data.flow && {
        flow: {
          transition: data.flow?.transition,
          overwrite: data.flow?.overwrite === '1',
          overlay: data.flow?.overlay === '1'
        }
      }),
      ...(data.custom_popover_colors && {
        customPopoverColors: data.custom_popover_colors === '1'
      }),
      ...(data.popover_background_color && {
        popoverBackgroundColor: data.popover_background_color
      }),
      ...(data.popover_text_color && {
        popoverTextColor: data.popover_text_color
      }),
      overwrite: data.overwrite === '1',
      ...(data.scroll_indicator && {
        scrollIndicator: {
          enable: data.scroll_indicator?.enable === '1',
          color: data.scroll_indicator?.color
        }
      }),
      ...(data.logo && {
        logo: data.logo
      }),
      styles: stylesState,
      ...(gridState && { grid: gridState as GridState }),
      buttons: { ...buttonState }
    };
  }

  private static constructCampaignStyling(
    state: CampaignLayoutState,
    modelState: CampaignState,
    layoutKey: string
  ): string {
    const utilityStore = useUtilityStore();

    // useHead() doesn't remove SSR rendered class names for some reason.
    // So we manually have to remove this on client hydration.

    let isPopup = false;

    if (modelState && modelState.config) {
      isPopup = modelState.config.gameAlias === 'popup' || modelState.config.gameAlias === 'popupv2';
    }

    let gridAlign: AlignContentType | undefined;
    let gridUnit = '';
    let gridValue: number | undefined;

    if (state && state.grid) {
      gridAlign = state.grid.align;
      gridUnit = state.grid.typePercent ? '%' : 'px';
      gridValue = gridUnit === '%' ? state.grid.percentSize : state.grid.pixelSize;
    }

    const backgroundColor = state?.backgroundColor;

    const textColor = state?.textColor;

    const baseColor = state?.baseColor;
    const customPopoverColors = state?.customPopoverColors;
    const buttonTextColor = state?.buttonTextColor ? formatColor(state?.buttonTextColor) : null;

    const focusColor = state?.focus?.color ?? '#ff0000';
    const focusWidth = state?.focus?.width ?? 0;

    let styleString = ``;

    if (focusWidth > 0) {
      styleString += `
        .form-group--is-focus-checkbox .content__item-form-checkbox-input:checked + .content__item-form-label--checkbox:after {
          outline: 1px solid ${focusColor} !important;
        }

        .category-landingpage .campaign__pages .section .content__item-form-element--radio .content__item-form-type--radio .form-group--is-focus-radio .content__item-form-label--radio:before,
        .category-landingpage .campaign__pages .section .form-group--is-focus-radio .content__item-form-label--radio:after,
        .category-landingpage .campaign__pages .section .content__item-form-element--checkbox .content__item-form-type--checkbox .form-group--is-focus-checkbox .content__item-form-label--checkbox::before {
          border-color: ${focusColor} !important
        }

        .form-group--is-focus:after {
          background-color: ${focusColor} !important;
        }

        input:focus,
        .form-group--is-focus,
        .form-group--is-focus .content__item-date-wrapper,
        .form-group--is-focus .vue-select,
        .form-group--is-focus .content__item-file-wrapper {
          border-color: ${focusColor} !important;
          outline: none !important;
        }

        body:not(.site--editmode-on) *:focus:not(input):not(.form-group--is-focus .vue-select):not(.form-group--is-focus .content__item-date-wrapper):not(.has-focus-state) {
          outline: ${focusWidth}px solid ${focusColor} !important;
        }

        .has-focus-state {
          outline: none !important;
        }
      `;
    }

    // If no grid width defined, we default back to 100%
    if (!gridValue) {
      gridValue = 100;
      gridUnit = '%';
    }
    styleString += `
    .site--mobile .content__item--form .content__item-form-element--text .content__item-form-autofill-action { display: none; }
    .campaign__page .btn .content__item-action-disabled-text, .campaign__page .btn .content__item-action-enabled-text {
      padding-top: 1.7rem;
      padding-bottom: 1.7rem;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .site--desktop .campaign > .campaign__pages {
      width: ${gridValue || 100}${gridUnit || '%'};
      max-width: 100%;
    }

    ${
      gridAlign === 'center'
        ? `.site--desktop .campaign > .campaign__pages {
      margin-left: auto;
      margin-right: auto;
    }`
        : ''
    }

    ${
      gridAlign === 'right'
        ? `.site--desktop .campaign > .campaign__pages {
      margin-right:0;
      margin-left: auto;
    }`
        : ''
    }

    .site--tablet .campaign > .campaign__pages {
      width: ${gridValue || 100}${gridUnit || '%'};
      max-width: 100%;
    }
    ${
      gridAlign === 'center'
        ? `.site--tablet .campaign > .campaign__pages {
      margin-left: auto;
      margin-right: auto;
    }`
        : ''
    }
    ${
      gridAlign === 'right'
        ? `
      .site--tablet .campaign > .campaign__pages {
        margin-right: 0;
        margin-left: auto;
      }
    `
        : ''
    }
    .${layoutKey} .content__item-date-wrapper .flatpickr-input { background: transparent; }
    .section.section-is-editting, .section.section-is-transitioning, .section.section-settings-active { height: auto !important; overflow: visible !important; }
    `;

    if (backgroundColor && textColor && backgroundColor === textColor) {
      if (backgroundColor === '#000000') {
        styleString += `
          body.${layoutKey} .row-expired { color: #ffffff; }
        `;
      } else {
        styleString += `
          body.${layoutKey} .row-expired { color: #383838; }
        `;
      }
    }

    if (backgroundColor) {
      const bgColor = formatColor(backgroundColor);

      if (layoutKey === 'site--desktop') {
        styleString += `
        body.${layoutKey} { background-color: ${bgColor}}
      `;
      } else if (layoutKey === 'site--mobile') {
        styleString += `
          body.${layoutKey}, .site--simulate-mobile .site-editor .site-editor__viewer .campaign { background-color: ${bgColor} }
        `;
      } else if (layoutKey === 'site--tablet') {
        styleString += `
          body.${layoutKey}, .site--simulate-tablet .site-editor .site-editor__viewer .campaign { background-color: ${bgColor} }
        `;
      } else if (layoutKey === 'site--ads') {
        styleString += `
          body.${layoutKey}, .site--ads .site-editor .site-editor__viewer .campaign { background-color: ${bgColor} }
        `;
      }
    }

    if (baseColor && baseColor === backgroundColor) {
      let bgColor = formatColor(backgroundColor);
      if (bgColor === '#fff' || bgColor === '#ffffff') {
        bgColor = '#000';
      } else {
        bgColor = '#fff';
      }
      styleString += `.${layoutKey} .content__item-form-label--checkbox:after { color: ${bgColor} !important; }`;
    } else if (backgroundColor) {
      const formattedBgColor = formatColor(backgroundColor);
      styleString += `
        .${layoutKey} .content__item-form-label--checkbox:after { color: ${formattedBgColor} !important; }
      `;
    }

    if (customPopoverColors) {
      if (backgroundColor && backgroundColor !== '') {
        const bgColor = adjustBrightness(formatColor(backgroundColor), -40);
        styleString += `
      .${layoutKey} .popover,
      .${layoutKey} .modal,
      .${layoutKey} .dropdown-menu,
      .${layoutKey}.site--mobile .menubar__navigation,
      .${layoutKey}.site--tablet .menubar__navigation {
        background-color: ${bgColor}
      }`;
      }
    }

    if (textColor) {
      let formattedBaseColor = 'transparent';
      let buttonBackgroundColor: string | undefined;

      const formattedTextColor = formatColor(textColor);
      if (baseColor) {
        formattedBaseColor = formatColor(baseColor);
      }
      if (state.buttons?.backgroundColor) {
        buttonBackgroundColor = state.buttons?.backgroundColor;
      }
      const textColorHexToRGBA = hexToRGBA(textColor, 20);

      styleString += `
        body.${layoutKey}  {
          color: ${formattedTextColor}
        }
        body.${layoutKey} a {
          color: ${formattedTextColor}
        }

        body.${layoutKey} .detail-tabs__content-item-dots {
          background-image: radial-gradient(circle, ${formattedTextColor} 45%, transparent 50%)';
        }

        body.${layoutKey} .display-image__details-list-item-dots {
          background-image: radial-gradient(circle, ${formattedTextColor} 45%, transparent 50%)';
        }

        body.${layoutKey} .detail-tabs__nav-item-link:before {
          background-color: ${formattedTextColor};
        }

        body.${layoutKey} .detail-tabs .nav-tabs {
          border-bottom-color: ${textColorHexToRGBA};
        }

        .${layoutKey} .form-group .form-control,
        .${layoutKey} .form-group .content__item-date-wrapper input::placeholder,
        .${layoutKey} .form-group .content__item-form-textarea,
        .${layoutKey} .content__item-form-checkbox label,
        .${layoutKey} .form-group .content__item-form-label {
          color: inherit;
        }

        .${layoutKey} .form-group--has-value.content__item-form-group--select input::-webkit-input-placeholder,
        .${layoutKey} .form-group--has-value.content__item-form-group--select input::placeholder{
           color: inherit;
        }

        .${layoutKey} .content__item-form-group--select .select2-selection,
        .${layoutKey} .content__item-form-group--select input,
        .${layoutKey} .content__item-form-group--select input::-webkit-input-placeholder,
        .${layoutKey} .content__item-form-group--select input::placeholder {
          opacity: 1;
        }

        .${layoutKey} .select2-container .select2-dropdown,
        .${layoutKey} .content__item-form-group--select .content__item-form-label--select {
          color: inherit;
        }

        .${layoutKey} .vue-select .arrow-downward {
          border-color: ${textColor} transparent transparent transparent;
        }

        .${layoutKey} .vue-select .open .arrow-downward {
          border-color: transparent transparent ${textColor} transparent;
        }

        body.${layoutKey} .slider-connects {
          background-color: ${textColor};
        }

        body.${layoutKey} .slider-handle {
          background-color: ${formattedBaseColor || buttonBackgroundColor};
        }

        body.${layoutKey} .game-indicator-item::before {
          background-color: ${formattedTextColor};
        }

        body.${layoutKey} .game-indicator-item__circle-path {
          stroke: ${formattedTextColor};
        }

        body.${layoutKey} .game-indicator-item__icon-path path {
          fill: ${formattedTextColor};
        }

        body.${layoutKey} .game-indicator-item__icon-path--dice rect {
          stroke: ${formattedTextColor};
        }

        body.${layoutKey} .game-indicator-item__icon-path--dice circle {
          fill: ${formattedTextColor};
        }
      `;
    }

    if (!customPopoverColors && textColor) {
      const formattedTextColor = formatColor(textColor);
      styleString += `
        .${layoutKey} .modal .navigation__liner {
          background-color: ${formattedTextColor} !important;
        }
      `;
    }
    if (buttonTextColor || textColor) {
      const formattedBackgroundColor = buttonTextColor
        ? formatColor(buttonTextColor)
        : formatColor(textColor ? textColor.toString() : '');
      styleString += `
        .${layoutKey} #nav-toggle span,
        .${layoutKey} #nav-toggle span:before,
        .${layoutKey} #nav-toggle span:after,
        .${layoutKey} .close:before,
        .${layoutKey} .close:after {
          background-color: ${formattedBackgroundColor}
        }

        .${layoutKey} .campaign__page button,
        .${layoutKey} .modal button,
        .${layoutKey} .campaign__page .content__item-action.btn,
        .${layoutKey} .modal .content__item-action.btn,
        .${layoutKey} .campaign__page .grid__addon .content__item-social-share,
        .${layoutKey} .campaign__page .grid__addon .content__item-share {
          color: ${buttonTextColor || textColor}
        }

        .${layoutKey} .btn--fab.btn-primary,
        .${layoutKey} .btn--fab,
        .${layoutKey} .content__item--slider .content__item-slider .btn {
          color: ${formattedBackgroundColor}
        }

        .${layoutKey} .btn .progress-container--circular.progress-container--primary .progress-circular__path {
          stroke: ${formattedBackgroundColor}
        }

        .${layoutKey} .content__item-form-group--date .flatpickr-day.selected,
        .${layoutKey} .content__item-form-group--date .flatpickr-day.today:hover,
        .${layoutKey} .content__item-form-group--date .flatpickr-day.today:focus {
           background-color: ${
             buttonTextColor ? hexToRGBA(buttonTextColor, 0) : hexToRGBA(textColor ? textColor.toString() : '', 0)
           } !important;
        }

        .${layoutKey} .control-code {
          color: ${buttonTextColor || textColor}
        }
      `;

      if (buttonTextColor) {
        styleString += `
          .${layoutKey} .slideshow .slideshow__arrow {
            color: ${buttonTextColor ? formatColor(buttonTextColor) : ''}
          }

          .${layoutKey} .slideshow .slideshow__arrow:hover,
          .${layoutKey}.site--mobile .slideshow .slideshow__arrow {
            color: ${buttonTextColor ? formatColor(buttonTextColor) : ''} !important;
          }
        `;
      }
    }
    if (baseColor && baseColor !== '') {
      const formattedBaseColor = formatColor(baseColor);
      styleString += `
        .${layoutKey} #nav-toggle,
        .${layoutKey} .close {
          background-color: ${formattedBaseColor}
        }

        .${layoutKey} .campaign__page button,
        .${layoutKey} .modal button,
        .${layoutKey} .campaign__page .content__item-action.btn,
        .${layoutKey} .modal .content__item-action.btn,
        .${layoutKey} .campaign__page .grid__addon .content__item-social-share,
        .${layoutKey} .campaign__page .grid__addon .content__item-share {
          background-color: ${formattedBaseColor}
        }

        .${layoutKey} .content__item-form-label--checkbox:before {
          border-color: ${formattedBaseColor} !important;
        }

        .${layoutKey} .word__letter {
          border-color: ${formattedBaseColor} !important;
        }

        .${layoutKey} .content__item-form-label--checkbox:after {
          background-color: ${formattedBaseColor} !important;
        }

        .${layoutKey} .content__item-form-radio .content__item-form-label--radio:before {
          border-color: ${formattedBaseColor} !important;
        }

        .${layoutKey} .content__item-form-radio .content__item-form-label--radio:after {
          color: ${formattedBaseColor} !important;
          border-color: ${formattedBaseColor} !important;
        }

        .${layoutKey} .form-group:after {
          background-color: ${formattedBaseColor};
        }

        .${layoutKey} .form-group:before {
          border-bottom-color: ${formattedBaseColor};
        }

        .${layoutKey} .content__item-form-group--border {
          border-bottom-color: ${formattedBaseColor};
        }

        .${layoutKey} .content__item-form-group--file:after {
          background-color: ${formattedBaseColor};
        }

        .${layoutKey} .content__item-form-group--file:before {
          border-bottom-color: ${formattedBaseColor};
        }

        .${layoutKey} .content__item-form-group--file:after {
          background-color: ${formattedBaseColor};
        }

        .${layoutKey} .content__item-form-group--select:before {
          border-bottom-color: ${formattedBaseColor};
        }

        .${layoutKey} .content__item-form-element--file .progress-container--circular.progress-container--primary .progress-circular__path {
          stroke: ${formattedBaseColor};
        }

        .${layoutKey} .btn--fab.btn-primary,
        .${layoutKey} .btn--fab,
        .${layoutKey} .btn--fab.btn-primary:active,
        .${layoutKey} .content__item--slider .content__item-slider .btn {
          background-color: ${formattedBaseColor}
        }

        .${layoutKey} .slideshow .slideshow__indicators .slideshow__indicator {
          border-color: ${formattedBaseColor} !important;
        }

        .${layoutKey} .slideshow .slideshow__indicators .slideshow__indicator .circle {
          background-color: ${formattedBaseColor} !important;
        }

        .${layoutKey} .slideshow .slideshow__arrow:hover,
        .${layoutKey}.site--mobile .slideshow .slideshow__arrow {
          background-color: ${hexToRGBA(baseColor, 0)} !important;
        }

        .${layoutKey} .slideshow .slideshow-arrow {
          background-color: ${hexToRGBA(baseColor, 50)} !important;
        }

        .${layoutKey} .theme-base-color-type-bg-color {
          background-color: ${hexToRGBA(baseColor, 50)} !important;
        }

        .${layoutKey} .content__item-form-group--date .flatpickr-day.selected,
        .${layoutKey} .content__item-form-group--date .flatpickr-day.today:hover,
        .${layoutKey} .content__item-form-group--date .flatpickr-day.today:focus {
           background-color: ${hexToRGBA(baseColor, 0)} !important;
        }

        .${layoutKey} .content__item-form-group--date .flatpickr-day.selected,
        .${layoutKey} .content__item-form-group--date .flatpickr-day.today {
          border-color: ${buttonTextColor ? hexToRGBA(buttonTextColor, 0) : baseColor} !important;
        }

        .${layoutKey} .control-code {
          background-color: ${hexToRGBA(baseColor, 0.9)};
        }

        .${layoutKey} > .preloader .preloader__default .preloader__default-inner:after {
          border-color: ${formattedBaseColor};
        }

        .${layoutKey} > .preloader .preloader__dots .preloader__dot {
          background-color: ${formattedBaseColor};
        }

        .${layoutKey} > .preloader .preloader__lf .preloader__lf-dot {
           background-color: ${formattedBaseColor};
        }
      `;
    }

    if (state?.buttons?.secondary && state.buttons?.secondary?.backgroundColor) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          background-color: ${formatColor(state.buttons.secondary.backgroundColor)};
        }
      `;
    }

    if (state?.buttons?.secondary) {
      if (
        state.buttons?.secondary &&
        (state.buttons?.secondary?.verticalPadding || state.buttons?.secondary?.verticalPadding === 0)
      ) {
        styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          padding-top: ${state.buttons?.secondary?.verticalPadding}px;
          padding-bottom: ${state.buttons?.secondary?.verticalPadding}px;
        }
      `;
      }

      if (
        state.buttons?.secondary &&
        (state.buttons?.secondary?.horizontalPadding || state.buttons?.secondary?.horizontalPadding === 0)
      ) {
        styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          padding-left: ${state.buttons.secondary.horizontalPadding}px;
          padding-right: ${state.buttons.secondary.horizontalPadding}px;
         }
      `;
      }

      if (state?.buttons.secondary && state.buttons.secondary.fontFamily) {
        const fontFamily = replaceFontIdWithFontString(state.buttons.secondary.fontFamily);
        if (fontFamily) {
          utilityStore.loadFont(fontFamily);

          styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
          .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
            font-family: "${fontFamily}", "Helvetica Neue", Arial, sans-serif;
          }
        `;
        } else {
          styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
          .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
            font-family: "${fontFamily}", "Helvetica Neue", Arial, sans-serif;
          }
        `;
        }
      }

      if (state?.buttons?.secondary && state.buttons?.secondary?.textSize) {
        styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          font-size: ${state.buttons.secondary.textSize}px;
        }
      `;
      }

      if (state?.buttons?.secondary && state.buttons?.secondary?.textWeight) {
        styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          font-weight: ${state.buttons.secondary.textWeight};
        }
      `;
      }

      if (state?.buttons?.secondary && state.buttons?.secondary?.textColor) {
        styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          color: ${state.buttons?.secondary?.textColor};
        }
      `;
      }

      if (state?.buttons.secondary && state.buttons.secondary.borderThickness) {
        styleString += `
       .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
       .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          border-style: solid;
          border-width: ${state.buttons.secondary.borderThickness}px;
       }
      `;
      }

      if (state?.buttons.secondary.borderColor) {
        styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
          .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
            border-color: ${formatColor(state.buttons.secondary.borderColor)};
          }
        `;
      }

      if (state.buttons.secondary.borderThickness && state.buttons.secondary.borderThickness === 0) {
        styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          border: 0 none;
        }
      `;
      }

      if (state?.buttons.secondary.textStyle) {
        const textStyle = state.buttons.secondary.textStyle;
        if (textStyle.includes('uppercase')) {
          styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
          .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
            text-transform: uppercase;
          }
        `;
        }
        if (textStyle.includes('lowercase')) {
          styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
          .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
            text-transform: lowercase;
          }
        `;
        }

        if (textStyle.includes('underline')) {
          styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
          .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
            text-decoration: underline !important;
          }
        `;
        }
        if (textStyle.includes('italic')) {
          styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
          .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
            font-style: italic;
          }
        `;
        }
      }

      if (state?.buttons.secondary.lineHeight) {
        styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          line-height: ${state.buttons.secondary.lineHeight};
        }
      `;
      }

      if (state?.buttons.secondary && state.buttons.secondary.addShadow) {
        const secondaryBtn = state.buttons.secondary;
        if (
          secondaryBtn.shadowColor &&
          secondaryBtn.shadowBlur !== undefined &&
          secondaryBtn.shadowSpread !== undefined &&
          secondaryBtn.shadowVertical !== undefined &&
          secondaryBtn.shadowHorizontal !== undefined &&
          hasNumberValue(secondaryBtn.shadowBlur) &&
          hasNumberValue(secondaryBtn.shadowSpread) &&
          hasNumberValue(secondaryBtn.shadowVertical) &&
          hasNumberValue(secondaryBtn.shadowHorizontal)
        ) {
          styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
          .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
            box-shadow: ${secondaryBtn.shadowHorizontal}px ${secondaryBtn.shadowVertical}px ${secondaryBtn.shadowBlur}px ${secondaryBtn.shadowSpread}px ${secondaryBtn.shadowColor};
          }
        `;
        }
      }

      if (state?.buttons.secondary.buttonWidth) {
        styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          min-width: ${state.buttons.secondary.buttonWidth};
        }
      `;
      }

      if (isValidValue(state?.buttons.secondary.borderRadius)) {
        styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary,
        .${layoutKey}.site .modal .content__item-action.btn.btn--secondary {
          border-radius: ${state.buttons.secondary.borderRadius}px;
        }
      `;
      }
    }

    if (state?.customPopoverColors && state?.popoverTextColor) {
      styleString += `
        body.${layoutKey} .modal {
          color: ${formatColor(state.popoverTextColor)};
        }
        body.${layoutKey} .modal a {
          color: ${formatColor(state.popoverTextColor)};
        }

        .${layoutKey} .modal,
        .${layoutKey} .dropdown-menu,
        .${layoutKey}.site--mobile .menubar__navigation,
        .${layoutKey}.site--tablet .menubar__navigation {
          background-color: ${state.popoverBackgroundColor};
        }

        .${layoutKey} .menubar__navigation-liner {
          background-color: ${formatColor(state.popoverTextColor)} !important;
        }

        .${layoutKey} .modal .navigation__liner {
          background-color: ${formatColor(state.popoverTextColor)} !important;
        }

      `;
    }

    if (modelState.layout?.buttonStyle && modelState.layout?.buttonStyle === 'rounded') {
      styleString += `
        .${layoutKey}.site .content__item-action.btn {
          border-radius: 0.6rem;
        }
      `;
    }

    if (state?.buttons) {
      if (state.buttons?.backgroundColor) {
        styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn,
          .${layoutKey}.site .modal .content__item-action.btn {
            background-color: ${formatColor(state.buttons.backgroundColor)};
          }
        `;
      }
    }

    if (state?.buttons && (state.buttons.verticalPadding || state.buttons.verticalPadding === 0)) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          padding-top: ${state.buttons.verticalPadding}px;
          padding-bottom: ${state.buttons.verticalPadding}px;
        }
      `;
    }

    if (state?.buttons && (state.buttons.horizontalPadding || state.buttons.horizontalPadding === 0)) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          padding-left: ${state.buttons.horizontalPadding}px;
          padding-right: ${state.buttons.horizontalPadding}px;
        }
      `;
    }

    if (state?.buttons && state.buttons.fontFamily) {
      utilityStore.loadFont(state.buttons.fontFamily);

      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          font-family: "${state.buttons.fontFamily}", "Helvetica Neue", Arial, sans-serif;
        }
      `;
    }

    if (state?.buttons && state.buttons.textSize) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          font-size: ${state.buttons.textSize}px;
        }
      `;
    }

    if (state?.buttons && state.buttons.textWeight) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          font-weight: ${state.buttons.textWeight};
        }
      `;
    }

    if (state?.buttons && state.buttons.letterSpacing) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          letter-spacing: ${state.buttons.letterSpacing}px;
        }
      `;
    }
    if (state?.buttons && state.buttons.addShadow) {
      const button = state.buttons;
      if (
        button.shadowColor &&
        button.shadowBlur !== undefined &&
        button.shadowSpread !== undefined &&
        button.shadowVertical !== undefined &&
        button.shadowHorizontal !== undefined &&
        hasNumberValue(button.shadowBlur) &&
        hasNumberValue(button.shadowSpread) &&
        hasNumberValue(button.shadowVertical) &&
        hasNumberValue(button.shadowHorizontal)
      ) {
        styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn,
          .${layoutKey}.site .modal .content__item-action.btn {
            box-shadow: ${button.shadowHorizontal}px ${button.shadowVertical}px ${button.shadowBlur}px ${button.shadowSpread}px ${button.shadowColor};
          }
        `;
      }
    }

    if (state?.buttons && state.buttons.textColor) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          color: ${formatColor(state.buttons.textColor)};
        }
      `;
    }

    if (state?.buttons && state.buttons.borderThickness) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          border-style: solid;
          border-width: ${state.buttons.borderThickness}px;
        }
      `;
    }

    if (state.buttons?.borderColor) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          border-color: ${formatColor(state.buttons.borderColor)};
        }
      `;
    }

    if (state?.buttons && state.buttons.borderThickness === 0) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          border: 0 none;
        }
      `;
    }

    if (state?.buttons && state.buttons.textStyle) {
      const buttonTextStyle = state.buttons.textStyle;
      if (buttonTextStyle.includes('uppercase')) {
        styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn,
          .${layoutKey}.site .modal .content__item-action.btn {
            text-transform: uppercase;
          }
        `;
      }
      if (buttonTextStyle.includes('lowercase')) {
        styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn,
          .${layoutKey}.site .modal .content__item-action.btn {
            text-transform: lowercase;
          }
        `;
      }
      if (buttonTextStyle.includes('underline')) {
        styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn,
          .${layoutKey}.site .modal .content__item-action.btn {
            text-decoration: underline;
          }
        `;
      }
      if (buttonTextStyle.includes('italic')) {
        styleString += `
          .${layoutKey}.site .campaign__page .content__item-action.btn,
          .${layoutKey}.site .modal .content__item-action.btn {
            font-style: italic;
          }
        `;
      }
    }

    if (state?.buttons && state.buttons.lineHeight) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          line-height: ${state.buttons.lineHeight};
        }
      `;
    }
    if (state?.buttons && state.buttons.buttonWidth) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          min-width: ${state.buttons.buttonWidth};
        }
      `;
    }
    if (state?.buttons && state.buttons.hoverTextStyle) {
      const hoverTextStyle = state.buttons.hoverTextStyle;

      if (hoverTextStyle.includes('underline')) {
        styleString += `
          .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
          .no-touch .${layoutKey}.site .modal .content__item-action.btn:hover {
            text-decoration: underline;
          }

          .touch .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
          .touch .${layoutKey}.site .modal .content__item-action.btn:hover {
            text-decoration: underline;
          }
        `;
      }
    }

    if (state?.buttons && isValidValue(state.buttons.borderRadius)) {
      styleString += `
        .${layoutKey}.site .campaign__page .content__item-action.btn,
        .${layoutKey}.site .modal .content__item-action.btn {
          border-radius: ${state.buttons.borderRadius}px;
        }
      `;
    }
    if (state?.styles) {
      const styles = state.styles;
      let selectorStyleString = '';
      let selector = '';

      styles.forEach((styleItem) => {
        if (!styleItem.element) {
          return;
        }

        if (styleItem.element === 'link') {
          selector = `
            .${layoutKey}.site .campaign__page a,
            .${layoutKey}.site .modal a
          `;
        } else if (styleItem.element === 'link_hover') {
          selector = `
            .${layoutKey}.site .campaign__page a:hover,
            .${layoutKey}.site .campaign__page a:hover *:not(i),
            .${layoutKey}.site .modal a:hover,
            .${layoutKey}.site .modal a:hover *:not(i)
          `;
        } else if (styleItem.element === 'link_active') {
          selector = `
            .${layoutKey}.site .campaign__page a:active,
            .${layoutKey}.site .campaign__page a:active *,
            .${layoutKey}.site .modal a:active,
            .${layoutKey}.site .modal a:active *
          `;
        } else if (styleItem.element === 'bullet') {
          selector = `
            .${layoutKey}.site .campaign__page ul:not(.dawa-autocomplete-suggestions):not(.vue-dropdown),
            .${layoutKey} ol,
            .${layoutKey}.site .campaign__page li:not(.dawa-autocomplete-suggestion):not(.vue-dropdown-item),
            .${layoutKey}.site .modal ul,
            .${layoutKey} ol,
            .${layoutKey}.site .modal li
          `;
        } else if (styleItem.element === 'form') {
          selector = `
            .${layoutKey}.site .campaign__page .form-group .form-control,
            .${layoutKey} .content__item-form-group--select .vue-input,
            .${layoutKey}.site .campaign__page .form-group .content__item-form-textarea,
            .${layoutKey}.site .campaign__page .select2-container .select2-dropdown,
            .site .modal .form-group .form-control,
            .${layoutKey}.site .modal .form-group .content__item-form-textarea,
            .${layoutKey}.site .modal .select2-container .select2-dropdown
          `;
        } else if (styleItem.element === 'content') {
          selector = `
            .${layoutKey} .campaign .lf-text,
            .${layoutKey}.site .campaign__page .row p,
            .${layoutKey}.site .modal .row p
          `;
        } else if (styleItem.element === 'form_placeholders') {
          selector = `
            .${layoutKey}.site .campaign__page .content__item--form .content__item-form-label,
            .${layoutKey}.site .campaign__page .content__item--form .content__item-form-element--checkbox .content__item-form-checkboxes-label,
            .${layoutKey}.site .campaign__page .content__item--form .content__item-form-element--checkbox .content__item-form-label--checkbox,
            .${layoutKey}.site .campaign__page .content__item--form .content__item-form-element--checkbox .content__item-form-label--checkbox p,
            .${layoutKey}.site .campaign__page .content__item--form .content__item-form-element--radio .content__item-form-label--radio,
            .${layoutKey}.site .campaign__page .content__item--form .content__item-form-element--radio .content__item-form-radios-label,
            .${layoutKey}.site .campaign__page .content__item-form-group--select .content__item-form-label--select,
            .${layoutKey}.site .campaign__page .content__item-form-element--paragraph .content__item-form-group--paragraph,
            .${layoutKey}.site .modal .form-group .content__item-form-label,
            .${layoutKey}.site .campaign__page .form-group .content__item-form-label,
            .${layoutKey}.site .modal .content__item--form .content__item-form-element--checkbox .content__item-form-checkboxes-label,
            .${layoutKey}.site .modal .content__item--form .content__item-form-element--checkbox .content__item-form-label--checkbox,
            .${layoutKey}.site .modal .content__item--form .content__item-form-element--radio .content__item-form-label--radio,
            .${layoutKey}.site .modal .content__item--form .content__item-form-element--radio .content__item-form-radios-label,
            .${layoutKey}.site .modal .content__item-form-group--select .content__item-form-label--select,
            .${layoutKey}.site .modal .content__item-form-element--paragraph .content__item-form-group--paragraph
          `;
        } else if (styleItem.element) {
          selector = `
            .${layoutKey}.site .campaign__page ${styleItem.element},
            .${layoutKey}.site .campaign__page ${styleItem.element} div,
            .${layoutKey}.site .modal ${styleItem.element},
            .${layoutKey}.site .modal ${styleItem.element} div
          `;
        }

        const fontFamily = styleItem.type ? replaceFontIdWithFontString(styleItem.type) : undefined;

        if (fontFamily) {
          utilityStore.loadFont(fontFamily);
        }

        let styleItemStyle = '';

        styleItem.style?.forEach((style) => {
          if (style.includes('italic')) {
            styleItemStyle += 'font-style: italic;';
          }
          if (style.includes('underline')) {
            styleItemStyle += 'text-decoration: underline;';
          }
          if (style.includes('uppercase')) {
            styleItemStyle += 'text-transform: uppercase;';
          }
          if (style.includes('lowercase')) {
            styleItemStyle += 'text-transform: lowercase;';
          }
        });

        if (styleItem.element === 'bullet') {
          if (styleItem.style?.includes('numbers')) {
            styleItemStyle += 'list-style-type: decimal;';
          }
        }

        if (styleItem.style?.includes('numbers') && styleItem.style.includes('circle')) {
          styleItemStyle += `
            .${layoutKey}.site .page ul,
            .${layoutKey}.site .campaign__page ol,
            .${layoutKey}.site .page ul,
            .${layoutKey}.site .modal ol {
              padding-left: 2rem;
            }
          `;
        }
        selectorStyleString += `
          ${selector} {`;
        if (styleItem.element === 'link_hover' || styleItem.element === 'link_active') {
          selectorStyleString += `
            ${styleItem.color ? `color: ${formatColor(styleItem.color)} !important;` : ''}`;
        } else {
          selectorStyleString += `
            ${styleItem.color ? `color: ${formatColor(styleItem.color)};` : ''}`;
        }
        selectorStyleString += `
            ${fontFamily ? `font-family: "${fontFamily}", "Helvetica Neue", Arial, sans-serif;` : ''}
            ${styleItem.size ? `font-size: ${styleItem.size}px;` : ''}
            ${styleItem.lineHeight ? `line-height: ${styleItem.lineHeight}px;` : ''}
            ${styleItem.weight ? `font-weight: ${styleItem.weight};` : ''}
            ${styleItemStyle}
            }`;
      });

      styleString += `${selectorStyleString}`;
    }

    if (state?.fontFamily) {
      utilityStore.loadFont(state.fontFamily);
      styleString += `
        body.${layoutKey} {
          font-family: "${state.fontFamily}", "Helvetica Neue", Arial, sans-serif !important;
        }
      `;
    } else {
      styleString += `
        body.${layoutKey} {
          font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
        }
      `;
    }

    if (!isPopup && state?.backgroundImage) {
      const backgroundType = state.backgroundType && state.backgroundType !== '' ? state.backgroundType : 'cover';

      if (useDevice().isMobile) {
        layoutKey = layoutKey + ', .site--simulate-mobile .site-editor .site-editor__viewer .campaign';
      } else if (useDevice().isTablet) {
        layoutKey = layoutKey + ', .site--simulate-tablet .site-editor .site-editor__viewer .campaign';
      } else if (modelState.deviceType === CampaignDeviceType.ADS) {
        layoutKey = layoutKey + ', .site--ads .site-editor .site-editor__viewer .campaign';
      }

      styleString += `
        body.${layoutKey} {
          background-image: url(${state.backgroundImage});
        }
      `;

      // Seems like absolute covers the whole screen for mobile
      // while fixed covers the whole screen for desktop
      if (state.backgroundImage && state.backgroundOverlay) {
        styleString += `
           body.site:not(.site--simulate-mobile):not(.site--simulate-tablet)::before {
              content: '';
              position: fixed;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              pointer-events: none;
              background-color: ${state.backgroundOverlay};
              min-height: 100vh;
            }

           body.site.site--simulate-mobile .campaign::before,
           body.site.site--simulate-tablet .campaign::before {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              pointer-events: none;
              background-color: ${state.backgroundOverlay};
              min-height: 100vh;
            }
          `;
      }

      if (backgroundType === 'tile') {
        styleString += `
          body.${layoutKey} {
            background-repeat: repeat;
          }
        `;
      }
      if (backgroundType === 'center') {
        styleString += `
          body.${layoutKey} {
            background-repeat: no-repeat;
            background-position: center center;
          }
        `;
      }
      if (backgroundType === 'stretch') {
        styleString += `
          body.${layoutKey} {
            background-repeat: no-repeat;
            background-size: 100% 100%;
          }
        `;
      }
      if (backgroundType === 'cover') {
        styleString += `
          body.${layoutKey}{
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        `;
      }
      if (backgroundType === 'contain') {
        styleString += `
          body.${layoutKey} {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
          }
        `;
      }

      if (state?.backgroundAttachmentFixed) {
        styleString += `
          body.${layoutKey}{
            background-attachment: fixed;
          }
        `;
      }

      if (state?.backgroundPosition) {
        if (state.backgroundPosition === 'top_left') {
          styleString += `
            body.${layoutKey}{
              background-position: top left;
            }
          `;
        }
        if (state.backgroundPosition === 'top_center') {
          styleString += `
            body.${layoutKey} {
              background-position: top center;
            }
          `;
        }
        if (state.backgroundPosition === 'top_right') {
          styleString += `
            body.${layoutKey} {
              background-position: top right;
            }
          `;
        }
        if (state.backgroundPosition === 'center_left') {
          styleString += `
            body.${layoutKey} {
              background-position: center left;
            }
          `;
        }
        if (state.backgroundPosition === 'center_center') {
          styleString += `
            body.${layoutKey} {
              background-position: center center;
            }
          `;
        }
        if (state.backgroundPosition === 'center_right') {
          styleString += `
            body.${layoutKey} {
              background-position: center right;
            }
          `;
        }
        if (state.backgroundPosition === 'bottom_left') {
          styleString += `
            body.${layoutKey} {
              background-position: bottom left;
            }
          `;
        }
        if (state.backgroundPosition === 'bottom_center') {
          styleString += `
            body.${layoutKey} {
              background-position: bottom center;
            }
          `;
        }
        if (state.backgroundPosition === 'bottom_right') {
          styleString += `
            body.${layoutKey} {
              background-position: bottom right;
            }
          `;
        }
      }
    }

    const preloader = modelState.advanced?.preloader;
    if (preloader) {
      if (preloader.type === 'dots') {
        styleString += `
          .site .preloader .preloader__default {
            display:none;
          }
          .site .preloader .preloader__dots {
            display:block;
          }
          .site .preloader .preloader__dots .preloader__dot {
            background-color: ${formatColor(preloader.color ? preloader.color.toString() : '')};
          }
        `;
      } else if (preloader.type === 'lf') {
        styleString += `
         .site .preloader .preloader__default {
            display:none;
          }
          .site .preloader .preloader__lf {
            display:block;
          }
          .site .preloader .preloader__lf .preloader__lf-dot {
            background-color: ${formatColor(preloader.color ? preloader.color.toString() : '')};
          }
        `;
      } else if (preloader.type === 'custom') {
        styleString += `
          .site .preloader .preloader__default {
            display:none;
          }
          .site .preloader .preloader__custom {
            display:flex;
          }
        `;
      } else if (preloader.type === 'default') {
        styleString += `
          .site .preloader .preloader__default {
            display: block;
          }
          .site .preloader .preloader__default .preloader__default-inner:after {
            border-color: ${formatColor(preloader.color ? preloader.color.toString() : '')};
          }
        `;
      }
      if (preloader.overlay && preloader.overlay.isEnabled) {
        styleString += `
          .site .preloader__overlay {
            display:block;
          }
          .site .preloader__overlay {
            background-color: ${formatColor(preloader.color ? preloader.color.toString() : '')}
          }
          .site .lf-modal .preloader__overlay {
             background-color: #232424
          }
        `;
      }
    }

    return styleString;
  }

  private static constructButtonHoverStyling(state: CampaignLayoutState, layoutKey: string): string {
    let formattedBaseColor = 'transparent';
    const baseColor = state?.baseColor;

    if (baseColor) {
      formattedBaseColor = formatColor(baseColor);
    }

    let styleString = `
        .no-touch .${layoutKey} .btn--fab.btn-primary:hover,
        .no-touch .${layoutKey} .btn--fab:hover {
          background-color: ${adjustBrightness(formattedBaseColor, 15)};
        }

    `;

    if (layoutKey === 'site--desktop' && baseColor) {
      const baseColorAdjustedBrightness = adjustBrightness(formatColor(baseColor), 15);
      styleString += `
        .${layoutKey}.site .content__item-action.btn:hover {
          background-color: ${baseColorAdjustedBrightness}
        }
      `;
    }

    if (state?.buttons?.secondary?.backgroundColor && !state.buttons?.secondary?.backgroundColorHover) {
      const bgColorAdjustedBrightness = adjustBrightness(
        formatColor(state.buttons.secondary.backgroundColor ? state.buttons.secondary.backgroundColor.toString() : ''),
        15
      );
      styleString += `
        .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:hover,
        .no-touch .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:hover {
          background-color: ${bgColorAdjustedBrightness}
        }

        .touch .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:active,
        .touch .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:active {
          background-color: ${bgColorAdjustedBrightness}
        }
      `;
    }

    if (state?.buttons?.secondary) {
      const buttonEffect = state.buttons.secondary.buttonEffect;
      if (buttonEffect && layoutKey === 'site--desktop') {
        if (buttonEffect === 'hvr-back-pulse') {
          styleString += `
            .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:hover,
            .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:hover,
            .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:active,
            .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:active
            .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:focus,
            .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:focus'{
              'background-color: transparent;
              -webkit-animation: back-pulse-sec 1s linear .2s infinite;
              animation: back-pulse-sec 1s linear .2s infinite;'
            }
            @keyframes back-pulse-sec {
              50% {
                background-color: ${formatColor(
                  state.buttons.secondary.backgroundColor ? state.buttons.secondary.backgroundColor.toString() : ''
                )};
              }
            }
          `;
        } else {
          const formattedBackgroundColorHover = formatColor(
            state.buttons.secondary.backgroundColorHover ? state.buttons.secondary.backgroundColorHover.toString() : ''
          );
          styleString += `
            .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:before,
            .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:before {
              background-color: ${formattedBackgroundColorHover};
            }
          `;
        }
      }

      if (state?.buttons.secondary && state.buttons.secondary.textColorHover) {
        styleString += `
        .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:hover,
        .no-touch .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:hover {
          color: ${formatColor(state.buttons.secondary.textColorHover)};
        }

        .touch .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:active,
        .touch .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:active {
          color: ${formatColor(state.buttons.secondary.textColorHover)};
        }
      `;
      }

      if (state?.buttons.secondary.borderColorHover) {
        styleString += `
          .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:hover,
          .no-touch .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:hover {
            border-color: ${formatColor(state.buttons.secondary.borderColorHover)};
          }

          .touch .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:active,
          .touch .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:active {
            border-color: ${formatColor(state.buttons.secondary.borderColorHover)};
          }
        `;
      }

      // If it has a value
      if (state?.buttons?.secondary && state.buttons?.secondary?.backgroundColorHover) {
        const formattedBgHoverColor = formatColor(state.buttons.secondary.backgroundColorHover);
        styleString += `
        .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:hover,
        .no-touch .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:hover {
          background-color: ${formattedBgHoverColor};
        }

        .touch .${layoutKey}.site .campaign__page .content__item-action.btn.btn--secondary:active,
        .touch .${layoutKey}.site .modal .content__item-action.btn.btn--secondary:active {
          background-color: ${formattedBgHoverColor};
        }
      `;
      }
    }

    if (state.buttons) {
      // IF background-color hover is empty
      if (
        state.buttons.backgroundColor &&
        state.buttons.backgroundColor !== '' &&
        !state.buttons.backgroundColorHover
      ) {
        const adjustBackgroundColorBrightness = adjustBrightness(formatColor(state.buttons.backgroundColor), 15);
        styleString += `
          .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
          .no-touch .${layoutKey}.site .modal .content__item-action.btn:hover {
            background-color: ${adjustBackgroundColorBrightness};
          }
          .touch .${layoutKey}.site .campaign__page .content__item-action.btn:active,
          .touch .${layoutKey}.site .modal .content__item-action.btn:active {
            background-color: ${adjustBackgroundColorBrightness};
          }
        `;
      }

      if (state?.buttons?.backgroundColorHover) {
        styleString += `
          .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
          .no-touch .${layoutKey}.site .modal .content__item-action.btn:hover {
            background-color: ${formatColor(state.buttons.backgroundColorHover)};
          }
          .touch .${layoutKey}.site .campaign__page .content__item-action.btn:active,
          .touch .${layoutKey}.site .modal .content__item-action.btn:active {
            background-color: ${formatColor(state.buttons.backgroundColorHover)};
          }
        `;

        if (state.buttons && state.buttons.buttonEffect && layoutKey === 'site--desktop') {
          if (state.buttons.buttonEffect === 'hvr-back-pulse') {
            styleString += `
              .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
              .${layoutKey}.site .modal .content__item-action.btn:hover,
              .${layoutKey}.site .campaign__page .content__item-action.btn:active,
              .${layoutKey}.site .modal .content__item-action.btn:active
              .${layoutKey}.site .campaign__page .content__item-action.btn:focus,
              .${layoutKey}.site .modal .content__item-action.btn:focus {
                background-color: transparent;
                -webkit-animation: back-pulse 1s linear .2s infinite;
                animation: back-pulse 1s linear .2s infinite;
              }

              @keyframes back-pulse-sec {
                50% {
                  background-color:${formatColor(state.buttons.backgroundColorHover)};
                }
              }
            `;
          } else {
            styleString += `
              .${layoutKey}.site .campaign__page .content__item-action.btn:before,
              .${layoutKey}.site .modal .content__item-action.btn:before {
                 background-color: ${formatColor(state.buttons.backgroundColorHover)};
              }
            `;
          }
        }
      }

      if (state?.buttons && state.buttons.textColorHover) {
        styleString += `
        .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
        .no-touch .${layoutKey}.site .modal .content__item-action.btn:hover {
          color: ${formatColor(state.buttons.textColorHover)} !important;
        }

        .touch .${layoutKey}.site .campaign__page .content__item-action.btn:active,
        .touch .${layoutKey}.site .modal .content__item-action.btn:active {
          color: ${formatColor(state.buttons.textColorHover)} !important;
        }
      `;
      }

      if (state.buttons?.borderColorHover) {
        styleString += `
        .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
        .no-touch .${layoutKey}.site .modal .content__item-action.btn:hover {
          border-color: ${formatColor(state.buttons.borderColorHover)} !important;
        }

        .touch .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
        .touch .${layoutKey}.site .modal .content__item-action.btn:hover {
          border-color: ${formatColor(state.buttons.borderColorHover)} !important;
        }
      `;
      }

      if (state?.buttons && state.buttons.hoverTextStyle) {
        const hoverTextStyle = state.buttons.hoverTextStyle;

        if (hoverTextStyle.includes('underline')) {
          styleString += `
          .no-touch .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
          .no-touch .${layoutKey}.site .modal .content__item-action.btn:hover {
            text-decoration: underline;
          }

          .touch .${layoutKey}.site .campaign__page .content__item-action.btn:hover,
          .touch .${layoutKey}.site .modal .content__item-action.btn:hover {
            text-decoration: underline;
          }
        `;
        }
      }
    }

    return styleString;
  }

  public getFlowPageModel(flowPageId: number): FlowpageModel | undefined {
    return this.state.flowPages.find((flowPageModel) => {
      return flowPageModel.id === flowPageId;
    });
  }

  public getFlowPageIndex(flowPageId: number): number | null {
    let flowIndex: number | null = null;
    this.state.flowPages.forEach((flowPageModel, flowPageIndex) => {
      if (flowPageModel.id === flowPageId) {
        flowIndex = flowPageIndex;
      }
    });
    return flowIndex;
  }

  public getGamePlayModel() {
    let gameplayModel: AddonGameplayModel | undefined;

    this.state.flowPages.forEach((pageModel) => {
      pageModel.getAddons<AddonGameplayModel>('gameplay').forEach((flowModel) => {
        gameplayModel = flowModel;
      });
    });

    return gameplayModel;
  }

  public hasFocusStyle() {
    const focus = this.state.layout?.focus;
    return !!focus?.width; // This will return true if focus.width exists and is not 0, otherwise false
  }

  public getPageUsingAddon(alias: string): SectionModelType | undefined {
    let model: SectionModelType | undefined;

    this.state.flowPages.forEach((pageModel) => {
      if (pageModel.getAddons(alias).length > 0) {
        model = pageModel;
      }
    });

    this.state.popovers.forEach((pageModel) => {
      if (pageModel.getAddons(alias).length > 0) {
        model = pageModel;
      }
    });

    this.state.sections.forEach((pageModel) => {
      if (pageModel.getAddons(alias).length > 0) {
        model = pageModel;
      }
    });

    return model;
  }

  public setContentReady(value: boolean) {
    this.state.contentReady = value;
  }

  /**
   * Get the field model for a form field based on it's field id.
   */
  public getRegistrationFieldModel(resolver: (field: FormElementModel) => boolean): FormElementModel | undefined {
    let formFieldModel: FormElementModel | undefined;

    this.state.flowPages.forEach((pageModel) => {
      pageModel.getAddons<AddonRegistrationModel>('registration').forEach((registrationModel) => {
        registrationModel.state.fields?.forEach((fieldModel) => {
          if (resolver(fieldModel)) {
            formFieldModel = fieldModel;
          }
        });
      });
    });

    if (!formFieldModel) {
      this.state.sections.forEach((pageModel) => {
        pageModel.getAddons<AddonRegistrationModel>('registration').forEach((registrationModel) => {
          registrationModel.state.fields?.forEach((fieldModel) => {
            if (resolver(fieldModel)) {
              formFieldModel = fieldModel;
            }
          });
        });
      });
    }

    if (!formFieldModel) {
      this.state.popovers.forEach((pageModel) => {
        pageModel.getAddons<AddonRegistrationModel>('registration').forEach((registrationModel) => {
          registrationModel.state.fields?.forEach((fieldModel) => {
            if (resolver(fieldModel)) {
              formFieldModel = fieldModel;
            }
          });
        });
      });
    }

    return formFieldModel;
  }

  public get mainRegistrationPage(): FlowpageModel | undefined {
    for (const flowPage of this.state.flowPages) {
      for (const addon of flowPage.getAddons<AddonRegistrationModel>('registration')) {
        if (
          addon.state &&
          addon.state.fields &&
          addon.state.fields.some((field) => field.state.type === FieldType.EMAIL)
        ) {
          return flowPage;
        }
      }
    }
    return undefined;
  }

  get formFields(): FormElementModel[] {
    const fields: FormElementModel[] = [];

    this.state.flowPages.forEach((flowPage) => {
      flowPage.getAddons<AddonRegistrationModel>('registration').forEach((addon) => {
        if (addon.state && addon.state.fields) {
          addon.state.fields.forEach((field) => {
            fields.push(field);
          });
        }
      });
    });

    this.state.sections.forEach((flowPage) => {
      flowPage.getAddons<AddonRegistrationModel>('registration').forEach((addon) => {
        if (addon.state && addon.state.fields) {
          addon.state.fields.forEach((field) => {
            fields.push(field);
          });
        }
      });
    });

    this.state.popovers.forEach((flowPage) => {
      flowPage.getAddons<AddonRegistrationModel>('registration').forEach((addon) => {
        if (addon.state && addon.state.fields) {
          addon.state.fields.forEach((field) => {
            fields.push(field);
          });
        }
      });
    });

    return fields;
  }
}
function sanitizedTagCollection(replacementtags: { [key: string]: unknown }): { [key: string]: string } | undefined {
  let sanitizedTagCollection = {};
  for (const key in replacementtags) {
    if (Object.prototype.hasOwnProperty.call(replacementtags, key)) {
      if (typeof replacementtags[`${key}`] === 'string') {
        sanitizedTagCollection = {
          ...{ [key]: replacementtags[`${key}`] }
        };
      } else {
        // eslint-disable-next-line no-console
        console.error(`Replacement tag not found:`, replacementtags[`${key}`]);
      }
    }
  }
  return sanitizedTagCollection;
}
