import type { CSSProperties } from 'vue';
import { useCampaignStore } from '@/src/store/campaign';
import type { AddonSliderData, AddonSliderSettingsData } from '@/src/components/addons/slider/Data';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonModelState } from '@/src/typings/interfaces/state/Addon';
import { AlignContentType } from '@/src/typings/enums/enums';
import type { AdvancedAddonsStyleData } from '@/src/typings/interfaces/data/settings/settings';
import type { CampaignLayoutState } from '@/src/models/CampaignModel';
import { SliderType } from '@/src/components/addons/slider/enums';

interface AddonSliderState extends AddonModelState {
  settings: AddonSliderSettingState;
  values?: AddonSliderFields;
  value?: number;
  styling?: string;
  theme?: AddonSliderTheme;
  layout: AddonSliderLayout;
  sliderFields?: AddonSliderFields;
  wrapperStyles: CSSProperties;
}

interface AddonSliderSettingState {
  type: SliderType;
  layout?: AddonSliderLayout;
  values?: AddonSliderValues;
  value?: number;
  options?: AddonSliderOptions;
  advanced?: AdvancedAddonsStyleData;
}
interface AddonSliderValues {
  sliderValues?: number;
  minValue: number;
  maxValue: number;
}
interface AddonSliderFields {
  minValue?: number;
  maxValue?: number;
}

interface AddonSliderOptions {
  floor: number;
  ceil: number;
  step: number;
}

interface AddonSliderLayout {
  formWidth: CSSProperties['width'];
  formAlign: AlignContentType;
}

interface AddonSliderTheme {
  barColor: string;
  pointerColor: string;
}

export class AddonSliderModel extends AddonModel<AddonSliderData, AddonSliderState, 'slider'> {
  parseAddon(data: AddonSliderData) {
    const state = this.state;

    state.settings = state.settings ?? {};

    if (!data?.settings) {
      state.settings.type = SliderType.SIMPLE;
    }

    const campaignStore = useCampaignStore();
    const layout = campaignStore.model?.state.layout;

    state.settings = AddonSliderModel.parseSliderSettings(data);

    if (state.classIdentifier && layout) {
      state.styling = AddonSliderModel.constructThemeStyling(data, state.classIdentifier, layout);
      state.theme = AddonSliderModel.constructTheme(data, layout);
    } else {
      state.styling = state.theme = undefined;
    }

    state.layout = AddonSliderModel.constructLayoutSettingsState(data.settings);
    state.wrapperStyles = AddonSliderModel.constructWrapperClasses(data);

    if (data.settings.slider_fields) {
      state.sliderFields = {
        ...(data.settings.slider_fields.min_value && { minValue: Number(data.settings.slider_fields.min_value) }),
        ...(data.settings.slider_fields.max_value && { maxValue: Number(data.settings.slider_fields.max_value) })
      };
    } else {
      state.sliderFields = undefined;
    }
  }

  public getValueRoot(): string {
    return '';
  }

  public getValuePath(path: string): string[] {
    return ['settings', ...super.getValuePath(path)];
  }

  public setValue(path: string, value: string | number | boolean, shouldParse?: boolean): void {
    super.setValue(path, value, shouldParse);
  }

  private static parseSliderSettings(data: AddonSliderData): AddonSliderSettingState {
    if (data.settings.settings?.type === SliderType.RANGE) {
      return AddonSliderModel.constructRangeSliderSettings(data.settings);
    } else {
      return AddonSliderModel.constructSimpleSliderSettings(data.settings);
    }
  }

  private static constructSimpleSliderSettings(data: AddonSliderSettingsData) {
    return {
      type: SliderType.SIMPLE,
      options: {
        step: data.settings?.options?.step ? Number(data.settings?.options?.step) : 1,
        ceil: data.settings?.options?.ceil ? Number(data.settings?.options?.ceil) : 100,
        floor: data.settings?.options?.floor ? Number(data.settings?.options?.floor) : 1
      },
      value: data.settings?.values?.slider_value ? Number(data.settings?.values?.slider_value) : 10
    };
  }

  private static constructRangeSliderSettings(data: AddonSliderSettingsData) {
    return {
      type: SliderType.RANGE,
      options: {
        step: data.settings?.options?.step ? Number(data.settings?.options?.step) : 1,
        ceil: data.settings?.options?.ceil ? Number(data.settings?.options?.ceil) : 100,
        floor: data.settings?.options?.floor ? Number(data.settings?.options?.floor) : 1
      },
      values: {
        minValue: data.settings?.values?.min_value ? Number(data.settings?.values.min_value) : 10,
        maxValue: data.settings?.values?.max_value ? Number(data.settings?.values.max_value) : 90
      }
    };
  }

  private static constructLayoutSettingsState(data: AddonSliderSettingsData): AddonSliderLayout {
    return {
      formAlign: data.layout?.form_align || AlignContentType.LEFT,
      formWidth: data.layout?.form_width || '100%'
    };
  }

  private static constructTheme(data: AddonSliderData, layout: CampaignLayoutState): AddonSliderTheme {
    return {
      barColor: data.settings.theme?.bar_color || layout.baseColor || '#000000',
      pointerColor: data.settings.theme?.pointer_color || layout.textColor || '#ffffff'
    };
  }

  private static constructWrapperClasses(data: AddonSliderData): CSSProperties {
    return {
      width: data.settings.layout?.form_width,
      marginLeft: data.settings.layout?.form_align === AlignContentType.LEFT ? '0px' : 'auto',
      marginRight: data.settings.layout?.form_align === AlignContentType.RIGHT ? '0px' : 'auto'
    };
  }

  private static constructThemeStyling(data: AddonSliderData, addonId: string, layout: CampaignLayoutState): string {
    return `.${addonId} .slider-base {background-color: ${layout.backgroundColor};}
            .${addonId} .slider-handle {background-color: ${
              data.settings.theme?.pointer_color || layout.baseColor
            } !important;}
            .${addonId} .slider-connects {background:${layout.baseColor}}
            .${addonId} .slider-connect  {
            background: ${data.settings.theme?.bar_color || layout.backgroundColor};
            }
            .${addonId} .slider-tooltip {
              color:${layout.textColor};
            }`;
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
