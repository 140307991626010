<template>
  <div
    class="scroll-indicator theme-base-color-type-fill-color"
    :class="{ 'scroll-indicator--active': isIndicatorActive }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 90 90"
      enable-background="new 0 0 90 90"
      xml:space="preserve"
      :style="{ fill: color }"
    >
      <circle cx="45" cy="13" r="3"></circle>
      <path
        d="M45,0.5c-8.284,0-15,6.717-15,15.002v24.996C30,48.783,36.716,55.5,45,55.5s15-6.717,15-15.002V15.502  C60,7.217,53.284,0.5,45,0.5z M58,40.498C58,47.667,52.168,53.5,45,53.5c-7.168,0-13-5.833-13-13.002V15.502  C32,8.333,37.832,2.5,45,2.5c7.168,0,13,5.833,13,13.002V40.498z"
      ></path>
      <path
        d="M58.293,59.793L45,73.086L31.707,59.793c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l14,14  C44.488,75.402,44.744,75.5,45,75.5s0.512-0.098,0.707-0.293l14-14c0.391-0.391,0.391-1.023,0-1.414S58.684,59.402,58.293,59.793z"
      ></path>
      <path
        d="M58.293,73.793L45,87.086L31.707,73.793c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l14,14  C44.488,89.402,44.744,89.5,45,89.5s0.512-0.098,0.707-0.293l14-14c0.391-0.391,0.391-1.023,0-1.414S58.684,73.402,58.293,73.793z"
      ></path>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { getDocumentHeight, getScrollY } from '@/src/utilities/Utilities';
import { useUtilityStore } from '@/src/store/utility';

export default defineComponent({
  name: 'ScrollIndicator',
  props: {
    color: {
      type: String
    }
  },
  emits: ['removeScrollIndicator'],
  setup(_props, context) {
    const utilityStore = useUtilityStore();
    const isIndicatorActive = ref(false);

    const onScrollDetected = () => {
      if (window.scrollY > 50) {
        isIndicatorActive.value = false;
        context.emit('removeScrollIndicator');
      }
    };

    const shallIndicatorShow = () => {
      if (typeof window === 'undefined') {
        return false;
      }

      const currentScroll = getScrollY();
      const scrollLeft = getDocumentHeight() - (window.innerHeight + currentScroll);

      return currentScroll === 0 && scrollLeft >= 1;
    };

    document.addEventListener('scroll', () => {
      onScrollDetected();
    });

    onMounted(() => {
      if (shallIndicatorShow()) {
        isIndicatorActive.value = true;
        utilityStore.addBodyClass('scroll-indicator--active');
      } else {
        isIndicatorActive.value = false;
      }
    });

    onBeforeUnmount(() => {
      utilityStore.removeBodyClass('scroll-indicator--active');
      document.removeEventListener('scroll', onScrollDetected);
    });

    return {
      isIndicatorActive
    };
  }
});
</script>

<style lang="scss">
@keyframes bounce-scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, 10px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.scroll-indicator {
  position: fixed;
  left: 50%;
  bottom: 35px;
  width: 7rem;
  height: 5rem;
  margin-left: -3.5rem;
  z-index: 21;
  fill: red !important;
  animation: bounce-scroll 1s infinite;
  transition:
    visibility 0ms linear 150ms,
    opacity 150ms linear;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.scroll-indicator--active {
    visibility: visible;
    opacity: 1;
    transition-delay: 0ms;
  }
}
</style>
