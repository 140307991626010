import { FormElementTextModel } from '@/src/components/addons/registration/Models/FormElementTextModel';
import { FormElementTextareaModel } from '@/src/components/addons/registration/Models/FormElementTextareaModel';
import { FormElementCheckboxModel } from '@/src/components/addons/registration/Models/FormElementCheckboxModel';
import { FormElementSelectModel } from '@/src/components/addons/registration/Models/FormElementSelectModel';
import { FormElementDateModel } from '@/src/components/addons/registration/Models/FormElementDateModel';
import { FormElementRadioModel } from '@/src/components/addons/registration/Models/FormElementRadioModel';
import { FormElementRatingModel } from '@/src/components/addons/registration/Models/FormElementRatingModel';
import { FormElementSignatureModel } from '@/src/components/addons/registration/Models/FormElementSignatureModel';
import { FormElementCVRModel } from '@/src/components/addons/registration/Models/FormElementCVRModel';
import { FormElementDividerModel } from '@/src/components/addons/registration/Models/FormElementDividerModel';
import { FormElementSliderModel } from '@/src/components/addons/registration/Models/FormElementSliderModel';
import { FormElementDawaModel } from '@/src/components/addons/registration/Models/FormElementDawaModel';
import { FormElementFileModel } from '@/src/components/addons/registration/Models/FormElementFileModel';
import { FormElementHiddenModel } from '@/src/components/addons/registration/Models/FormElementHiddenModel';
import { FormElementMaskModel } from '@/src/components/addons/registration/Models/FormElementMaskModel';
import { FormElementCaptchaModel } from '@/src/components/addons/registration/Models/FormElementCaptchaModel';
import { FormElementParagraphModel } from '@/src/components/addons/registration/Models/FormElementParagraphModel';
import { FormElementReCaptchaModel } from '@/src/components/addons/registration/Models/FormElementReCaptchaModel';
import { type FormElementModelType } from '@/src/components/addons/registration/types';

export const FormElementMapping: Record<string, FormElementModelType> = {
  text: FormElementTextModel,
  email: FormElementTextModel,
  tel: FormElementTextModel,
  textarea: FormElementTextareaModel,
  checkbox: FormElementCheckboxModel,
  select: FormElementSelectModel,
  date: FormElementDateModel,
  radio: FormElementRadioModel,
  rating: FormElementRatingModel,
  signature: FormElementSignatureModel,
  cvr: FormElementCVRModel,
  divider: FormElementDividerModel,
  slider: FormElementSliderModel,
  dawa: FormElementDawaModel,
  file: FormElementFileModel,
  hidden: FormElementHiddenModel,
  mask: FormElementMaskModel,
  captcha: FormElementCaptchaModel,
  paragraph: FormElementParagraphModel,
  recaptcha: FormElementReCaptchaModel
};
