import type { Component } from 'vue';
import { useClipboardStore } from '@/src/store/clipboard';
import { useEditingStore } from '@/src/store/editing';
import { useCampaignStore } from '@/src/store/campaign';
import { BaseModel } from '@/src/models/BaseModel';
import type { SectionData } from '@/src/typings/interfaces/data/section';
import { CustomCSSModel } from '@/src/models/CustomCSSModel';
import type { SettingsModel } from '@/src/components/layout/SettingsModel';
import RowModel from '@/src/components/layout/row/RowModel';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { CustomCSS, SettingsData } from '@/src/typings/interfaces/data/settings/settings';
import useAxios from '@/src/hooks/useAxios';
import type { ContentRowData } from '@/src/typings/interfaces/data/grid/row';
import type { ContentDataGrid } from '@/src/typings/interfaces/data/content';
import type { CampaignModel } from '@/src/models/CampaignModel';
import { CampaignDeviceType, SectionType } from '@/src/typings/enums/enums';
import { BlockTypes } from '@/src/typings/types/types';
import type { ContentAddonType } from '@/src/components/layout/column/ColumnModel';
import type { ContentColumnData } from '@/src/typings/interfaces/data/grid/column';
import { createEditFormUrl, generateUniqueId } from '@/src/utilities/Utilities';
import { getAdminUiEndpoint } from '@/src/utilities/Url';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';

export enum ContentType {
  GRID = 'grid',
  TEXT = 'text',
  COMPONENT = 'component'
}

export type Content = ContentText | ContentGrid | ContentComponent;

export interface ContentText {
  type: ContentType.TEXT;
  content: string;
}

export interface ContentGrid {
  type: ContentType.GRID;
  rows: RowModel[];
}

export interface ContentComponent {
  type: ContentType.COMPONENT;
  loader: () => Promise<Component>;
}

export interface SectionConfig {
  clone: boolean;
  cloneId: number;
  devices: {
    desktop?: boolean;
    mobile?: boolean;
  };
  global: boolean;
  globalInUse: boolean;
  hidden: boolean;
  locked: boolean;
  settings: SettingsModel;
  title?: string;
  menu?: {
    active: boolean;
  };
  hasRegistrationAddon: boolean;
  hasGameflowAddon: boolean;
}

interface SectionEditState {
  isCollapsed?: boolean;
  isActive?: boolean;
  isPreviewing?: boolean;
  isFocus?: boolean;
  canOpenGameSettings?: boolean;
  canOpenSectionSettings?: boolean;
  sectionRef?: HTMLElement;
  cloneOfTitle?: string;
  cloneOfDescription?: string;
  cloneOfLink?: string;
}

export interface SectionState {
  modelId: string;
  id: number;
  classIdentifier: string;
  title: string | undefined;
  edit?: SectionEditState;
  content: Content[];
  config: SectionConfig;
  customCss: CustomCSSModel | undefined;
  // Used for programmatic & fictive flowPages
  programmatic?: boolean;

  // This feature is used by skip-registration. When a registration is told to skip it should "hide" the DOM element
  // by adding a class. So this is a rather specific state property for flow pages. Just FYI (Dannie been here)
  hidden: boolean;
}

export abstract class SectionBaseModel extends BaseModel<SectionData, SectionState> {
  public previewData?: Pick<SectionData, 'content' | 'config'>;
  public campaignModel: CampaignModel;
  public id: number;
  public isSaving = false;
  public isSaved = false;

  constructor(data: SectionData, campaignModel: CampaignModel) {
    super(data);
    this.id = data.id;
    this.campaignModel = campaignModel;
    this.parse(this.getData());
  }

  getValueRoot() {
    return 'config.settings';
  }

  getValuePath(path: string): string[] {
    // Overwrite path if the path is title
    if (path === 'title') {
      return ['config', 'title'];
    }

    return super.getValuePath(path);
  }

  parse(data: SectionData): void {
    if (this.previewData) {
      data = {
        ...this.getData(),
        ...this.previewData
      };
    }

    // Store current state as a variable so that we don't continuously ask for the state through the getter
    const state = this.state;

    if (!state.modelId) {
      state.modelId = this.modelId;
    }

    this.id = data.original_id;

    if (!state.id) {
      state.id = data.original_id;
    }

    state.classIdentifier = 'section--' + state.id;
    state.title = data.config?.title || data.title;
    state.programmatic = data.programmatic || false;

    const rowMap: { [key: string]: RowModel } = {};

    if (state.content && (state.content[0] as ContentGrid).rows) {
      (state.content[0] as ContentGrid).rows.forEach((row) => {
        rowMap[row.state.id] = row;
      });
    }

    state.content = data.content?.map<Content>((item) => {
      if (item.type === ContentType.TEXT) {
        return {
          type: ContentType.TEXT,
          content: item.content
        };
      } else if (item.type === ContentType.GRID) {
        return {
          type: ContentType.GRID,
          rows: item.rows.map<RowModel>((row) => {
            if (typeof row === 'string') {
              row = {
                columns: []
              };
            }

            if (row.id && typeof rowMap[row.id] !== 'undefined') {
              const rowMapModel = rowMap[row.id];
              rowMapModel.setData(row);
              return rowMapModel;
            }
            return new RowModel(row, this);
          })
        };
      } else if (item.type === ContentType.COMPONENT) {
        return {
          type: ContentType.COMPONENT,
          loader: item.loader
        };
      }

      throw new Error(`Unknown content type in section "${data.id}".`);
    });

    state.config = state.config || {};
    state.config.global = data.config.global || false;
    state.config.globalInUse = data.config.global_in_use || false;
    state.config.clone = data.config.clone || false;
    if (data.config?.clone_id || data.config?.clone_id === 0) {
      state.config.cloneId = data.config.clone_id;
    }

    state.config.hidden = data.config.hidden;

    state.config.locked = data.config.locked;

    state.config.devices = {};
    state.config.devices.desktop = data.config?.devices?.desktop;
    state.config.devices.mobile = data.config?.devices?.mobile;

    if (data.config.menu) {
      state.config.menu = {
        active: data.config.menu.active ?? false
      };
    }

    if (data.config?.settings) {
      if (state.config.settings) {
        state.config.settings.setData(data.config.settings);
      } else {
        state.config.settings = this.getSettingsModel(data.config.settings);
      }
    } else if (state.config.settings) {
      state.config.settings.setData({});
    } else {
      state.config.settings = this.getSettingsModel({});
    }

    const editingStore = useEditingStore();
    const campaignStore = useCampaignStore();

    state.config.hasGameflowAddon = this.checkForAddons('gameflow');

    if (state.config.hasGameflowAddon) {
      editingStore.sectionWithGame = this;
    }

    state.config.hasRegistrationAddon = this.checkForAddons('registration');

    if (!state.edit && campaignStore.model?.state.edit?.enabled) {
      state.edit = {};
      state.edit.isActive = false;
      state.edit.isCollapsed = true;
      state.edit.isFocus = false;
    }

    if (state.config.clone && state.edit) {
      if (this.getSectionType() === SectionType.SECTION) {
        state.edit.cloneOfTitle = 'Clone of global section';
        state.edit.cloneOfDescription =
          'If you wish to edit this section you need to edit it on the original campaign.';
        state.edit.cloneOfLink = 'Click here to edit the original section';
      }
      if (this.getSectionType() === SectionType.FLOWPAGE) {
        state.edit.cloneOfTitle = 'Clone of global flow page';
        state.edit.cloneOfDescription =
          'If you wish to edit this flow page you need to edit it on the original campaign.';
        state.edit.cloneOfLink = 'Click here to edit the original flow page.';
      }
      if (this.getSectionType() === SectionType.POPOVER) {
        state.edit.cloneOfTitle = 'Clone of global popover';
        state.edit.cloneOfDescription =
          'If you wish to edit this popover you need to edit it on the original campaign.';
        state.edit.cloneOfLink = 'Click here to edit the original popover.';
      }
    }

    // We need an identifier for the CSS - '.section--' + data.id
    if (data.config.settings?.advanced?.customcss) {
      state.customCss = SectionBaseModel.parseCustomCSSData(data, state.classIdentifier);
    }
  }

  public hasCustomCss() {
    if (this.state.customCss?.state?.code) {
      return true;
    }

    return this.state.content.some((content) => {
      if (content.type === ContentType.GRID && content.rows.length > 0) {
        return content.rows.some((rowModel) => {
          if (rowModel.state.customCss?.state.code) {
            return true;
          }

          return rowModel.state.columns.some((columnModel) => {
            if (columnModel.state.customCss?.state.code) {
              return true;
            }

            return columnModel.state.addons.some((addonModel) => {
              return !!addonModel.state.customCss?.state.code;
            });
          });
        });
      }

      return false;
    });
  }

  get getSectionElementId() {
    return this.state.config.settings.state.advanced?.advanced?.elementId || '';
  }

  public getSection() {
    return this;
  }

  public getEditUrl() {
    const editFormUrl = `/edit/campaign/page/${this.campaignModel.id}/${this.state.id}`;
    return createEditFormUrl(editFormUrl);
  }

  setHiddenState(isVisible: boolean) {
    this.state.hidden = isVisible;
  }

  setSectionRefElement(ref: HTMLElement) {
    if (this.state.edit) {
      this.state.edit.sectionRef = ref;
    }
  }

  canOpenGameSettings() {
    const state = this.state;
    return state.config.hasGameflowAddon || this.getSectionType() === SectionType.FLOWPAGE;
  }

  canOpenSectionSettings() {
    return this.getSectionType() === SectionType.FLOWPAGE;
  }

  canDelete(): boolean {
    const state = this.state;
    return !(state.config && state.config.clone);
  }

  canMakeGlobal(): boolean {
    const campaignStore = useCampaignStore();

    // Ads cannot use global content, so we should also make sure they cannot make global content
    if (campaignStore.model?.state.deviceType === CampaignDeviceType.ADS) {
      return false;
    }

    const hasRegistrationAddon = this.getAddons<AddonRegistrationModel>('registration').length > 0;
    const hasGameplayAddon = this.getAddons<AddonRegistrationModel>('gameplay').length > 0;
    const state = this.state;

    return state.config && !state.config.clone && !state.config.global && !hasRegistrationAddon && !hasGameplayAddon;
  }

  canMakeLocal(): boolean {
    const state = this.state;
    return !!(state.config && !state.config.clone && state.config.global);
  }

  canUnlink(): boolean {
    const state = this.state;
    return !!(state.config && state.config.clone && !state.config.global);
  }

  async onUnlink() {
    const data = this.getData();
    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/page/unlink?campaign_id=${this.campaignModel.id}`;
    const { postDataFormData } = useAxios(postUrl, {
      page_id: this.state.id
    });

    data.config.clone = false;
    data.config.clone_id = 0;
    this.parse(data);
    return await postDataFormData();
  }

  canOpenOriginCampaign() {
    const state = this.state;
    return state !== null && state.config && state.config.clone;
  }

  canDuplicate(): boolean {
    const state = this.state;
    if (state.config && state.config.clone) {
      return false;
    } else if (state.config.hasRegistrationAddon) {
      return false;
    }
    return true;
  }

  canPaste(): boolean {
    const clipboardStore = useClipboardStore();

    if (clipboardStore.elementInClipboard instanceof SectionBaseModel) {
      const state = this.state;

      if (state.config && state.config.clone) {
        return false;
      }

      return !state.config.hasRegistrationAddon;
    }

    return false;
  }

  canMoveUp(): boolean {
    const state = this.state;
    if (state.config && state.config.clone) {
      return false;
    }
    return this.index > 0;
  }

  async onMoveUp() {
    const campaignStore = useCampaignStore();
    const editingStore = useEditingStore();

    if (!campaignStore.model) {
      return;
    }

    const order = this.getCurrentPageOrder();

    const currentItem = order[this.index];
    const parentItem = order[this.index - 1];

    order[this.index] = parentItem;
    order[this.index - 1] = currentItem;

    editingStore.loading = true;

    await campaignStore.model.saveOrder(this.getSectionType(), order);
    await campaignStore.model.reloadCampaign();

    this.activateEditing();

    editingStore.loading = false;
  }

  private getCurrentPageOrder(): number[] {
    const campaignStore = useCampaignStore();
    let order: number[] = [];

    if (!campaignStore.model) {
      return [];
    }

    switch (this.getSectionType()) {
      case SectionType.SECTION:
        order = campaignStore.model.state.sections.map((page) => page.id);
        break;
      case SectionType.FLOWPAGE:
        order = campaignStore.model.state.flowPages.map((page) => page.id);
        break;
      case SectionType.POPOVER:
        order = campaignStore.model.state.popovers.map((page) => page.id);
        break;
    }

    return order;
  }

  canMoveDown(): boolean {
    const campaignStore = useCampaignStore();
    const campaignState = campaignStore.model?.state;

    if (!campaignState) {
      return false;
    }

    let sections = campaignState.sections;

    if (this.getSectionType() === SectionType.FLOWPAGE) {
      sections = campaignState.flowPages;
    } else if (this.getSectionType() === SectionType.POPOVER) {
      sections = campaignState.popovers;
    }

    const numberOfSections = sections.length;

    if (numberOfSections - 1 <= this.index) {
      return false;
    }

    return true;
  }

  async onMoveDown() {
    const campaignStore = useCampaignStore();
    const editingStore = useEditingStore();

    if (!campaignStore.model) {
      return;
    }

    const order = this.getCurrentPageOrder();

    const currentItem = order[this.index];
    const parentItem = order[this.index + 1];

    order[this.index] = parentItem;
    order[this.index + 1] = currentItem;

    editingStore.loading = true;

    await campaignStore.model.saveOrder(this.getSectionType(), order);
    await campaignStore.model.reloadCampaign();

    this.activateEditing();

    editingStore.loading = false;
  }

  canCopy(): boolean {
    return true;
  }

  onPasteElement() {
    const clipboardStore = useClipboardStore();
    const campaignStore = useCampaignStore();
    const campaignModel = campaignStore.model;

    if (!campaignModel) {
      return;
    }

    let type: BlockTypes | undefined;

    if (this.getSectionType() === SectionType.SECTION) {
      type = BlockTypes.SECTIONS;
    } else if (this.getSectionType() === SectionType.FLOWPAGE) {
      type = BlockTypes.PAGES_FLOW;
    } else if (this.getSectionType() === SectionType.POPOVER) {
      type = BlockTypes.PAGES_PAGE;
    }

    if (type && clipboardStore.elementInClipboard instanceof SectionBaseModel) {
      campaignModel.onAddSection(clipboardStore.elementInClipboard, type, true, this.id);
    }
  }

  onOpenOriginCampaign() {
    window.open(`${getAdminUiEndpoint()}/api/v1/campaign/page-global-redirect/${this.state.id}`);
  }

  async onPasteRow(rowData: string, rowIndex: number) {
    const editingStore = useEditingStore();
    const data = this.getData();
    const rows = (data.content[0] as ContentDataGrid).rows;
    const clone = JSON.parse(rowData) as ContentRowData;
    clone.id = generateUniqueId();

    // If rows contains registration, remove all fields, as we can only have 1 registration
    if (clone && clone.columns && clone.columns.length > 0) {
      clone.columns.forEach((column) => {
        if (typeof column === 'string') {
          return;
        }

        column.addons.forEach((addon) => {
          if (addon.alias === 'registration' && addon.settings) {
            addon.settings.fields = [];
          }
        });
      });

      // Filter out gameflow or gameplay, as we can only have one
      clone.columns.forEach((column) => {
        if (typeof column === 'string') {
          return;
        }

        column.addons = column.addons.filter((addon) => {
          return addon.alias !== 'gameflow' && addon.alias !== 'gameplay';
        });
      });
    }

    if (clone.columns.length > 0) {
      clone.columns.forEach((column) => {
        if (typeof column === 'string') {
          return;
        }

        column.id = generateUniqueId();

        if (column.addons.length > 0) {
          column.addons.forEach((addon) => {
            addon.id = generateUniqueId();
          });
        }
      });
    }

    // Insert new addon on correct index
    rows.splice(rowIndex + 1, 0, clone);

    this.parse(data);
    await this.saveBlock();

    (this.state.content[0] as ContentGrid).rows.forEach((row, index) => {
      if (index === rowIndex + 1) {
        editingStore.setActiveModel(row);
      }
    });
  }

  onMoveRowUp(currentRowIndex: number) {
    const editingStore = useEditingStore();

    currentRowIndex = Number(currentRowIndex);

    const data = this.getData();
    const rows = (data.content[0] as ContentDataGrid).rows;
    const currentItem = rows[Number(currentRowIndex)];

    rows[Number(currentRowIndex)] = rows[currentRowIndex - 1];
    rows[currentRowIndex - 1] = currentItem;

    (data.content[0] as ContentDataGrid).rows = rows;
    this.parse(data);

    editingStore.setActiveModel((this.state.content[0] as ContentGrid).rows[currentRowIndex - 1]);
  }

  onMoveRowDown(currentRowIndex: number) {
    const editingStore = useEditingStore();
    const data = this.getData();
    const rows = (data.content[0] as ContentDataGrid).rows;
    const currentItem = rows[Number(currentRowIndex)];

    rows[Number(currentRowIndex)] = rows[currentRowIndex + 1];
    rows[currentRowIndex + 1] = currentItem;

    (data.content[0] as ContentDataGrid).rows = rows;
    this.parse(data);

    editingStore.setActiveModel((this.state.content[0] as ContentGrid).rows[currentRowIndex + 1]);
  }

  async makeSectionGlobal() {
    const state = this.state;
    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/page/makeGlobal?campaign_id=${this.campaignModel.id}`;
    const { postDataFormData } = useAxios(postUrl, {
      page_id: this.state.id
    });

    await postDataFormData();

    state.config.global = true;
  }

  isSectionGlobalAndInUse() {
    const state = this.state;
    return !!(state.config.global && state.config.globalInUse);
  }

  async makeSectionLocal() {
    const state = this.state;
    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/page/makeLocal?campaign_id=${this.campaignModel.id}`;
    const { postDataFormData } = useAxios(postUrl, {
      page_id: this.state.id
    });

    await postDataFormData();

    state.config.global = false;
  }

  openSectionSettings() {
    const editingStore = useEditingStore();
    const state = this.state;

    if (this.getSectionType() === SectionType.FLOWPAGE && state.edit) {
      state.edit.isActive = false;
      const sectionWithGame = editingStore.sectionWithGame;

      if (!sectionWithGame) {
        return;
      }

      editingStore.setActiveModel(sectionWithGame);
      editingStore.activeTabCategory = SectionType.SECTION;
    }
  }

  async deleteRow(rowIndex: number) {
    if (this.state.content[0] && this.state.content[0].type === ContentType.GRID) {
      await this.state.content[0].rows[Number(rowIndex)].onDelete();
    }

    const data = this.getData();
    (data.content[0] as ContentDataGrid).rows.splice(rowIndex, 1);
    this.parse(data);

    await this.saveBlock();
  }

  getRowByIndex(index: number) {
    return (this.state.content[0] as ContentGrid).rows[Number(index)];
  }

  async addNewRow(columns: ContentColumnData[]) {
    const data = this.getData();

    const editingStore = useEditingStore();

    const newRow: ContentRowData = {
      id: generateUniqueId(),
      columns,
      settings: {}
    };

    if (data.content[0].type === 'grid') {
      data.content[0].rows.push(newRow);
    }

    this.parse(data);
    await this.saveBlock();

    if (this.state.content[0].type === ContentType.GRID) {
      this.state.content[0].rows.forEach((row) => {
        if (row.state.id === newRow.id) {
          editingStore.setActiveModel(row);
        }
      });
    }
  }

  async delete() {
    const campaignStore = useCampaignStore();
    const promises: Promise<void>[] = [];

    this.state.content.forEach((content) => {
      if (content.type === ContentType.GRID && content.rows.length > 0) {
        promises.push(...content.rows.map((row) => row.onDelete()));
      }
    });

    await Promise.all(promises);

    const sectionIndex = this.index;
    await campaignStore.model?.onDeleteSection(sectionIndex, this.state.id, this.getSectionType());
    return true;
  }

  getTotalRows() {
    const rowItems = this.getData().content.filter((contentItem) => {
      return contentItem.type === ContentType.GRID;
    });

    return rowItems.length;
  }

  async saveBlock() {
    this.isSaving = true;

    const data = this.getData();
    const state = this.state;
    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/save/page?campaign_id=${
      this.campaignModel.id
    }&page_id=${state.id}&original_page_id=${state.id}`;

    const { postDataFormData } = useAxios(postUrl, {
      content: JSON.stringify((data.content[0] as ContentDataGrid).rows)
    });

    await postDataFormData();

    this.isSaving = false;
    this.isSaved = true;

    // Reset isSaved
    setTimeout(() => {
      this.isSaved = false;
    }, 500);
  }

  async onUnlockSection() {
    const editingStore = useEditingStore();

    const data = this.getData();
    const state = this.state;
    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/page/unlock?campaign_id=${Number(
      this.campaignModel.id
    )}`;

    const { postDataFormData } = useAxios<{ saved: boolean }>(postUrl, {
      page_id: Number(state.id)
    });

    const response = await postDataFormData();

    if (response.saved) {
      data.config.locked = false;
      this.parse(data);

      if (state.edit && state.edit.isActive) {
        editingStore.removeActiveModel();
      }
    }
  }

  reorderRows() {
    const data = this.getData();
    // @ts-ignore
    ((data as SectionData).content[0] as ContentDataGrid).rows = (this.state.content[0] as ContentGrid).rows.map(
      (row) => {
        return row.getData();
      }
    );

    this.parse(data);
  }

  async onLockSection() {
    const editingStore = useEditingStore();

    const data = this.getData();
    const state = this.state;
    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/page/lock?campaign_id=${Number(
      this.campaignModel.id
    )}`;

    const { postDataFormData } = useAxios<{ saved: boolean }>(postUrl, {
      page_id: Number(state.id)
    });

    const response = await postDataFormData();

    if (response.saved) {
      data.config.locked = true;
      this.parse(data);

      if (state.edit && state.edit.isActive) {
        editingStore.removeActiveModel();
      }
    }
  }

  async reload() {
    const campaignStore = useCampaignStore();

    const demoToken = campaignStore.demoToken;
    const data = this.getData();

    const { fetchData } = useAxios<SectionData>(
      `${getAdminUiEndpoint()}/api/v1/campaign/${this.campaignModel.id}/${this.campaignModel.hash}/section/${
        this.id
      }?vue=1${demoToken ? `&token=${demoToken}` : ''}`
    );

    const response = await fetchData();

    if (response?.title) {
      data.title = response.title;
    }

    if (response?.config) {
      data.config = response.config;
    }

    if (response?.content) {
      data.content = response.content;
    }

    this.parse(data);
  }

  public async createAuditLog() {
    const postUrl = `${getAdminUiEndpoint()}/api/v1/campaign/edit/page/create-audit-log?campaign_id=${
      this.campaignModel.id
    }&page_id=${this.state.id}`;

    const { postData } = useAxios(postUrl);

    await postData();
  }

  public shallSkipInitialParse(): boolean {
    return true;
  }

  private checkForAddons(alias: string): boolean {
    let hasAddon = false;

    this.state.content.forEach((item) => {
      if (item.type === ContentType.GRID) {
        item.rows.forEach((row) => {
          if (row.state.columns) {
            row.state.columns.forEach((col) => {
              if (col.state.addons) {
                col.state.addons.forEach((addon) => {
                  if (addon.alias === alias) {
                    hasAddon = true;
                  }
                });
              }
            });
          }
        });
      }
    });

    return hasAddon;
  }

  private static parseCustomCSSData(data: SectionData, identifier: string): CustomCSSModel | undefined {
    if (data.config.settings?.advanced?.customcss) {
      const useData = getDeviceData(data.config.settings.advanced.customcss) as CustomCSS | undefined;

      if (!useData) {
        return undefined;
      }

      if (useData.code && useData.code.length > 0) {
        return SectionBaseModel.constructCustomCSSState(useData, identifier);
      }

      return undefined;
    }
  }

  private static constructCustomCSSState(data: CustomCSS, identifier: string): CustomCSSModel {
    return new CustomCSSModel(data, identifier);
  }

  public abstract getSectionType(): SectionType;
  public abstract getSettingsModel(data: SettingsData): SettingsModel;

  public setEditingActive(): void {
    const state = this.state;
    if (state.edit) {
      state.edit.isActive = true;
      state.edit.isCollapsed = false;
    }
  }

  public hide() {
    this.state.hidden = true;
  }

  public show() {
    this.state.hidden = false;
  }

  public getAddons<Model extends ContentAddonType>(alias: string): Model[] {
    const state = this.state;
    const addonModels: Model[] = [];

    state.content.forEach((content) => {
      if (content.type === ContentType.GRID) {
        content.rows.forEach((row) => {
          row.state.columns.forEach((column) => {
            column.state.addons.forEach((addon) => {
              if (addon.alias === alias) {
                addonModels.push(addon as Model);
              }
            });
          });
        });
      }
    });

    return addonModels;
  }

  abstract get index(): number;

  abstract activateEditing(): void;
}
