<template>
  <div
    ref="gameEl"
    class="addon gameplay"
    :class="{
      'gameplay--has-indicators': hasIndicators,
      'gameplay--pointer-events-none': campaignState?.isEditModeActive,
      'gameplay--bottom-fill':
        bottomIndicators && bottomIndicators?.length > 0 && topIndicators && topIndicators?.length > 0 && !hasPosition
    }"
  >
    <Indicators
      v-if="state.settings?.game?.state.indicators"
      :model="state.settings?.game.state.indicators"
      :has-position="hasPosition"
      :top-indicators="topIndicators"
      :center-indicators="centerIndicators"
      :bottom-indicators="bottomIndicators"
    >
      <component :is="comp" v-if="isReady && state.settings?.game" key="game" :model="state.settings.game" />
    </Indicators>

    <component :is="comp" v-else-if="isReady && state.settings?.game" key="game" :model="state.settings.game" />
  </div>
</template>

<script lang="ts">
import type { Component, PropType } from 'vue';
import { computed, defineAsyncComponent, defineComponent, nextTick, ref } from 'vue';
import type { AddonGameplayModel } from '@/src/components/addons/gameplay/Model';
import type { GameIndicatorTypeState } from '@/src/components/indicators/Model';
import { GameIndicatorPositionType, GameIndicatorVAlignTypes } from '@/src/components/indicators/Model';
import { useCampaignStore } from '@/src/store/campaign';
import Indicators from '@/src/components/indicators/View.vue';

const gameComponent: { [key: string]: () => Promise<Component> } = {
  video_quiz: () => import('@/src/components/games/video-quiz/View.vue'),
  slotmachine: () => import('@/src/components/games/slot-machine/View.vue'),
  guessthepicture: () => import('@/src/components/games/guessthepicture/View.vue'),
  poll: () => import('@/src/components/games/poll/View.vue'),
  placetheitem: () => import('@/src/components/games/placetheitem/View.vue'),
  scratchcard: () => import('@/src/components/games/scratchcard/View.vue'),
  hitthetarget: () => import('@/src/components/games/hitthetarget/View.vue'),
  prediction: () => import('@/src/components/games/prediction/View.vue'),
  guesstheword: () => import('@/src/components/games/guesstheword/View.vue'),
  finderrors: () => import('@/src/components/games/spotthedifference/View.vue'),
  memory: () => import('@/src/components/games/memory/View.vue'),
  dice: () => import('@/src/components/games/dice/View.vue'),
  shootit: () => import('@/src/components/games/shootit/View.vue'),
  snake: () => import('@/src/components/games/snake/View.vue'),
  spinthebottle: () => import('@/src/components/games/spinthebottle/View.vue'),
  swipeit: () => import('@/src/components/games/swipeit/View.vue'),
  wof: () => import('@/src/components/games/wof/View.vue'),
  luckynumber: () => import('@/src/components/games/luckynumber/View.vue'),
  rockpaperscissors: () => import('@/src/components/games/rockpaperscissors/View.vue'),
  dropgame: () => import('@/src/components/games/dropgame/View.vue'),
  shell: () => import('@/src/components/games/shell/View.vue'),
  slidingpuzzle: () => import('@/src/components/games/sliding-puzzle/View.vue'),
  product_selector: () => import('@/src/components/games/product-selector/View.vue'),
  campaign_link: () => import('@/src/components/games/campaign-link/View.vue'),
  personality_test: () => import('@/src/components/games/personality-test/View.vue'),
  quiz: () => import('@/src/components/games/quiz/View.vue'),
  calendar: () => import('@/src/components/games/calendar/View.vue'),
  survey: () => import('@/src/components/games/survey/View.vue'),
  priority: () => import('@/src/components/games/priority/View.vue'),
  puzzle: () => import('@/src/components/games/puzzle/View.vue'),
  sliceit: () => import('@/src/components/games/sliceit/View.vue'),
  roulette: () => import('@/src/components/games/roulette/View.vue'),
  tapping: () => import('@/src/components/games/tapping/View.vue'),
  bounce_battle: () => import('@/src/components/games/bounce-battle/View.vue'),
  gravity_dodger: () => import('@/src/components/games/gravity-dodger/View.vue'),
  hit_a_mole: () => import('@/src/components/games/hit-a-mole/View.vue'),
  banko: () => import('@/src/components/games/banko/View.vue'),
  sudoku: () => import('@/src/components/games/sudoku/View.vue'),
  wordriddle: () => import('@/src/components/games/wordriddle/View.vue'),
  rush_runner: () => import('@/src/components/games/rush-runner/View.vue'),
  tap_to_reveal: () => import('@/src/components/games/tap-to-reveal/View.vue')
};

export default defineComponent({
  name: 'AddonGameplay',
  components: {
    Indicators
  },
  props: {
    model: {
      type: Object as PropType<AddonGameplayModel>,
      required: true
    }
  },
  setup(props) {
    const model = props.model as AddonGameplayModel;
    const state = model.state;
    const gameEl = ref<HTMLElement | null>(null);
    const campaignStore = useCampaignStore();
    const campaignState = campaignStore.model?.state;
    const isReady = ref(!state.hasInstantWin);

    let readyPromiseResolve: (() => void) | undefined;

    const hasIndicators = computed(() => {
      const indicators = state.settings?.game?.state?.indicators?.state?.indicators;
      return indicators ? indicators.length > 0 : false;
    });

    const topIndicators = computed<GameIndicatorTypeState[] | undefined>(() => {
      return state.settings?.game?.state?.indicators?.state?.indicators.filter(
        (el: GameIndicatorTypeState) => el.valign === GameIndicatorVAlignTypes.TOP
      );
    });

    const centerIndicators = computed<GameIndicatorTypeState[] | undefined>(() => {
      return state.settings?.game?.state?.indicators?.state?.indicators.filter(
        (el: GameIndicatorTypeState) => el.valign === GameIndicatorVAlignTypes.CENTER
      );
    });

    const bottomIndicators = computed<GameIndicatorTypeState[] | undefined>(() => {
      return state.settings?.game?.state?.indicators?.state?.indicators.filter(
        (el: GameIndicatorTypeState) => el.valign === GameIndicatorVAlignTypes.BOTTOM
      );
    });

    const hasPosition = computed<boolean>(() => {
      const bottom = state.settings?.game?.state?.indicators?.state.position.bottom;
      const top = state.settings?.game?.state?.indicators?.state.position.top;
      return bottom !== GameIndicatorPositionType.DEFAULT || top !== GameIndicatorPositionType.DEFAULT;
    });

    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const openGameOverlay = () => {
      if (model.state.settings?.gameOverlay) {
        campaignStore.setActivePopover(model.state.settings?.gameOverlay);
      }
    };

    const comp = defineAsyncComponent({
      loader: async () => {
        if (!state.settings?.gameAlias) {
          throw new Error('Missing game alias');
        }

        const component = await gameComponent[state.settings.gameAlias]();

        await nextTick();

        openGameOverlay();

        if (readyPromiseResolve) {
          readyPromiseResolve();
        }

        return component;
      }
    });

    if (state.hasInstantWin) {
      model
        .fetchInstantWinData()
        .then(() => {
          const startTime = new Date().getTime();
          setTimeout(
            () => {
              isReady.value = true;
            },
            Math.max(0, 625 - (new Date().getTime() - startTime))
          );
        })
        .catch(() => {
          isReady.value = true;

          campaignStore.addStaticFormData({
            instantWinFetchError: '1'
          });
        });
    }

    return {
      comp,
      state,
      campaignState,
      gameEl,
      isReady,
      hasPosition,
      topIndicators,
      bottomIndicators,
      centerIndicators,
      hasIndicators,
      onBeforeEnter: async () => {
        await readyPromise;

        // Not sure why 2 nextTicks are needed. But they are...
        await nextTick();
        await nextTick();

        return gameEl.value;
      }
    };
  }
});
</script>

<style lang="scss">
.gameplay {
  &--pointer-events-none {
    pointer-events: none;
    * {
      pointer-events: none !important;
    }
  }

  .content__item--action {
    margin-bottom: 0;
  }

  &--has-indicators {
    position: relative;
  }
}
</style>
