<template>
  <div
    :class="`content__item-form-${model.state.id}`"
    class="content__item-form-type content__item-form-type--radio"
    role="radiogroup"
    :aria-labelledby="`radio-group-label-${model.state.id}`"
  >
    <label :id="`radio-group-label-${model.state.id}`" class="content__item-form-radios-label">{{
      model.state.label
    }}</label>
    <div
      v-for="(item, index) in model.state.options"
      :key="index"
      class="content__item-form-radio form-check"
      :class="{
        'content__item-form-radio--first-child': index === 0,
        'form-group--is-focus-radio': inputFocused === index,
        'form-group--error': showValidationErrors && !state.isValid,
        'form-check--error': showValidationErrors && !state.isValid,
        'form-check--has-value': state.value,
        'form-check--valid': !!state.isValid
      }"
    >
      <!-- eslint-disable vue/valid-v-model -->
      <input
        :id="`${model.state.id}-${index}`"
        v-model="state.value as any"
        tabindex="0"
        class="content__item-form-radio-input form-check-input"
        type="radio"
        :name="`form[${model.state.id}]`"
        :value="item.value"
        :aria-label="item.value"
        :aria-checked="state.value === item.value ? 'true' : 'false'"
        role="radio"
        @blur="onBlur"
        @focus="onFocus(index)"
      />
      <!-- eslint-enable -->

      <label
        class="content__item-form-label content__item-form-label--radio form-check-label"
        :for="`${model.state.id}-${index}`"
        >{{ item.label }}</label
      >
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, ref } from 'vue';
import type { FormElementRadioModel } from '@/src/components/addons/registration/Models/FormElementRadioModel';

export default defineComponent({
  name: 'FormElementRadio',
  props: {
    model: {
      type: Object as PropType<FormElementRadioModel>,
      required: true
    },

    showValidationErrors: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const inputFocused = ref<undefined | number>(undefined);

    const onFocus = (index: number) => {
      inputFocused.value = index;
    };
    const onBlur = () => {
      inputFocused.value = undefined;
    };

    return {
      state: props.model.state,
      inputFocused,
      onFocus,
      onBlur
    };
  }
});
</script>
