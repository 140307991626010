import { useCampaignStore } from '@/src/store/campaign';
import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';
import type { IntegrationRecaptchaModel } from '@/src/components/integrations/recaptcha/Model';

export type FormElementCaptchaSettingsData = BaseFormElementSettingsData;

export interface FormElementCaptchaData extends BaseFormElementData {
  settings?: FormElementCaptchaSettingsData;
}

export type FormElementReCaptchaValue = {
  valid: string;
};

export type FormElementReCaptchaState = BaseFormElementState<string>;

export class FormElementReCaptchaModel extends BaseFormElementModel<
  string,
  FormElementCaptchaData,
  FormElementReCaptchaState
> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseFormElement(data: FormElementCaptchaData) {
    // No settings available we need to handle;
  }

  public getSiteKey() {
    const campaignStore = useCampaignStore();
    const campaignState = campaignStore.model?.state;
    const recaptchaIntegrationModel = campaignState?.config?.integrations.find((integration) => {
      return integration.state.namespace === 'recaptcha';
    }) as IntegrationRecaptchaModel | undefined;

    if (recaptchaIntegrationModel) {
      return recaptchaIntegrationModel.state.recaptchaSiteKey;
    }
  }

  getInitialValue(): undefined {
    return undefined;
  }

  parseStringValue(): undefined {
    return undefined;
  }

  getSerializedPostValue(): FormElementReCaptchaValue | string {
    return this.state.value ?? '';
  }

  getStringifiedValue(): string | undefined {
    return this.state.value && this.state.value !== '' ? this.state.value : undefined;
  }

  getSerializedCookieValue(): string {
    return typeof this.state.value === 'object' ? JSON.stringify(this.state.value) : '';
  }

  getValidationRules(): string[] {
    const rules = super.getValidationRules();

    if (!rules.includes('required')) {
      rules.push('required');
    }

    return rules;
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
