import { useEditingStore } from '@/src/store/editing';
import { useClipboardStore } from '@/src/store/clipboard';
import { useCampaignStore } from '@/src/store/campaign';
import { BaseModel } from '@/src/models/BaseModel';
import type { ContentRowData } from '@/src/typings/interfaces/data/grid/row';
import type { ContentAddonType } from '@/src/components/layout/column/ColumnModel';
import ColumnModel from '@/src/components/layout/column/ColumnModel';
import { RowSettingsModel } from '@/src/components/layout/row/RowSettingsModel';
import type { ContentGrid } from '@/src/components/layout/section/SectionBaseModel';
import { ContentType } from '@/src/components/layout/section/SectionBaseModel';
import type { ContentColumnData } from '@/src/typings/interfaces/data/grid/column';
import { CustomCSSModel } from '@/src/models/CustomCSSModel';
import { getDeviceData } from '@/src/hooks/useDevice';
import type { CustomCSS } from '@/src/typings/interfaces/data/settings/settings';
import { SectionType } from '@/src/typings/enums/enums';
import { createEditFormUrl, generateUniqueId } from '@/src/utilities/Utilities';
import type { SectionModelType } from '@/src/typings/types/types';
import { RepeatableModel } from '@/src/models/settings/RepeatableModel';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';
import { Cache } from '@/src/services/cache';

interface RowEditState {
  isCollapsed: boolean;
  isActive: boolean;
  isFocus: boolean;
}

interface RowConfigState {
  hasRegistrationAddon: boolean;
}

export enum RowRepeatableSortDirection {
  DESC = 'desc',
  ASC = 'asc'
}

export interface RowRepeatableState {
  enabled?: boolean;
  type?: string;
  columnGrid?: number;
  feedUrl?: string;
  limit?: number;

  sort?: {
    by?: string;
    direction?: RowRepeatableSortDirection;
  };
}

export interface RowModelState {
  modelId: string;
  id: string;
  classIdentifier: string;
  label: string;
  edit?: RowEditState;
  columns: ColumnModel[];
  settings: RowSettingsModel;
  config?: RowConfigState;
  repeatable?: RepeatableModel;
  customCss: CustomCSSModel | undefined;
}

export default class RowModel extends BaseModel<ContentRowData, RowModelState> {
  public section: SectionModelType;
  public campaignId: number;
  private deleted = false;

  constructor(data: ContentRowData, section: SectionModelType) {
    super(data);

    const reservedIds = Cache.get<string[]>('reserved-row-ids') ?? [];

    // Conflict found or no row id exists. Some other rows already uses this ID.
    // We need to refresh it to avoid conflicts with style or other functionality.
    if (!data.id || reservedIds.includes(data.id)) {
      data.id = generateUniqueId();
    }

    reservedIds.push(data.id);
    Cache.set('reserved-row-ids', reservedIds);

    this.section = section;
    this.campaignId = section.campaignModel.id;

    this.parse(data);
  }

  getValueRoot() {
    return 'settings';
  }

  getTotalColumns() {
    return this.getData().columns.length;
  }

  parse(data: ContentRowData) {
    const campaignStore = useCampaignStore();
    const state = this.state;
    state.modelId = this.modelId;
    state.id = data.id || generateUniqueId();
    state.classIdentifier = `grid__row--${state.id}`;
    state.label = data.settings?.basic?.name || 'Row';

    const editState = campaignStore.model?.state.edit;

    if (!state.edit && editState?.enabled) {
      state.edit = {
        isFocus: false,
        isActive: false,
        isCollapsed: true
      };
    }

    state.config = {
      hasRegistrationAddon: RowModel.checkForAddons(data, 'registration')
    };

    if (data.settings?.advanced?.repeater) {
      if (state.repeatable) {
        state.repeatable.setData(data.settings.advanced.repeater);
      } else {
        state.repeatable = new RepeatableModel(data.settings.advanced.repeater);
      }
    } else {
      state.repeatable = undefined;
    }

    const columnMap: { [key: string]: ColumnModel } = {};

    if (state.columns) {
      state.columns.forEach((column) => {
        columnMap[column.state.id] = column;
      });
    }

    state.columns = data.columns
      .filter((column) => typeof column !== 'string')
      .map((column) => {
        if (column.id && typeof columnMap[column.id] !== 'undefined') {
          const columnModel = columnMap[column.id];
          columnModel.setData(column);
          return columnModel;
        }

        return new ColumnModel(column, this);
      });

    if (state.settings) {
      state.settings.setData(data.settings ?? {});
    } else {
      state.settings = new RowSettingsModel(data.settings ?? {}, this.getSection());
    }

    if (data.settings?.advanced?.customcss) {
      state.customCss = RowModel.parseCustomCSSData(data, state.classIdentifier);
    }
  }

  public getSection() {
    return this.section;
  }

  public getEditUrl() {
    const editFormUrl = `/edit/campaign/row/${this.campaignId}/${this.section.state.id}?row_index=${this.index}`;
    return createEditFormUrl(editFormUrl);
  }

  reload() {
    this.section.reload();
  }

  setEditActiveState(activeState: boolean) {
    if (this.state.edit) {
      this.state.edit.isActive = activeState;
    }
  }

  canOpenGameSettings() {
    return this.getSection().getSectionType() === SectionType.FLOWPAGE;
  }

  canOpenSectionSettings() {
    return this.getSection().getSectionType() === SectionType.FLOWPAGE;
  }

  canInsertExtraColumn(): boolean {
    const rowState = this.state;
    if (rowState && rowState.settings) {
      const rowSettingsState = rowState.settings.state;

      if (
        rowSettingsState &&
        rowSettingsState.advanced &&
        rowSettingsState.advanced.repeater &&
        rowSettingsState.advanced.repeater.enable
      ) {
        return false;
      }
    }

    return rowState.columns.length < 4;
  }

  setNewColumnActive(column: { model: ColumnModel; id: string }) {
    const editingStore = useEditingStore();
    editingStore.setActiveModel(column.model);
  }

  async handleInsertExtraColumn() {
    const rowData = this.getData();
    const newColumn = {
      label: 'Column',
      size: 1,
      addons: [],
      id: generateUniqueId()
    };

    rowData.columns.push(newColumn);
    this.parse(rowData);
    this.redoParse();
    this.resizeColumns();
    await this.saveBlock();

    this.state.columns.forEach((column) => {
      if (column.state.id === newColumn.id && column.state.edit) {
        column.state.edit.isActive = true;
        this.setNewColumnActive({
          model: column,
          id: newColumn.id
        });
      }
    });
  }

  // get visible columns
  getVisibleColumns(): ColumnModel[] {
    return this.state.columns.filter((column) => {
      // find if visible
      const conditions = column.state.settings.state.advanced.visibilityCondition;

      if (conditions) {
        return conditions.check();
      }

      return true;
    });
  }

  // resize visible columns by length
  resizeColumns(onState?: boolean) {
    if (onState) {
      let size = 12;
      const columns = this.getVisibleColumns();

      switch (columns.length) {
        case 2:
          size = 6;
          break;

        case 3:
          size = 4;
          break;

        case 4:
          size = 3;
          break;

        case 6:
          size = 2;
          break;
      }
      columns.forEach((column) => {
        column.state.size = size;
      });
    } else {
      const data = this.getData();
      let size = 12;

      switch (data.columns.length) {
        case 2:
          size = 6;
          break;

        case 3:
          size = 4;
          break;

        case 4:
          size = 3;
          break;

        case 6:
          size = 2;
          break;
      }

      data.columns = data.columns
        .filter((column) => typeof column !== 'string')
        .map((column) => {
          column.size = size;
          return column;
        });
    }
  }

  reorderColumns() {
    const data = this.getData();
    data.columns = this.state.columns.map((column) => {
      return column.getData();
    });

    this.parse(data);
  }

  async saveBlock() {
    return await this.section.saveBlock();
  }

  canDelete(): boolean {
    const sectionState = this.section.state;
    return !(sectionState.config && sectionState.config.clone);
  }

  canCopy(): boolean {
    const state = this.state;

    if (state && state.settings) {
      const settingsState = state.settings.state;
      return !(settingsState.advanced && settingsState.advanced.repeater && settingsState.advanced.repeater.enable);
    }

    return true;
  }

  canDuplicate(): boolean {
    const state = this.state;
    const hasRegistrationAddon = this.getAddons<AddonRegistrationModel>('registration').length > 0;

    if (hasRegistrationAddon) {
      return false;
    }

    if (state && state.settings) {
      const settingsState = state.settings.state;

      if (settingsState.advanced?.repeater && settingsState.advanced?.repeater?.enable) {
        return false;
      } else {
        return true;
      }
    }

    return true;
  }

  canPaste(): boolean {
    const clipboardStore = useClipboardStore();

    if (clipboardStore.elementInClipboard instanceof RowModel) {
      const parentSection = this.getSection();

      if (!parentSection.state.config.hasRegistrationAddon) {
        return true;
      } else {
        const model = clipboardStore.elementInClipboard;

        if (model && model.state && model.state.config) {
          return !model.state.config.hasRegistrationAddon;
        }
      }
    }
    return clipboardStore.elementInClipboard instanceof RowModel;
  }

  canMoveUp(): boolean {
    return this.index > 0;
  }

  onMoveUp() {
    this.section.onMoveRowUp(this.index);
  }

  canMoveDown(): boolean {
    const section = this.section;
    const sectionState = section.state;
    const content = sectionState.content[0] as ContentGrid;
    return this.index < content.rows.length - 1;
  }

  onMoveDown() {
    this.section.onMoveRowDown(this.index);
  }

  onPasteElement() {
    const clipboardStore = useClipboardStore();
    const rowData = clipboardStore.dataInClipboard;

    if (rowData) {
      this.section.onPasteRow(rowData, this.index);
    }
  }

  async onPasteColumn(columnData: string, colIndex: number) {
    const editingStore = useEditingStore();
    const data = this.getData();
    const columns = data.columns;
    const clone = JSON.parse(columnData) as ContentColumnData;
    clone.id = generateUniqueId();

    // If rows contains registration, remove all fields, as we can only have 1 registration
    if (clone && clone.addons && clone.addons.length > 0) {
      clone.addons.forEach((addon) => {
        if (addon.alias === 'registration' && addon.settings) {
          addon.settings.fields = [];
        }
      });

      // Filter out gameflow or gameplay, as we can only have one
      clone.addons = clone.addons.filter((addon) => {
        return addon.alias !== 'gameflow' && addon.alias !== 'gameplay';
      });
    }

    if (clone && clone.addons.length > 0) {
      clone.addons.forEach((addon) => {
        addon.id = generateUniqueId();
      });
    }

    // Insert new addon on correct index
    columns.splice(colIndex + 1, 0, clone);

    this.parse(data);
    this.resizeColumns();
    await this.saveBlock();

    this.state.columns.forEach((column, index) => {
      if (index === colIndex + 1) {
        editingStore.setActiveModel(column);
      }
    });
  }

  onMoveColumnUp(currentColumnIndex: number) {
    const editingStore = useEditingStore();
    const data = this.getData();
    const columns = data.columns;
    const currentItem = columns[Number(currentColumnIndex)];
    columns[Number(currentColumnIndex)] = columns[currentColumnIndex - 1];
    columns[currentColumnIndex - 1] = currentItem;

    data.columns = columns;
    this.parse(data);

    editingStore.setActiveModel(this.state.columns[currentColumnIndex - 1]);
  }

  onMoveColumnDown(currentColumnIndex: number) {
    const editingStore = useEditingStore();
    const data = this.getData();
    const columns = data.columns;
    const currentItem = columns[Number(currentColumnIndex)];

    columns[Number(currentColumnIndex)] = columns[currentColumnIndex + 1];
    columns[currentColumnIndex + 1] = currentItem;

    data.columns = columns;
    this.parse(data);

    editingStore.setActiveModel(this.state.columns[currentColumnIndex + 1]);
  }

  async onDelete() {
    await Promise.all(this.state.columns.map((column) => column.onDelete()));
  }

  // Delete row
  async delete() {
    if (this.deleted) {
      return;
    }

    this.deleted = true;

    await this.section.deleteRow(this.index);
  }

  // Delete a column from array
  async deleteColumn(columnIndex: number) {
    await this.state.columns[Number(columnIndex)].onDelete();

    const data = this.getData();
    data.columns.splice(columnIndex, 1);
    this.resizeColumns();
    this.parse(data);

    await this.saveBlock();
  }

  getColumnByIndex(index: number) {
    return this.state.columns[Number(index)];
  }

  private static checkForAddons(data: ContentRowData, alias: string): boolean {
    let hasAddon = false;

    if (data.columns) {
      data.columns.forEach((col) => {
        if (typeof col !== 'string' && col.addons) {
          col.addons.forEach((addon) => {
            if (addon.alias === alias) {
              hasAddon = true;
            }
          });
        }
      });
    }

    return hasAddon;
  }

  private static parseCustomCSSData(data: ContentRowData, identifier: string): CustomCSSModel | undefined {
    if (data.settings?.advanced?.customcss) {
      const useData = getDeviceData(data.settings.advanced.customcss) as CustomCSS | undefined;

      if (!useData) {
        return undefined;
      }

      if (useData.code && useData.code.length > 0) {
        return RowModel.constructCustomCSSState(useData, identifier);
      }

      return undefined;
    }
  }

  private static constructCustomCSSState(data: CustomCSS, identifier: string): CustomCSSModel {
    return new CustomCSSModel(data, identifier);
  }

  public shallSkipInitialParse(): boolean {
    return true;
  }

  public setEditingActive(): void {
    const state = this.state;

    if (state.edit) {
      state.edit.isActive = true;
      state.edit.isCollapsed = false;
    }

    if (this.section.state.edit) {
      this.section.state.edit.isCollapsed = false;
    }
  }

  public getAddons<Model extends ContentAddonType>(alias: string): Model[] {
    const addonModels: Model[] = [];

    this.state.columns.forEach((column) => {
      column.state.addons.forEach((addon) => {
        if (addon.alias === alias) {
          addonModels.push(addon as Model);
        }
      });
    });

    return addonModels;
  }

  public get index() {
    if (
      this.section.state.content &&
      this.section.state.content[0] &&
      this.section.state.content[0].type === ContentType.GRID
    ) {
      const index = this.section.state.content[0].rows.indexOf(this);

      if (index !== undefined) {
        return index;
      }
    }

    throw new Error(`Could not find row index in section`);
  }
}
