import { reactive } from 'vue';
import { generateUniqueId } from '@/src/utilities/Utilities';

export abstract class BaseModel<Data, State> {
  /**
   * Original data stores for later use when wanting to modify the original value.
   */
  private _data: Data;

  public readonly modelId: string;
  public readonly state: State;

  /**
   * Initially we receive a unknown data dump that is then saved as the original
   * value & passed through the model parser for splitting the data into
   * how we want it to look like in the frontend facing components.
   */
  constructor(data: Data) {
    this._data = data;
    this.modelId = generateUniqueId();
    this.state = reactive({}) as State;

    if (!this.shallSkipInitialParse()) {
      this.parse(data);
    }
  }

  /**
   * This instructs vue reactivity when using devalue() to stringify the model using the json it represents.
   */
  public toJSON() {
    return {};
    // return this._data;
  }

  /**
   * Each model is responsible for parsing the incoming data & manually keep track
   * of a state that contains the newly parsed information.
   */
  public abstract parse(data: Data): void;

  /**
   * Set a value for a specific path in original data.
   * "path" is a dot separated list it have to traverse through
   * to set the data on the original data;
   *
   * This method will after setting the value in the original data
   * re-parse the content which will cause reactivity to update the view.
   */
  public setValue(path: string, value: string | number | boolean, shouldParse?: boolean): void {
    if (typeof shouldParse === 'undefined') {
      shouldParse = true;
    }

    const arrPath = this.getValuePath(path);

    if (arrPath) {
      let i: number;
      let obj = this._data;

      // Loop over all items in the path besides the last one and continuously dig through the tree
      for (i = 0; i < arrPath.length - 1; i++) {
        // @ts-ignore
        if (typeof obj[arrPath[Number(i)]] === 'undefined') {
          // Detect if the next item in the path is a number with value 0 or not, and if it is, then set as array instead of empty object.
          // This is to detect paths such as: settings.images.0.src
          if (typeof arrPath[i + 1] !== 'undefined' && Number(arrPath[i + 1]) === 0) {
            // @ts-ignore
            obj[arrPath[Number(i)]] = [];
          } else {
            // @ts-ignore
            obj[arrPath[Number(i)]] = {};
          }
          /**
           * This "hack" is because PHP treats arrays and objects in the same way, and if an object or array is empty in PHP, it returns []
           * But in our case, this should be an object {}, so we are handling this ourselves to accomodate the FE needs
           */
          // @ts-ignore
          // eslint-disable-next-line security/detect-object-injection
        } else if (Array.isArray(obj[arrPath[i]]) && Number(arrPath[i]) != arrPath[i] && obj[arrPath[i]].length === 0) {
          // @ts-ignore
          obj[arrPath[Number(i)]] = {};
        }

        // @ts-ignore
        obj = obj[arrPath[Number(i)]]; // nosem
      }

      // For last item in the path as just set the value directly on the object
      // @ts-ignore
      obj[arrPath[Number(i)]] = value;

      if (shouldParse) {
        // Re-run the parsing after changing values in the original data
        this.parse(this._data);
      }
    }
  }

  public setData(data: Data) {
    this._data = data;
    this.parse(this._data);
  }

  public getData<T extends Data = Data>() {
    return this._data as T;
  }

  public serialize(): string {
    return JSON.stringify(this.getData());
  }

  public redoParse(): void {
    this.parse(this._data);
  }

  public getValuePath(path: string): string[] {
    const getValueRoot = this.getValueRoot();
    if (getValueRoot && getValueRoot.length > 0 && path.indexOf(getValueRoot) !== 0) {
      return [...this.getValueRoot().split('.'), ...path.split('.')];
    }
    return [...path.split('.')];
  }

  public shallSkipInitialParse(): boolean {
    return false;
  }

  public getValueRoot(): string {
    return '';
  }
}
