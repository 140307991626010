import { GameModel } from '@/src/models/GameModel';
import { GameIndicatorPositionType } from '@/src/components/indicators/Model';
import { getDeviceData } from '@/src/hooks/useDevice';
import type {
  GameTapToRevealData,
  GameTapToRevealGeneralData,
  GameTapToRevealVisualsData
} from '@/src/components/games/tap-to-reveal/Data';
import type { GameIndicator, GameState, InstantWinData } from '@/src/models/GameModel';
import type { GameIndicatorPosition } from '@/src/components/indicators/Model';
import { DrawType } from '@/src/typings/enums/enums';

export enum GameTapToRevealEffects {
  SHAKE,
  TEAR_APART
}

interface GameTapToRevealState extends GameState {
  general: GameTapToRevealGeneralState;
  visuals: GameTapToRevealVisualsState;
  winnerImageId: string;
}

interface GameTapToRevealGeneralState {
  drawType: DrawType;
  effect: GameTapToRevealEffects;
  winnerPercentage: number;
}

export interface GameTapToRevealImage {
  id: string;
  image: string;
}

interface GameTapToRevealVisualsState {
  gameAsset: string;
  loserImage?: GameTapToRevealImage;
  winnerImages: GameTapToRevealImage[];
}

export interface GameTapToRevealInstantWin extends InstantWinData {
  winner_image_id?: string;
}

const GameTapToRevealEffectsMap: Record<string, GameTapToRevealEffects> = {
  shake: GameTapToRevealEffects.SHAKE,
  tear_apart: GameTapToRevealEffects.TEAR_APART
};

export class GameTapToRevealModel extends GameModel<GameTapToRevealData, GameTapToRevealState> {
  parseGame(data: GameTapToRevealData) {
    const state = this.state;

    state.general = GameTapToRevealModel.constructGeneralState(data.general);
    state.visuals = GameTapToRevealModel.constructVisualsState(data.images);
  }

  public setInstantWinnerData(data: InstantWinData) {
    super.setInstantWinnerData(data);

    const winnerImageId = data.winner_image_id;

    if (winnerImageId && typeof winnerImageId === 'string') {
      this.state.winnerImageId = winnerImageId;
    }
  }

  public hasInstantWin(): boolean {
    return this.state.general.drawType === DrawType.AUTO;
  }

  public getIndicatorPosition(): GameIndicatorPosition {
    return {
      top: GameIndicatorPositionType.DEFAULT,
      bottom: GameIndicatorPositionType.DEFAULT
    };
  }

  public getIndicators(): GameIndicator[] {
    return [];
  }

  private static constructGeneralState(data: GameTapToRevealGeneralData): GameTapToRevealGeneralState {
    return {
      drawType: data.draw_type,
      effect: GameTapToRevealEffectsMap[data.effect] ?? null,
      winnerPercentage: Number(data.winner_percentage)
    };
  }

  private static constructVisualsState(data: GameTapToRevealVisualsData): GameTapToRevealVisualsState {
    const deviceData = getDeviceData(data.game_assets);

    return {
      gameAsset: deviceData?.asset ?? '',
      loserImage: deviceData?.loser,
      winnerImages: data.winner
    };
  }

  public isGameValid(): boolean {
    return true;
  }
}
