import { SdkTypeCheck } from '../utilities';
import type { RepeatableData } from '@/src/store/repeatable';
import { registerRepeatable, updateRepeatable } from '@/src/services/repeatable';

export class SdkRepeatableApi {
  public static register(identifier: string, fn: () => Promise<RepeatableData[]>): void {
    SdkTypeCheck('string', identifier);
    SdkTypeCheck('function', fn);

    if (!identifier) {
      throw new TypeError(`[SDK] Identifier must not be empty`);
    }

    registerRepeatable(`custom_sdk_${identifier}`, fn);
  }

  public static update(identifier: string): void {
    SdkTypeCheck('string', identifier);

    if (!identifier) {
      throw new TypeError(`[SDK] Identifier must not be empty`);
    }

    updateRepeatable(`custom_sdk_${identifier}`);
  }
}
