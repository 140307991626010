import type { GameQuizQuestionState } from '../Model';
import { QuizAnswerTypes } from '../Model';
import { SdkQuizQuestionAnswerModel } from './question-answer-model';

export class SdkQuizQuestionModel {
  public readonly id: number;
  public readonly questionHtml: string;
  public readonly answers: SdkQuizQuestionAnswerModel[];
  public readonly answerType: string | undefined = undefined;
  public readonly correctAnswer: SdkQuizQuestionAnswerModel[];

  constructor(
    state: GameQuizQuestionState,
    public readonly isLastQuestion: boolean
  ) {
    this.id = state.id;
    this.questionHtml = state.question;
    this.answers = state.answers.map((answer) => new SdkQuizQuestionAnswerModel(state, answer));

    switch (state.answerType) {
      case QuizAnswerTypes.TEXT:
        this.answerType = 'text';

      case QuizAnswerTypes.IMAGE:
        this.answerType = 'image';

      case QuizAnswerTypes.SLIDER:
        this.answerType = 'slider';

      case QuizAnswerTypes.SWIPE:
        this.answerType = 'swipe';
    }

    this.correctAnswer = this.answers.filter((answer) => state.correctAnswers.includes(answer.id));
  }
}
