import { IntegrationCookieModel } from '@/src/components/integrations/cookie/Model';
import { IntegrationCodanModel } from '@/src/components/integrations/custom/codan/Model';
import { IntegrationEspBrazeModel } from '@/src/components/integrations/esp/braze/Model';
import { IntegrationGrundfosModel } from '@/src/components/integrations/custom/grundfos/Model';
import { IntegrationClerkModel } from '@/src/components/integrations/clerk/Model';
import { IntegrationBulkPrizeVouchersModel } from '@/src/components/integrations/bulk-prize-vouchers/Model';
import { IntegrationDanskeSpilModel } from '@/src/components/integrations/custom/danskespil/Model';
import { IntegrationSportsAllianceModel } from '@/src/components/integrations/custom/sports-alliance/Model';
import { IntegrationEspClickdimensionModel } from '@/src/components/integrations/esp/clickdimension/Model';
import { IntegrationBezzerwisserModel } from '@/src/components/integrations/custom/bezzerwisser/Model';
import { IntegrationEspFacebookConversionModel } from '@/src/components/integrations/esp/facebook-conversion/Model';
import { IntegrationCoopModel } from '@/src/components/integrations/custom/coop/Model';
import { IntegrationDagrofaModel } from '@/src/components/integrations/custom/dagrofa/Model';
import { IntegrationEspEloquaModel } from '@/src/components/integrations/esp/eloqua/Model';
import { IntegrationQiagenModel } from '@/src/components/integrations/custom/qiagen/Model';
import { IntegrationRecaptchaModel } from '@/src/components/integrations/recaptcha/Model';
import { IntegrationSaxoModel } from '@/src/components/integrations/custom/saxo/Model';
import { IntegrationPhilipMorrisModel } from '@/src/components/integrations/custom/philip-morris/Model';
import { IntegrationJwtModel } from '@/src/components/integrations/jwt/Model';
import { IntegrationOpenIdConnectModel } from '@/src/components/integrations/open-id-connect/Model';
import { IntegrationLobycoModel } from '@/src/components/integrations/custom/lobyco/Model';
import { IntegrationPixelTrackingModel } from '@/src/components/integrations/pixel-tracking/Model';
import { IntegrationParticleGeneratorModel } from '@/src/components/integrations/particle-generator/Model';
import { IntegrationCookieConsentModel } from '@/src/components/integrations/cookie-consent/Model';
import { IntegrationCookieConsentV2Model } from '@/src/components/integrations/cookie-consent-v2/Model';

export type IntegrationModel =
  | IntegrationEspEloquaModel
  | IntegrationQiagenModel
  | IntegrationDagrofaModel
  | IntegrationCookieModel
  | IntegrationRecaptchaModel
  | IntegrationCodanModel
  | IntegrationEspBrazeModel
  | IntegrationSaxoModel
  | IntegrationPhilipMorrisModel
  | IntegrationDanskeSpilModel
  | IntegrationJwtModel
  | IntegrationOpenIdConnectModel
  | IntegrationLobycoModel
  | IntegrationCoopModel
  | IntegrationGrundfosModel
  | IntegrationClerkModel
  | IntegrationBulkPrizeVouchersModel
  | IntegrationPixelTrackingModel
  | IntegrationSportsAllianceModel
  | IntegrationEspClickdimensionModel
  | IntegrationParticleGeneratorModel
  | IntegrationEspFacebookConversionModel
  | IntegrationBezzerwisserModel
  | IntegrationCookieConsentModel
  | IntegrationCookieConsentV2Model;

export type IntegrationModelType =
  | typeof IntegrationEspEloquaModel
  | typeof IntegrationQiagenModel
  | typeof IntegrationCookieModel
  | typeof IntegrationDagrofaModel
  | typeof IntegrationRecaptchaModel
  | typeof IntegrationCodanModel
  | typeof IntegrationEspBrazeModel
  | typeof IntegrationSaxoModel
  | typeof IntegrationPhilipMorrisModel
  | typeof IntegrationDanskeSpilModel
  | typeof IntegrationJwtModel
  | typeof IntegrationOpenIdConnectModel
  | typeof IntegrationLobycoModel
  | typeof IntegrationCoopModel
  | typeof IntegrationGrundfosModel
  | typeof IntegrationClerkModel
  | typeof IntegrationBulkPrizeVouchersModel
  | typeof IntegrationPixelTrackingModel
  | typeof IntegrationSportsAllianceModel
  | typeof IntegrationEspClickdimensionModel
  | typeof IntegrationParticleGeneratorModel
  | typeof IntegrationEspFacebookConversionModel
  | typeof IntegrationBezzerwisserModel
  | typeof IntegrationCookieConsentModel
  | typeof IntegrationCookieConsentV2Model;

export const IntegrationModelMapping: Record<string, IntegrationModelType> = {
  'esp/eloqua': IntegrationEspEloquaModel,
  qiagen: IntegrationQiagenModel,
  customer_leadfamly_dagrofa: IntegrationDagrofaModel,
  cookie: IntegrationCookieModel,
  recaptcha: IntegrationRecaptchaModel,
  customer_leadfamly_codan: IntegrationCodanModel,
  'esp/braze': IntegrationEspBrazeModel,
  customer_leadfamly_saxo: IntegrationSaxoModel,
  customer_leadfamly_philip_morris: IntegrationPhilipMorrisModel,
  customer_leadfamly_danskespil: IntegrationDanskeSpilModel,
  jwt: IntegrationJwtModel,
  openid_connect: IntegrationOpenIdConnectModel,
  ajax: IntegrationOpenIdConnectModel,
  customer_lobyco: IntegrationLobycoModel,
  customer_coop_app: IntegrationCoopModel,
  customer_leadfamly_grundfos: IntegrationGrundfosModel,
  clerk: IntegrationClerkModel,
  bulk_prize_vouchers: IntegrationBulkPrizeVouchersModel,
  pixel_tracking: IntegrationPixelTrackingModel,
  sports_alliance: IntegrationSportsAllianceModel,
  'esp/clickdimension': IntegrationEspClickdimensionModel,
  particle_generator: IntegrationParticleGeneratorModel,
  'esp/facebook_conversion': IntegrationEspFacebookConversionModel,
  customer_leadfamly_politikken_bezzerwisser: IntegrationBezzerwisserModel,
  cookie_consent: IntegrationCookieConsentModel,
  cookie_consent_v2: IntegrationCookieConsentV2Model
};
