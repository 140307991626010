import { getHostName } from '../services/url';
import { applyReplacementTags } from './Utilities';
import { useUtilityStore } from '@/src/store/utility';
import type { ReplacementTags } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import { useAppConfig } from '@/src/config';

export interface QueryParameters {
  [key: string]: string;
}

/**
 * Validity can be checked here: https://jsfiddle.net/f20htdjq/2/
 */
export const isValidHttpUrl = (string: string) => {
  const pattern = new RegExp(
    '^((https?:)?\\/\\/)?' + // protocol
      '(?:\\S+(?::\\S*)?@)?' + // authentication
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locater
  return pattern.test(string);
};

export function parseQueryString(query: string): QueryParameters {
  try {
    const querySplit = query.split(/[?#]/);

    if (!querySplit[1]) {
      return {};
    }

    const vars = querySplit[1].split('&');
    const parameters: QueryParameters = {};

    for (let i = 0; i < vars.length; i++) {
      try {
        // eslint-disable-next-line security/detect-object-injection
        const pair = vars[i].split('=');
        parameters[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Error in parseQueryString when trying to loop params: ${e}`);
      }
    }

    const url = new URL(query);

    for (const value of url.searchParams.keys()) {
      const param = url.searchParams.get(value);

      if (param !== null) {
        parameters[`${value}`] = param;
      }
    }

    return parameters;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return {};
  }
}

export const formatUrl = (url?: string, extraReplacementTags?: ReplacementTags | undefined): undefined | string => {
  if (!url) {
    return url;
  }
  if (
    (!(url.includes(':') && !url.includes('http')) && !(url.indexOf('coop-game-') === 0)) ||
    url.indexOf('lotto://') === 0
  ) {
    if (
      url &&
      !url.includes('http://') &&
      !url.includes('https://') &&
      !url.includes(':') &&
      url[0] !== '/' &&
      url[0] !== '#'
    ) {
      // Can't locate either http or https protocol.
      url = 'http://' + url;
    }

    if (url.startsWith('/files/')) {
      // If URL contains '/files/' it is a relative URL and should be prefixed with the current host.
      url = `https://${window.location.host}` + url;
    }

    url = insertUTMInUrl(url);
    url = addTrackingUrl(url);
  }

  return applyReplacementTags(url, extraReplacementTags);
};

export function getCurrentUrl(): string {
  const utilityStore = useUtilityStore();
  return typeof window !== 'undefined' ? window.location.href : utilityStore.url;
}

export function getQueryParams(): QueryParameters {
  const campaignStore = useCampaignStore();

  return {
    ...parseQueryString(getCurrentUrl()),
    ...campaignStore.staticQueryParams
  };
}

export function getSpecificQueryParam(param: string) {
  const queryParams = getQueryParams();
  return queryParams[`${param}`];
}

const insertUTMInUrl = (url: string): string => {
  const campaignStore = useCampaignStore();
  if (
    campaignStore.model?.state.config?.utmEnabled &&
    url.includes('http') &&
    !url.includes('utm_source=') &&
    !url.includes('utm_medium=') &&
    !url.includes('utm_campaign=') &&
    typeof campaignStore.model?.state.config?.utmSource !== 'undefined' &&
    typeof campaignStore.model?.state.config?.utmCampaign !== 'undefined' &&
    typeof campaignStore.model?.state.config?.utmMedium !== 'undefined'
  ) {
    const params = getQueryParams();
    const queryArgs: string[] = [];

    const utmSource = params.utm_source || campaignStore.model?.state.config.utmSource;
    const utmMedium = params.utm_medium || campaignStore.model?.state.config.utmMedium;
    const utmCampaign = params.utm_campaign || campaignStore.model?.state.config.utmCampaign;
    const utmContent = params.utm_content || campaignStore.model?.state.config.utmContent;

    queryArgs.push('utm_source=' + encodeURIComponent(utmSource));
    queryArgs.push('utm_medium=' + encodeURIComponent(utmMedium));
    queryArgs.push('utm_campaign=' + encodeURIComponent(utmCampaign));

    if (utmContent && !url.includes('utm_content=')) {
      queryArgs.push('utm_content=' + encodeURIComponent(utmContent));
    }

    if (url.includes('?')) {
      url += '&';
    } else {
      url += '?';
    }

    url += queryArgs.join('&');
  }

  return url;
};

export const replaceHrefsWithUrlTrackingAndReplacementTags = (
  htmlString: string,
  extraReplacementTags?: ReplacementTags
) => {
  return applyReplacementTags(htmlString, extraReplacementTags).replace(/<a.*href="(.+?)"/gm, (match, contents) => {
    return match.replace(contents, formatUrl(contents) ?? '');
  });
};

/**
 * Get URL's tracking link.
 */
const addTrackingUrl = (url: string) => {
  const campaignStore = useCampaignStore();
  const AppConfig = useAppConfig();

  const isDemo = useUtilityStore().url.includes('/campaign/view/demo');

  if (url.includes('/track?url=')) {
    return url;
  }

  if (url && (url.indexOf('#') === 0 || url.match(/#[a-z0-9_-]+#/gim))) {
    return url;
  }

  if (url && campaignStore.model?.state.config?.deactivateInternalLinkTracking) {
    return url;
  }

  // Early return in case of empty string
  if (!url) {
    return url;
  }

  // Resolve entities from the URL
  url = url
    .replace(/&aelig;/gi, 'æ')
    .replace(/&oslash;/gi, 'ø')
    .replace(/&aring;/gi, 'å')
    .replace(/&AElig;/gi, 'Æ')
    .replace(/&Oslash;/gi, 'Ø')
    .replace(/&Aring;/gi, 'Å')
    .replace(/&amp;/gi, '&');

  // Tokenize the URL for safe æ, ø & å characters.
  // AWS Api Gateway REST version doesn't work well with UTF8 in query string.
  url = url
    .replace('å', '%C3%A5')
    .replace('Å', '%C3%85')
    .replace('æ', '%C3%A6')
    .replace('Æ', '%C3%86')
    .replace('ø', '%C3%B8')
    .replace('Ø', '%C3%98');

  let hostname = campaignStore.model?.state.config?.apiEndpoint ?? AppConfig.apiEndpoint;

  if (isDemo) {
    hostname = window.location.origin;
  }

  const trackingBaseUrl = hostname + '/track?url=';

  const sanitizedUrl = encodeURIComponent(url);

  return (
    trackingBaseUrl +
    sanitizedUrl +
    '&campaign_id=' +
    campaignStore.model?.id +
    '&customer_id=' +
    campaignStore.model?.state.config?.customerId
  );
};

export const isHostIPAddress = (host: string) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    host
  );
};

const extractDomainFromUrl = (url?: string): string => {
  const finalUrl = url || window.location.href;
  const normalizedUrl = finalUrl.endsWith('/') ? finalUrl.slice(0, -1) : finalUrl;
  return new URL(normalizedUrl).hostname;
};

export const isLocalhostDomain = (url?: string): boolean => {
  const domain = extractDomainFromUrl(url);
  return domain.endsWith('localhost');
};

export const isDevPlayableDomain = (url?: string): boolean => {
  const domain = extractDomainFromUrl(url);
  return domain.endsWith('dev.playable.com');
};

export const getAdminUiEndpoint = (): string => {
  const AppConfig = useAppConfig();
  const host = getHostName();

  if (
    AppConfig.adminUiEndpointOverwrites &&
    Array.isArray(AppConfig.adminUiEndpointOverwrites) &&
    AppConfig.adminUiEndpointOverwrites.length > 0
  ) {
    const overwriteItem = AppConfig.adminUiEndpointOverwrites.find((overwrite) => {
      return overwrite.lookup === host;
    });

    if (overwriteItem) {
      // eslint-disable-next-line no-console
      console.info('Made use of custom administration URL:', overwriteItem.overwrite);
      return overwriteItem.overwrite;
    }
  }

  return AppConfig.adminUiEndpoint;
};
