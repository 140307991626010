<template>
  <transition name="control-code">
    <div
      v-show="isReady"
      ref="containerRef"
      class="control-code"
      :class="controlCodeTypeClass"
      :style="controlCodeStyles"
    >
      <h6 class="control-code__label">{{ settings?.label }}</h6>

      <button
        v-if="settings?.type === ControlCodeTypes.OVERLAY"
        type="button"
        class="control-code__close close"
        aria-label="Close"
        @click="onClose"
      >
        <span class="control-code__close-icon" aria-hidden="true">&times;</span>
      </button>
    </div>
  </transition>
</template>

<script lang="ts">
import type { PropType, CSSProperties } from 'vue';
import { computed, defineComponent, ref, watch, nextTick } from 'vue';
import { ControlCodeTypes } from '@/src/components/integrations/bulk-prize-vouchers/Model';
import type { ColorRGBA } from '@/src/utilities/ColorHelper';
import { hexToRGBAObject } from '@/src/utilities/ColorHelper';
import { useUtilityStore } from '@/src/store/utility';
import { useCampaignStore } from '@/src/store/campaign';

export interface ControlCodeSettings {
  enabled?: boolean;
  label?: string;
  type?: ControlCodeTypes;
  backgroundColor?: string;
  textColor?: string;
  active?: boolean;
  closed?: boolean;
}

export default defineComponent({
  name: 'ControlCode',
  props: {
    settings: {
      type: Object as PropType<ControlCodeSettings>,
      required: true
    }
  },
  emits: ['onClose'],
  setup(props, context) {
    const utilityStore = useUtilityStore();
    const campaignStore = useCampaignStore();
    const containerRef = ref<HTMLElement | null>(null);

    const onClose = () => {
      context.emit('onClose');
    };

    const controlCodeStyles = computed<CSSProperties>(() => {
      let backgroundColor = props.settings.backgroundColor;
      let rgba: ColorRGBA | undefined;

      if (props.settings.type === ControlCodeTypes.OVERLAY && props.settings.backgroundColor) {
        if (backgroundColor?.includes('rgba(')) {
          backgroundColor = props.settings.backgroundColor;
        } else {
          rgba = hexToRGBAObject(props.settings.backgroundColor, 0.9);
          backgroundColor = `rgba(${rgba?.r}, ${rgba?.g}, ${rgba?.b}, ${rgba?.a})`;
        }
      }

      return {
        // default colors is set by campaign styling, therefor no fallback.
        ...(backgroundColor && { backgroundColor }),
        ...(props.settings?.textColor && { color: props.settings.textColor })
      };
    });

    const controlCodeType = computed(() => {
      let type: string | undefined;
      switch (props.settings.type) {
        case ControlCodeTypes.TOP:
          type = 'top';
          break;

        case ControlCodeTypes.BOTTOM:
          type = 'bottom';
          break;

        case ControlCodeTypes.OVERLAY:
          type = 'overlay';

          break;

        case ControlCodeTypes.REPLACEMENT_TAG:
          type = 'replacementtag';
          break;

        default:
          type = undefined;
      }
      return type;
    });

    const controlCodeTypeClass = computed(() => {
      return controlCodeType.value ? `control-code--${controlCodeType.value}` : undefined;
    });

    const isReady = computed(() => {
      return props.settings.active && props.settings?.label;
    });

    const setPadding = () => {
      if (containerRef.value && props.settings.type === ControlCodeTypes.TOP) {
        utilityStore.campaignPadding = containerRef.value.clientHeight;
      }
    };

    // reactive add/remove class on type
    watch(
      () => props.settings.type,
      () => {
        if (props.settings.type === ControlCodeTypes.TOP) {
          utilityStore.addBodyClasses(['site--control-code-top']);
        } else {
          utilityStore.removeBodyClasses(['site--control-code-top']);
        }
      },
      {
        immediate: true
      }
    );

    watch(
      () => isReady.value,
      async () => {
        await nextTick();
        if (props.settings.type === ControlCodeTypes.TOP) {
          // waiting for next tick before setting padding, else it will set it 0 as the is not rendered.
          setPadding();
        }
      }
    );

    watch(
      () => props.settings?.label,
      () => {
        if (props.settings.type === ControlCodeTypes.REPLACEMENT_TAG) {
          let replacementTag: string | undefined;
          // regular prizes

          if (campaignStore.replacementTags.bulk_prize_instantwin) {
            replacementTag = 'bulk_prize_control_code_message';
          } else {
            replacementTag = 'control_code_message';
          }

          if (replacementTag && props.settings.enabled && props.settings.label) {
            campaignStore.addReplacementTags({ [replacementTag]: props.settings.label });
          }
        }
      }
    );

    return {
      controlCodeType,
      ControlCodeTypes,
      controlCodeTypeClass,
      onClose,
      controlCodeStyles,
      containerRef,
      isReady
    };
  }
});
</script>

<style lang="scss">
.control-code {
  position: fixed;
  left: 0;
  right: 0;
  padding: 1.2rem 1.5rem 1.4rem 1.5rem;
  background: rgba(56, 56, 56, 0.9);
  color: #fff;
  z-index: 100;

  &.control-code-enter-active {
    transition:
      transform 400ms $transition-easing-transform,
      opacity 200ms linear;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    opacity: 0;
  }
  &.control-code-enter-to {
    opacity: 1;
  }

  &.control-code-leave-active {
    transition:
      transform 400ms $transition-easing-transform,
      opacity 300ms linear;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    opacity: 1;
  }
  &.control-code-enter-from,
  &.control-code-leave-to {
    opacity: 0;
  }

  &--top {
    top: 0;
    text-align: center;

    &.control-code-enter-active {
      opacity: 1;
      transform: translateY(-100%) translateZ(0);
    }
    &.control-code-enter-to {
      transform: translateY(0%) translateZ(0);
    }
    &.control-code-enter-from,
    &.control-code-leave-to {
      transform: translateY(0) translateZ(0);
    }

    &.control-code-leave-active {
      transform: translateY(0) translateZ(0);
    }
    &.control-code-enter-from,
    &.control-code-leave-to {
      opacity: 1;
      transform: translateY(-100%) translateZ(0);
    }
  }

  &--bottom {
    bottom: 0;
    text-align: right;

    &.control-code-enter-active {
      transform: translateY(100%) translateZ(0);
    }
    &.control-code-enter-to {
      transform: translateY(0%) translateZ(0);
    }
    &.control-code-enter-from,
    &.control-code-leave-to {
      transform: translateY(0) translateZ(0);
    }

    &.control-code-leave-active {
      transform: translateY(0) translateZ(0);
    }
    &.control-code-enter-from,
    &.control-code-leave-to {
      transform: translateY(100%) translateZ(0);
    }
  }

  &--overlay {
    top: 0;
    bottom: 0;
    text-align: center;

    &.control-code-enter-from,
    &.control-code-leave-to {
      transition: opacity 300ms linear;
    }

    .control-code__label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-width: 60rem;
    }
  }

  &__label {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 1.4rem;
    font-weight: normal;
  }

  &__close {
    position: fixed !important;
    top: size(30px);
    right: size(30px);
    width: size(60px);
    height: size(60px);
    line-height: size(56px);
    backface-visibility: hidden;
    border-radius: 50%;
    transform: translate3d(0, 0, 0);
    opacity: 1;

    &-icon {
      font-size: size(25px);
    }

    &:hover,
    &:focus {
      opacity: 1 !important;
    }

    .site--mobile & {
      top: size(20px);
      right: size(20px);
      width: size(50px);
      height: size(50px);
      line-height: size(46px);
    }
  }
}

.site--control-code-top .campaign {
  transition:
    padding-top 400ms $transition-easing-transform,
    width 400ms $transition-easing-transform,
    transform 400ms $transition-easing-transform,
    filter 300ms linear;
}
</style>
