export enum InputStyleType {
  EMPTY = '',
  COLUMNS = 'columns',
  COLUMNS_ROUNDED = 'columns_rounded',
  CLASSIC = 'classic',
  CLASSIC_ROUNDED = 'classic_rounded',
  LABELS_ABOVE = 'labels_above'
}

export enum ValidationMessageType {
  INLINE = 'inline',
  HOVEr = 'hover'
}
