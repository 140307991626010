import type { App, Directive, DirectiveBinding } from 'vue';

const clickEventType = typeof document !== 'undefined' && document.ontouchstart !== null ? 'click' : 'touchstart';

const UNIQUE_ID = '__vue_click_away__';

const onMounted = (el: HTMLElement, binding: DirectiveBinding<() => void>) => {
  onUnmounted(el);

  const callback = binding.value;
  let nextTick = false;

  setTimeout(() => {
    nextTick = true;
  }, 0);

  // @ts-ignore
  el[`${UNIQUE_ID}`] = (event: MouseEvent | TouchEvent) => {
    if (
      event.target &&
      event.target instanceof Node &&
      (!el || !el.contains(event.target)) &&
      callback &&
      nextTick &&
      typeof callback === 'function'
    ) {
      return callback();
    }
  };

  if (typeof document !== 'undefined') {
    // @ts-ignore
    document.addEventListener(clickEventType, el[`${UNIQUE_ID}`], false);
  }
};

const onUnmounted = (el: HTMLElement) => {
  if (typeof document !== 'undefined') {
    // @ts-ignore
    document.removeEventListener(clickEventType, el[`${UNIQUE_ID}`], false);
  }

  // @ts-ignore
  delete el[`${UNIQUE_ID}`];
};

const onUpdated = (el: HTMLElement, binding: DirectiveBinding<() => void>) => {
  if (binding.value === binding.oldValue) {
    return;
  }

  onMounted(el, binding);
};

const directive: Directive = {
  mounted: onMounted,
  updated: onUpdated,
  unmounted: onUnmounted
};

export default (app: App<Element>) => {
  app.directive('click-away', directive);
};
