import { defineRule } from 'vee-validate';
import type { SdkEvents } from './interface';
import { SdkEventsEmitter } from './interface';
import { SdkPopoverApi } from './api/popover';
import { SdkReplacementTagsApi } from './api/replacementTags';
import { SdkGameApi } from './api/game';
import { SdkAuthenticationApi } from './api/authentication';
import { SdkUtilsApi } from './api/utils';
import { SdkCampaignApi } from '@/src/sdk/api/campaign';
import { SdkFlowpageApi } from '@/src/sdk/api/flowPages';
import { SdkCustomSolutionsApi } from '@/src/sdk/api/customSolutions';
import { SdkRepeatableApi } from '@/src/sdk/api/repeatable';

/**
 * SDK interface class. This is a singleton class.
 */
export class Sdk {
  public version = '0.1.2';

  // Add event listener.
  public on<E extends keyof SdkEvents>(event: E, listener: SdkEvents[E]): this {
    SdkEventsEmitter.on(event, listener);
    return this;
  }

  // Add event listener & auto-remove after first emit.
  public once<E extends keyof SdkEvents>(event: E, listener: SdkEvents[E]): this {
    SdkEventsEmitter.once(event, listener);
    return this;
  }

  // De-register an event listener.
  public off<E extends keyof SdkEvents>(event: E, listener: SdkEvents[E]): this {
    SdkEventsEmitter.off(event, listener);
    return this;
  }

  public defineValidationRule(name: string, fn: (value: string) => boolean) {
    defineRule(name, fn);
  }

  public flowPages = SdkFlowpageApi;
  public campaign = SdkCampaignApi;
  public popover = SdkPopoverApi;
  public replacementTags = SdkReplacementTagsApi;
  public game = SdkGameApi;
  public authentication = SdkAuthenticationApi;
  public utils = SdkUtilsApi;
  public customSolutions = SdkCustomSolutionsApi;
  public repeatable = SdkRepeatableApi;
}

// Extend the global Window object to include our own sdk.
declare global {
  interface Window {
    sdk?: Sdk;
  }
}

const initializeSDK = () => {
  // Make SDK available in window object.
  if (typeof window !== 'undefined') {
    window.sdk = new Sdk();

    window.dispatchEvent(new Event('lf-sdk-ready', { bubbles: true, cancelable: false, composed: true }));
  }
};

export default initializeSDK;
