import * as Sentry from '@sentry/vue';
import { v4 as uuidv4 } from 'uuid';
import useAxios from './useAxios';
import { useUtilityStore } from '@/src/store/utility';
import { useCampaignStore } from '@/src/store/campaign';
import { getDevice } from '@/src/hooks/useDevice';
import { getCookie, setCookie } from '@/src/utilities/CookieHelpers';
import { parseQueryString } from '@/src/utilities/Url';
import { CookieCategory, waitForCookieAccess } from '@/src/services/cookieConsent';
import { useAppConfig } from '@/src/config';

let sessionId: string | null = null;
let sessionLastSeen: number | null = null;
let processedSessionInvalidation = false;
const tracked: number[] = [];

const cleanseValueForRequest = (value?: string) => {
  return value ? value.replace(/[^a-zA-Z0-9-_+% ]/g, '') : undefined;
};

const cleanseUrlForRequest = (url?: string) => {
  if (!url) {
    return undefined;
  }

  const urlObject = new URL(url);
  const queryParameters = parseQueryString(url);

  // Reconstruct the query string with the cleansed values.
  const cleansedQueryString = Object.keys(queryParameters)
    .map((key) => {
      let queryStringValue = cleanseValueForRequest(queryParameters[`${key}`]) ?? '';

      // Trim off any query parameter that exceeds 500 characters.
      // These are typically encrypted strings that we would like
      // exclude anyway.
      if (queryStringValue.length > 500) {
        queryStringValue = '';
      }

      return `${key}=${encodeURIComponent(queryStringValue)}`;
    })
    .join('&');

  // [AP-3323] We have some instances of ' in our error log. trim these off the pathname.
  const pathname = urlObject.pathname.replaceAll("'", '');

  // Reconstruct the URL with the cleansed query string.
  return `${urlObject.origin}${pathname}${cleansedQueryString ? '?' + cleansedQueryString : ''}`;
};

export default function useAnalytics() {
  const campaignStore = useCampaignStore();
  const utilityStore = useUtilityStore();
  const appConfig = useAppConfig();

  const cookiePrefix = `${campaignStore.model?.id}-sid`;
  const sessionTimeout = 60 * 5;

  sessionLastSeen = sessionLastSeen ?? getCookie(`${cookiePrefix}-seen`) ?? null;
  sessionId = sessionId ?? getCookie(cookiePrefix) ?? uuidv4();

  const handleSaveSession = async () => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 5 * 60 * 1000);
    const cookieOptions = { expire: expires };
    sessionLastSeen = new Date().getTime();
    await waitForCookieAccess(CookieCategory.STATISTICS);
    setCookie(cookiePrefix, sessionId ?? '', cookieOptions, true, false);
    setCookie(cookiePrefix + '-seen', sessionLastSeen ? sessionLastSeen.toString() : '', cookieOptions, true, false);
  };
  const isDemo = () => {
    return utilityStore.url.includes('/campaign/view/demo');
  };

  const handlePageView = (pageId?: number) => {
    if (
      (pageId && tracked.includes(pageId)) ||
      campaignStore.model?.state?.config?.expired ||
      !campaignStore.model?.state.config?.enableStatistics
    ) {
      return;
    }

    // Only process the session invalidation once on the current page.
    // The only thing triggering an invalidation check is a full-page reload.
    if (!processedSessionInvalidation) {
      processedSessionInvalidation = true;

      if (!(sessionLastSeen && Math.floor((new Date().getTime() - sessionLastSeen) / 1000) < sessionTimeout)) {
        sessionId = uuidv4();
      }
    }

    if (pageId) {
      tracked.push(pageId);
    }

    if (!isDemo() && campaignStore.model?.state.config?.customerId && campaignStore.model.id) {
      const registrationId = campaignStore.flowRegistrationInfo?.id;
      const docReferrer =
        !['popup', 'popupv2'].includes(campaignStore.model.state?.config.gameAlias) && document.referrer
          ? document.referrer.split('?')[0]
          : '';

      const queryParams = parseQueryString(utilityStore.url);

      const utmSource = queryParams.utm_source;
      const utmMedium = queryParams.utm_medium;
      const utmCampaign = queryParams.utm_campaign;

      // Post to the new analytics platform
      if (sessionId && 'analyticsEndpoint' in appConfig && typeof appConfig.analyticsEndpoint === 'string') {
        const newPlatformData: Record<string, string | number | undefined> = {
          campaign_id: campaignStore.model.id,
          customer_id: campaignStore.model.state.config.customerId,
          session_id: sessionId,
          page_id: pageId,
          registration_id: registrationId,
          url: cleanseUrlForRequest(utilityStore.url),

          // Only allow alphanumeric characters (a-z, A-Z, 0-9), along with the characters -, _, and +
          // This is to avoid ingestion errors due to inproper escapes strings with our Redshift loader
          utm_source: cleanseValueForRequest(utmSource),
          utm_medium: cleanseValueForRequest(utmMedium),
          utm_campaign: cleanseValueForRequest(utmCampaign),
          screen_width: window.screen.width,
          screen_height: window.screen.height,
          device: getDevice(),
          ...(docReferrer && { referrer: cleanseUrlForRequest(docReferrer) })
        };

        const { postData: postNewAnalytics } = useAxios(
          appConfig.analyticsEndpoint,
          {
            type: 'CampaignPageView101',
            data: newPlatformData
          },
          undefined,
          undefined,

          // Since we're doing a POST there is no guarantee that the request won't
          // have left the client. But we have decided to retry no matter what as we're
          // typically doing distinct counts and won't be affected much by more page-views.
          () => {
            return true;
          }
        );

        postNewAnalytics(false).catch((e) => {
          Sentry.captureException(e, {
            contexts: {
              'Analytics data': newPlatformData
            }
          });

          // eslint-disable-next-line no-console
          console.error(`Could not post new analytics, ${e}`);
        });
      }

      handleSaveSession();
    }
  };

  return {
    handlePageView
  };
}
