import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';
import { SliderType } from '@/src/components/addons/slider/enums';

export interface FormElementSliderSettingsData extends BaseFormElementSettingsData {
  slider_type?: SliderType;
  slider?: {
    value: string;
    floor?: string;
    max?: string;
    ceil?: string;
    step?: string;
  };
}

export interface FormElementSliderData extends BaseFormElementData {
  settings?: FormElementSliderSettingsData;
}

type SliderValue = number | number[];

export interface FormElementSliderState extends BaseFormElementState<SliderValue> {
  elementMessage?: string;
  sliderType?: SliderType;
  slider?: {
    value?: number;
    floor?: number;
    max?: number;
    ceil?: number;
    step?: number;
    defaultMinimum?: number;
    defaultMaximum?: number;
  };
}

export class FormElementSliderModel extends BaseFormElementModel<
  SliderValue,
  FormElementSliderData,
  FormElementSliderState
> {
  parseFormElement(data: FormElementSliderData) {
    this.state.sliderType = data.settings?.slider_type || SliderType.SIMPLE;

    this.state.slider = {
      value: parseInt(data.settings?.slider?.value || '1'), // value || default min value
      max: parseInt(data.settings?.slider?.max || '100'), // default max value
      ceil: parseInt(data.settings?.slider?.ceil || '100'),
      floor: parseInt(data.settings?.slider?.floor || '1'),
      step: parseInt(data.settings?.slider?.step || '1')
    };
  }

  getInitialValue(): SliderValue | undefined {
    const initialValue = this.parseStringValue(this.getInitialStringValue() ?? '');

    if (
      this.state.sliderType === SliderType.RANGE &&
      !Array.isArray(initialValue) &&
      initialValue !== undefined &&
      this.state.slider?.value !== undefined &&
      this.state.slider?.max !== undefined
    ) {
      return [this.state.slider.value, this.state.slider.max];
    }

    return initialValue;
  }

  getStringifiedValue(): string | undefined {
    return this.state.value ? this.state.value.toString() : undefined;
  }

  parseStringValue(value: string): SliderValue | undefined {
    if (value[0] === '[') {
      return JSON.parse(value) as number[];
    }

    return Number(value);
  }

  getSerializedPostValue(): string | string[] {
    return Array.isArray(this.state.value)
      ? this.state.value.map((value) => value.toString())
      : typeof this.state.value !== 'undefined'
        ? this.state.value.toString()
        : '';
  }

  getSerializedCookieValue(): string {
    const value = this.getSerializedPostValue();
    return Array.isArray(value) ? JSON.stringify(value) : value;
  }

  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
