import { toDate, formatInTimeZone } from 'date-fns-tz';
import { format, isValid, parse } from 'date-fns';
import { useCampaignStore } from '@/src/store/campaign';

export const getWithTimezone = (date: Date | string): Date => {
  const campaignStore = useCampaignStore();
  return toDate(date, {
    timeZone: campaignStore.model?.state.config?.timezone ?? 'Europe/Copenhagen'
  });
};

export const parseDateStr = (str: string, formatString: string) => {
  const parseFormat = formatString
    .replace('Y', 'yyyy')
    .replace('m', 'MM')
    .replace('d', 'dd')
    .replace('H', 'HH')
    .replace('i', 'mm');
  return parse(str, parseFormat, new Date());
};

export const formatDate = (date: Date, formatString: string, handleTimezone = true): string => {
  const campaignStore = useCampaignStore();
  const timezone = campaignStore.model?.state.config?.timezone ?? 'Europe/Copenhagen';

  const convertedFormatString = formatString
    .replace('Y', 'yyyy')
    .replace('m', 'MM')
    .replace('d', 'dd')
    .replace('H', 'HH')
    .replace('i', 'mm');

  if (handleTimezone) {
    return formatInTimeZone(date, timezone, convertedFormatString);
  } else {
    return format(date, convertedFormatString);
  }
};

export const convertDateFormat = (dateStr: string, targetFormat: string) => {
  // Parse the date string, which is always in 'Y-m-d' format to a Date object
  const parsedDate = parseDateStr(dateStr, 'Y-m-d');

  if (isValid(parsedDate)) {
    return formatDate(parsedDate, targetFormat);
  }

  return '';
};
