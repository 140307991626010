import MobileDetect from 'mobile-detect';
import { useUtilityStore } from '@/src/store/utility';
import { useCampaignStore } from '@/src/store/campaign';
import { DeviceTypes, CampaignDeviceType, CampaignAdsSizeType } from '@/src/typings/enums/enums';
import { Cache } from '@/src/services/cache';

export enum Device {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile'
}

export interface DeviceData<Data> {
  desktop?: Data & DeviceDataMain;
  tablet?: Data;
  mobile?: Data;

  // Support for ads and other variants of device data
  default?: Data & DeviceDataMain;
  [key: string]: Data | undefined;
}

interface DeviceDataMain {
  overwrite_tablet?: string | boolean;
  overwrite_mobile?: string | boolean;

  // Support for ads
  [key: `overwrite_${string}`]: unknown;
}

function isTouchDevice() {
  return 'ontouchstart' in window;
}

function isIOSTablet() {
  return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
}

function isAndroidTablet() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('android') && !userAgent.includes('mobile');
}

function isSamsungTabletFallback() {
  const userAgent = navigator.userAgent.toLowerCase();

  const isSamsungBrowser = userAgent.includes('samsungbrowser');
  return isSamsungBrowser;
}

function useDevice(allowSimulation = true) {
  const userAgent = navigator.userAgent;

  // Cache the mobile-detect object in our cache storage so we don't continue
  // to re-initialize new objects.
  const md = Cache.get<MobileDetect>('mobile-detect') ?? new MobileDetect(userAgent);
  Cache.set('mobile-detect', md);

  // Get current device from the store. This allows for mobile emulation.
  const campaignStore = useCampaignStore();
  const currentDevice = campaignStore.currentDevice;

  let isMobile = !!md.mobile() || (allowSimulation && currentDevice === DeviceTypes.MOBILE);
  let isTablet = !!md.tablet() || (allowSimulation && currentDevice === DeviceTypes.TABLET);

  /**
   * Our package mobile-detect cant detect iPad version from 13+, which causes us to assume it is an iPad the user in coming from
   * in some other way - It seems from what I can find online, this is one of the most feasible solutions
   * Note: This will only apply if you are testing it from a real device or chrome emulator - If will not work
   * if you test it in a Desktop Safari browser with an iPad user agent, due to not being a touch device.
   */
  if (!isTablet && !isMobile && isTouchDevice() && isIOSTablet()) {
    isTablet = true;
  }

  // Handle Android tablets
  if (!isTablet && !isMobile && isAndroidTablet()) {
    isTablet = true;
  }

  // Samsung fallback for tablets with ambiguous user agents
  if (!isTablet && !isMobile && isSamsungTabletFallback()) {
    isTablet = true;
  }

  const isDesktop = !isMobile && !isTablet;

  isMobile = isMobile && !isTablet;
  isTablet = isTablet && !isMobile;

  return {
    isMobile,
    isTablet,
    isDesktop,
    device: isMobile ? Device.MOBILE : isTablet ? Device.TABLET : Device.DESKTOP
  };
}

/**
 * Locate device specific data in a known data format. This will when you have desktop, tablet & mobile
 * specific settings automatically fetch the correct one depending on the settings & current device.
 */
export function getDeviceData<Data>(
  data: DeviceData<Data>,
  overwriteMobileName: keyof (DeviceDataMain & Data) = 'overwrite_mobile',
  overwriteTabletName: keyof (DeviceDataMain & Data) = 'overwrite_tablet'
): Data | undefined {
  const campaignStore = useCampaignStore();

  if (
    campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
    campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.FIXED &&
    campaignStore.currentSimulatedView
  ) {
    const defaultData = data.default ?? undefined;

    const overwriteForView =
      defaultData === undefined ||
      typeof defaultData[`overwrite_${campaignStore.currentSimulatedView.view}`] === 'undefined' ||
      defaultData[`overwrite_${campaignStore.currentSimulatedView.view}`] + '' === '1';

    if (!overwriteForView) {
      return data[campaignStore.currentSimulatedView.view];
    }

    return defaultData ?? data.desktop;
  }

  const desktopData = data.desktop;
  const { isMobile, isTablet } = useDevice();

  const overwriteMobile =
    desktopData === undefined ||
    // eslint-disable-next-line security/detect-object-injection
    typeof desktopData[overwriteMobileName] === 'undefined' ||
    // eslint-disable-next-line security/detect-object-injection
    desktopData[overwriteMobileName] + '' === '1';

  const overwriteTablet =
    desktopData === undefined ||
    // eslint-disable-next-line security/detect-object-injection
    typeof desktopData[overwriteTabletName] === 'undefined' ||
    // eslint-disable-next-line security/detect-object-injection
    desktopData[overwriteTabletName] + '' === '1';

  if (isTablet && !overwriteTablet) {
    return data.tablet;
  }

  if (isMobile && !overwriteMobile) {
    return data.mobile;
  }

  return desktopData;
}

export function getDevice(): Device {
  const { isTablet, isMobile } = useDevice();

  if (isMobile) {
    return Device.MOBILE;
  }

  if (isTablet) {
    return Device.TABLET;
  }

  return Device.DESKTOP;
}

export interface DeviceSizes {
  id: string;
  label: string;
  dimensions?: Dimension;
}

export interface DeviceType {
  id?: DeviceTypes;
  name: string;
  icon: string;
  sizes: DeviceSizes[];
}

export interface Dimension {
  width: number;
  height: number;
}

const availableSimulationDevices: DeviceType[] = [
  {
    id: DeviceTypes.DESKTOP,
    name: 'Desktop',
    icon: 'desktop',
    sizes: [{ id: 'fullscreen', label: 'Fullscreen' }]
  },
  {
    id: DeviceTypes.TABLET,
    name: 'Tablet',
    icon: 'tablet',
    sizes: [
      { id: 'ipad', label: 'iPad', dimensions: { width: 768, height: 1024 } },
      { id: 'ipadPro', label: 'iPad Pro', dimensions: { width: 1024, height: 1366 } },
      { id: 'galaxyTab', label: 'Galaxy Tab', dimensions: { width: 800, height: 1280 } },
      { id: 'surfacePro', label: 'Surface Pro', dimensions: { width: 1366, height: 768 } }
    ]
  },
  {
    id: DeviceTypes.MOBILE,
    name: 'Mobile',
    icon: 'mobile',
    sizes: [
      { id: 'iphone8', label: 'iPhone 8', dimensions: { width: 375, height: 667 } },
      { id: 'iphoneX', label: 'iPhone X', dimensions: { width: 375, height: 812 } },
      { id: 'iphone11', label: 'iPhone 11', dimensions: { width: 414, height: 896 } },
      { id: 'iphone13', label: 'iPhone 13', dimensions: { width: 428, height: 926 } },
      { id: 'iphone13Pro', label: 'iPhone 13 Pro', dimensions: { width: 428, height: 926 } },
      { id: 'pixel2', label: 'Pixel 2', dimensions: { width: 411, height: 731 } },
      { id: 'pixel5', label: 'Pixel 5', dimensions: { width: 412, height: 853 } },
      { id: 'galaxyS5', label: 'Galaxy S5', dimensions: { width: 360, height: 640 } },
      { id: 'pixel6Pro', label: 'Pixel 6 Pro', dimensions: { width: 392, height: 869 } },
      { id: 'galaxyS21', label: 'Galaxy S21', dimensions: { width: 360, height: 800 } },
      { id: 'onePlus9', label: 'OnePlus 9', dimensions: { width: 402, height: 869 } },
      { id: 'onePlus9Pro', label: 'OnePlus 9 Pro', dimensions: { width: 402, height: 869 } }
    ]
  }
];

export const getDeviceTypes = () => {
  const campaignStore = useCampaignStore();

  if (
    campaignStore.model?.state.deviceType === CampaignDeviceType.ADS &&
    campaignStore.model?.state.adsSizeType === CampaignAdsSizeType.FIXED
  ) {
    return [];
  }

  if (campaignStore.model?.state.deviceType === CampaignDeviceType.MOBILE_ONLY) {
    return availableSimulationDevices.filter((device) => device.id === DeviceTypes.MOBILE);
  }

  return availableSimulationDevices;
};

export const getAvailableSimulationDevices = (
  deviceType?: CampaignDeviceType,
  dimensions?: string[],
  adsSizeType?: CampaignAdsSizeType
) => {
  const campaignStore = useCampaignStore();

  deviceType = deviceType ?? campaignStore.model?.state.deviceType;
  dimensions = dimensions ?? campaignStore.model?.state.adDimensions;
  adsSizeType = adsSizeType ?? campaignStore.model?.state.adsSizeType;

  if (deviceType === CampaignDeviceType.MOBILE_ONLY) {
    return availableSimulationDevices.filter((device) => device.id === DeviceTypes.MOBILE);
  }

  if (deviceType === CampaignDeviceType.ADS && adsSizeType === CampaignAdsSizeType.FIXED) {
    const devices: DeviceType[] = [
      {
        name: 'Ads',
        icon: 'message-square',
        sizes: []
      }
    ];

    if (devices[0].sizes.length === 0 && dimensions && dimensions.length > 0) {
      devices[0].sizes = dimensions.map<DeviceSizes>((dimension) => {
        const [width, height] = dimension.split('x').map(Number);

        return {
          id: dimension,
          label: dimension,
          dimensions: { width, height }
        };
      });
    }

    return devices;
  }

  return availableSimulationDevices;
};

/**
 * return container height based on current device (simulating or actual device)
 */
export const getDeviceHeight = () => {
  const campaignStore = useCampaignStore();
  const utilityStore = useUtilityStore();

  if (campaignStore.currentSimulatedView?.height) {
    return campaignStore.currentSimulatedView?.height;
  }

  return utilityStore.windowHeight;
};

export const setDevice = (device: DeviceTypes, onChange?: () => void) => {
  const campaignStore = useCampaignStore();
  campaignStore.requestDeviceChange(device, onChange);
};

export default useDevice;
