import { validate } from 'vee-validate';
import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';
import { FieldType } from '@/src/typings/enums/enums';
import type { EmailValidationModel } from '@/src/components/content/form/validation/types';
import { EmailValidationMap } from '@/src/components/content/form/validation/types';
import type { BaseEmailValidationData } from '@/src/components/content/form/validation/BaseValidationModel';
import type { EmailableValidationData } from '@/src/components/content/form/validation/Models/Emailable';
import { useCampaignStore } from '@/src/store/campaign';
import EmailValidationError from '@/src/components/content/form/validation/EmailValidationError';
import EmailValidationApiError from '@/src/components/content/form/validation/EmailValidationApiError';
import EmailValidationInitializationError from '@/src/components/content/form/validation/EmailValidationInitializationError';

interface FormElementTextSettingsData extends BaseFormElementSettingsData {
  country_codes_enabled?: string;
  country_codes_tags?: string;
  country_codes_validation_enable?: string;
  email_validation_service?: { provider: string; enable: boolean } & BaseEmailValidationData & EmailableValidationData;
}

export interface FormElementTextData extends BaseFormElementData {
  settings: FormElementTextSettingsData;
}

export interface FormElementTextValue {
  countryCode?: string;
  input?: string;
}

export interface FormElementTextState extends BaseFormElementState<FormElementTextValue> {
  country: {
    codesEnabled?: boolean;
    codeTags?: string[];
    confirmPhoneCountryCode: boolean;
  };

  emailValidationImplementation?: EmailValidationModel;
}

export class FormElementTextModel extends BaseFormElementModel<
  FormElementTextValue,
  FormElementTextData,
  FormElementTextState
> {
  public async validateOnSubmit(): Promise<boolean> {
    if (this.state.emailValidationImplementation) {
      try {
        const response = await this.state.emailValidationImplementation.validate(this.getSerializedPostValue());

        this.setValidationResult('email_validation_result', response);
        this.setValidationResult('email_validation_status', 'ok');
      } catch (e) {
        if (e instanceof EmailValidationError) {
          this.setValidationResult('email_validation_result', e.message);
          this.setValidationResult('email_validation_status', e.apiStatus);

          this.state.isValid = false;
          this.state.elementMessage = this.state.emailValidationImplementation.state.message;
          return false;
        }

        if (e instanceof EmailValidationApiError) {
          this.setValidationResult('email_validation_result', 'not validated');
          this.setValidationResult('email_validation_status', e.apiStatus);
          if (!this.state.emailValidationImplementation.state.bypassServiceFailure) {
            this.state.isValid = false;
            this.state.elementMessage = this.state.emailValidationImplementation.state.message;
            return false;
          }

          return true;
        }

        throw e;
      }
    }

    return true;
  }

  private setValidationResult(targetField: string, result: string): void {
    const campaignStore = useCampaignStore();
    const validationField = campaignStore.model?.getRegistrationFieldModel((field) => {
      return String(field.state.name) === targetField;
    });

    if (validationField) {
      validationField.state.value = validationField.parseStringValue(result);
    }
  }

  parseFormElement(data: FormElementTextData) {
    const state = this.state;
    state.emailValidationImplementation = undefined;
    if (
      data.input_html_type === 'email' &&
      data.settings?.email_validation_service &&
      data.settings?.email_validation_service.enable &&
      data.settings?.email_validation_service.provider &&
      data.settings?.email_validation_service.provider.toLowerCase() in EmailValidationMap
    ) {
      try {
        const providerKey = data.settings.email_validation_service.provider.toLowerCase();
        if (providerKey in EmailValidationMap) {
          const ValidationClass = EmailValidationMap[providerKey as keyof typeof EmailValidationMap];
          state.emailValidationImplementation = new ValidationClass({
            api_key: data.settings.email_validation_service.api_key,
            score: data.settings.email_validation_service.score,
            message: data.settings.email_validation_service.message,
            status: data.settings.email_validation_service.status ?? [],
            bypass_service_failure: data.settings.email_validation_service.bypass_service_failure
          });
        } else {
          throw new EmailValidationInitializationError('Provider key not found');
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        throw new EmailValidationInitializationError('Error instantiating email validation class');
      }
    }

    state.country = state.country ?? {};
    if (data.settings && data.settings.country_codes_enabled) {
      state.country.codesEnabled = data.settings.country_codes_enabled === '1';
    } else {
      state.country.codesEnabled = false;
    }
    if (data.settings && data.settings.country_codes_tags) {
      state.country.codeTags = data.settings.country_codes_tags.split(',');
    } else {
      state.country.codeTags = undefined;
    }

    if (data.settings && data.settings.country_codes_validation_enable) {
      state.country.confirmPhoneCountryCode = data.settings && data.settings.country_codes_validation_enable === '1';
    } else {
      state.country.confirmPhoneCountryCode = false;
    }
  }

  getStringifiedValue(): string | undefined {
    if (this.state.value?.input) {
      return `${this.state.value.countryCode ? this.state.value.countryCode : ''}${this.state.value.input}`;
    }

    return undefined;
  }

  /**
   * Validate the current states value.
   * added validate locally to look up on input instead of getSerializedPostValue()
   * had issue with phone number with countrycode value was +968 and would try and validate that together with the number as value +96823212321
   * this would then break the validation for number and min / max characters -> now we only check the input etc phone number 22334455
   */
  public async validate(propagate = true): Promise<boolean> {
    if (this.state.ignoreValidation) {
      this.state.isValid = true;
      this.state.validated = true;
      return true;
    }

    if (this.state.containsEncryptedValue) {
      this.state.isValid = true;
      this.state.validated = true;
      return this.state.isValid;
    }

    this.state.isValid = (await validate(this.state.value?.input, this.getValidationRules().join('|'))).valid;

    this.state.validated = true;

    if (propagate) {
      this.validateDependencies();
    }

    return this.state?.isValid ?? false;
  }

  getValidationRules() {
    const rules = super.getValidationRules();

    if (this.state.ignoreValidation) {
      return [];
    }

    if ([FieldType.EMAIL, FieldType.EMAIL_CONFIRM].includes(this.state.type) && this.state.required) {
      rules.push('email');
    }

    if ([FieldType.PHONE_NUMBER, FieldType.PHONE_NUMBER_CONFIRM].includes(this.state.type) && this.state.required) {
      rules.push('phone');
    }

    if (this.state.type === FieldType.PHONE_NUMBER_CONFIRM) {
      const phoneNumberField = this.getSpecificFieldFromInputType<FormElementTextModel>(FieldType.PHONE_NUMBER);
      const fieldId = phoneNumberField?.state.id;

      if (phoneNumberField && fieldId) {
        if (this.state.country.confirmPhoneCountryCode) {
          rules.push(`is_same_as:${fieldId}`);
        } else {
          rules.push(`is_same_number_as:${fieldId},${phoneNumberField.id}`);
        }
      }
    }

    if (this.state.type === FieldType.EMAIL_CONFIRM) {
      const fieldEmail = this.getSpecificFieldFromInputType<FormElementTextModel>(FieldType.EMAIL);
      const fieldId = fieldEmail?.state.id;
      if (fieldEmail && fieldId) {
        rules.push(`is_same_as:${fieldId}`);
      }
    }
    if (this.state.type === FieldType.PASSWORD_CONFIRM) {
      const fieldPassword = this.getSpecificFieldFromInputType<FormElementTextModel>(FieldType.PASSWORD);
      const fieldId = fieldPassword?.state.id;
      if (fieldPassword && fieldId) {
        rules.push(`is_same_as:${fieldId}`);
      }
    }

    return rules;
  }

  getValidationDependencies(): FormElementTextModel[] {
    if (this.state.type === FieldType.PHONE_NUMBER) {
      const confirmPhoneNumberField = this.getSpecificFieldFromInputType<FormElementTextModel>(
        FieldType.PHONE_NUMBER_CONFIRM
      );

      const fieldId = confirmPhoneNumberField?.state.id;
      if (confirmPhoneNumberField && fieldId) {
        return [confirmPhoneNumberField];
      }
    }

    if (this.state.type === FieldType.PASSWORD) {
      const passwordConfirmField = this.getSpecificFieldFromInputType<FormElementTextModel>(FieldType.PASSWORD_CONFIRM);

      const fieldId = passwordConfirmField?.state.id;
      if (passwordConfirmField && fieldId) {
        return [passwordConfirmField];
      }
    }

    if (this.state.type === FieldType.EMAIL) {
      const emailConfirmField = this.getSpecificFieldFromInputType<FormElementTextModel>(FieldType.EMAIL_CONFIRM);

      const fieldId = emailConfirmField?.state.id;
      if (emailConfirmField && fieldId) {
        return [emailConfirmField];
      }
    }

    return [];
  }

  getInitialValue(): FormElementTextValue | undefined {
    return this.parseStringValue(this.getInitialStringValue() ?? '');
  }

  parseStringValue(value: string): FormElementTextValue | undefined {
    let countryCode: string | undefined;

    if (this.state.country?.codesEnabled && this.state.country?.codeTags) {
      this.state.country.codeTags.forEach((codeTag) => {
        if (value.startsWith(codeTag)) {
          countryCode = codeTag;
          value = value.replace(codeTag, '');
        }
      });
    }

    if (!value) {
      return undefined;
    }

    return {
      input: value,
      countryCode
    };
  }

  getSerializedPostValue(): string {
    if (this.state.value?.input) {
      return `${this.state.value.countryCode ?? ''}${this.state.value.input}`;
    }

    return '';
  }

  getSerializedCookieValue(): string {
    return this.getSerializedPostValue();
  }
}
