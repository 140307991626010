import type { FieldOptionItem } from '../types';
import type { TargetType } from '@/src/typings/enums/enums';
import { InputOptionType } from '@/src/typings/enums/enums';
import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';
import type { TruncateData, TruncateState } from '@/src/utilities/Truncate';

export interface FormElementCheckboxSettingsData extends BaseFormElementSettingsData {
  checkbox_type: 'single' | 'multiple';
  checkbox_values?: 'true-false' | '1-0' | 'custom';
  checkbox_values_custom_true?: string;
  checkbox_values_custom_false?: string;
  option_type?: InputOptionType; // without countries
  options_interval?: {
    interval_from: string;
    interval_to: string;
  };
  options_line?: string;
  cta_type?: 'url' | 'none' | 'popover';
  cta_url?: string;
  cta_target?: TargetType;
  cta_popover?: string;
  options?: {
    [key: string]: string;
  };
  truncate?: TruncateData;
}

export interface FormElementCheckboxData extends BaseFormElementData {
  settings?: FormElementCheckboxSettingsData;
  options?: {
    [key: string]: string;
  };
}

export type FormElementCheckboxValue = boolean | string[];

export interface FormElementCheckboxState extends BaseFormElementState<FormElementCheckboxValue> {
  checkbox: {
    type: 'single' | 'multiple';
    values?: 'true-false' | '1-0' | 'custom';
    customTrue?: string;
    customFalse?: string;
  };
  optionType?: InputOptionType; // without countries
  optionsInterval?: {
    intervalFrom: number;
    intervalTo: number;
  };
  optionsLine?: string;
  options?: FieldOptionItem[];
  cta?: {
    type: 'url' | 'none' | 'popover';
    url?: string;
    target?: TargetType;
    popover?: number;
  };
  truncate?: TruncateState;
}

export class FormElementCheckboxModel extends BaseFormElementModel<
  FormElementCheckboxValue,
  FormElementCheckboxData,
  FormElementCheckboxState
> {
  parseFormElement(data: FormElementCheckboxData) {
    this.state.checkbox = this.state.checkbox ?? {};
    this.state.truncate = this.state.truncate ?? {};
    this.state.checkbox.type = data.settings?.checkbox_type ?? 'single';
    this.state.checkbox.values = data.settings?.checkbox_values ?? '1-0';

    if (data.settings?.truncate?.enable) {
      this.state.truncate = {
        enabled: data.settings.truncate.enable === '1',
        labelMore: data.settings.truncate.label_more || 'read more',
        labelLess: data.settings.truncate.label_less || 'read less',
        limit: Number(data.settings.truncate.limit) || 100
      };
    } else {
      this.state.truncate = {};
    }

    if (data.settings?.checkbox_values_custom_true) {
      this.state.checkbox.customTrue = data.settings?.checkbox_values_custom_true;
    }

    if (data.settings?.checkbox_values_custom_false) {
      this.state.checkbox.customFalse = data.settings?.checkbox_values_custom_false;
    }

    this.state.cta = {
      type: data.settings?.cta_type || 'none'
    };

    switch (data.settings?.cta_type) {
      case 'url':
        if (data.settings.cta_url) {
          this.state.cta.url = data.settings.cta_url;
        }

        if (data.settings.cta_target) {
          this.state.cta.target = data.settings.cta_target;
        }
        break;

      case 'popover':
        if (data.settings.cta_popover) {
          this.state.cta.popover = Number(data.settings.cta_popover);
        }
    }

    this.state.optionsLine = data.settings?.options_line;
    this.state.optionType = data.settings?.option_type;

    switch (this.state.optionType) {
      case InputOptionType.INTERVAL:
        if (data.settings?.options_interval) {
          this.state.optionsInterval = {
            intervalTo: Number(data.settings?.options_interval.interval_to),
            intervalFrom: Number(data.settings?.options_interval.interval_from)
          };

          const options: FieldOptionItem[] = [];

          for (let i = this.state.optionsInterval.intervalFrom; i <= this.state.optionsInterval.intervalTo; i++) {
            options.push({
              value: `${i}`,
              label: `${i}`
            });
          }

          this.state.options = options;
        } else {
          this.state.options = undefined;
        }
        break;

      case InputOptionType.OPTIONS:
        this.state.options = (data.settings?.options ? this.parseFieldOptions(data.settings.options) : undefined)?.map(
          (option) => {
            return {
              label: option.label,
              value: option.value ? option.value : option.label
            };
          }
        );
        break;

      case InputOptionType.LINE:
        this.state.options = data.options
          ? Object.entries(data.options).map<FieldOptionItem>((value) => {
              return {
                value: value[1],
                label: value[1]
              };
            })
          : undefined;
        break;

      case InputOptionType.PASTE:
        if (data.options && !Array.isArray(data.options)) {
          const options: FieldOptionItem[] = [];

          for (const optionIndex in data.options) {
            if (Object.prototype.hasOwnProperty.call(data.options, optionIndex)) {
              options.push({
                value: optionIndex,
                label: data.options[`${optionIndex}`]
              });
            }
          }

          this.state.options = options;
        } else {
          this.state.options = undefined;
        }
        break;

      default:
        this.state.options = undefined;
    }

    const initialValue = this.getInitialValue();

    if (this.state.options && Array.isArray(initialValue)) {
      this.state.options.forEach((option) => {
        option.selected = initialValue.includes(option.value);
      });
    }
  }

  getInitialValue(): FormElementCheckboxValue | undefined {
    return this.parseStringValue(this.getInitialStringValue() ?? '');
  }

  getStringifiedValue(): string | undefined {
    if (Array.isArray(this.state.value)) {
      const values = this.state.value.map((val) => val);
      return values.join(',');
    } else if (this.state.value && this.state.value.toString().length > 0) {
      // Convert the above to string as the value can be true/false
      return this.state.value.toString();
    }
    return undefined;
  }

  parseStringValue(value: string): FormElementCheckboxValue | undefined {
    if (value && value[0] === '[') {
      return JSON.parse(value) as FormElementCheckboxValue;
    }

    return value === '1';
  }

  getSerializedPostValue(): string[] | string {
    if (typeof this.state.value === 'undefined') {
      if (this.state.checkbox.type === 'multiple') {
        return [];
      }

      return '0';
    }

    return Array.isArray(this.state.value) ? this.state.value : this.state.value ? '1' : '0';
  }

  getSerializedCookieValue(): string {
    const value = this.getSerializedPostValue();
    return Array.isArray(value) ? JSON.stringify(value) : value;
  }

  getValidationRules(): string[] {
    const rules = super.getValidationRules();

    if (rules.includes('required')) {
      rules.splice(rules.indexOf('required'), 1);
      rules.push('required-checkbox');
    }

    return rules;
  }

  /**
   * Updated by Nicky Christensen 12-07-2021 (Added Handling of Custom Values in Checkboxes)
   */
  authorSignature(): string {
    return 'Jannik Fischer';
  }
}
