import type { GamePersonalityTestQuestionState } from '../Model';
import { PersonalityTestAnswerType } from '../Model';
import { SdkPersonalityTestQuestionAnswerModel } from './question-answer-model';

export class SdkPersonalityTestQuestionModel {
  public readonly id: number;
  public readonly questionHtml: string;
  public readonly answers: SdkPersonalityTestQuestionAnswerModel[];
  public readonly answerType: string | undefined = undefined;

  constructor(
    state: GamePersonalityTestQuestionState,
    public readonly isLastQuestion: boolean
  ) {
    this.id = state.id;
    this.questionHtml = state.question;
    this.answers = state.answers.map((answer) => new SdkPersonalityTestQuestionAnswerModel(state, answer));

    switch (state.answerType) {
      case PersonalityTestAnswerType.TEXT:
        this.answerType = 'text';

      case PersonalityTestAnswerType.IMAGE:
        this.answerType = 'image';

      case PersonalityTestAnswerType.SLIDER:
        this.answerType = 'slider';

      case PersonalityTestAnswerType.SWIPE:
        this.answerType = 'swipe';
    }
  }
}
