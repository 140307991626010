import type { MetricData } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import { inIframe } from './Utilities';
import { getQueryParams } from '@/src/utilities/Url';

interface EventData {
  type: string;
  action: string;
  uri: string;
  campaignId: number;

  data?: object;
}

export interface IframeEventData<Data = string> {
  action: string;
  uri?: string;
  data: Data;
  type: string;
}

interface NoFlowPageEvent {
  winner: boolean;
  metricData: MetricData;
  registrationId?: number;
  bulkPrize?: {
    code?: string | number;
    value?: string | number;
  };
}

interface GameStateEvent {
  winner: boolean;
  metricData: MetricData;
  registrationId?: number;
  bulkPrize?: {
    code?: string | number;
    value?: string | number;
  };
}

let sentNoFlowPageStatusEvent = false;
let sentGameStateEvent = false;

export const sendEvent = (action: string, data?: object, topLevel?: boolean) => {
  topLevel = topLevel || false;

  const campaignStore = useCampaignStore();

  const eventData: EventData = {
    type: 'leadfamly-campaign',
    action,
    uri: window.location.href,
    campaignId: Number(campaignStore.model?.id),
    ...(data && { data })
  };

  const windowTarget = topLevel ? window.top : window.parent;

  if (windowTarget && inIframe()) {
    // nosem: javascript.browser.security.wildcard-postmessage-configuration.wildcard-postmessage-configuration
    windowTarget.postMessage(JSON.stringify(eventData), '*');
  }
};

export const onEvent = <T>(action: string, callback: (data: IframeEventData<T>) => void) => {
  const listener = (e: MessageEvent) => {
    let data: IframeEventData<T>;

    try {
      data = JSON.parse(e.data) as IframeEventData<T>;

      if (data.action === action) {
        callback(data);
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {}
  };

  window.addEventListener('message', listener);

  return () => {
    window.removeEventListener('message', listener);
  };
};

export const onEventOnce = <T>(action: string, callback: (data: IframeEventData<T>) => void) => {
  const listener = onEvent<T>(action, (data) => {
    listener();
    callback(data);
  });
};

export const sendGameStateEvent = () => {
  const campaignStore = useCampaignStore();
  const won = campaignStore.gameWinner;
  const flowRegistrationInfo = campaignStore.flowRegistrationInfo;
  const params = getQueryParams();

  if (!sentGameStateEvent && won !== null && (flowRegistrationInfo?.id || params['send-game-event'])) {
    sentGameStateEvent = true;

    const replacementTags = campaignStore.replacementTags;

    const eventData: GameStateEvent = {
      winner: won,
      metricData: campaignStore.metricData,
      registrationId: flowRegistrationInfo?.id
    };

    if (replacementTags.bulk_prize_value) {
      eventData.bulkPrize = eventData.bulkPrize ?? {};
      eventData.bulkPrize.code = replacementTags.bulk_prize_value;
    }

    if (replacementTags.bulk_prize_value) {
      eventData.bulkPrize = eventData.bulkPrize ?? {};
      eventData.bulkPrize.value = replacementTags.bulk_prize_value;
    }

    sendEvent('game-state', eventData);
  }
};

export const sendNoFlowPageStatusEvent = () => {
  const campaignStore = useCampaignStore();
  const won = campaignStore.gameWinner;
  const flowRegistrationInfo = campaignStore.flowRegistrationInfo;

  if (!sentNoFlowPageStatusEvent && won !== null) {
    sentNoFlowPageStatusEvent = true;
    const replacementTags = campaignStore.replacementTags;

    const eventData: NoFlowPageEvent = {
      winner: won,
      metricData: campaignStore.metricData,
      ...(flowRegistrationInfo?.id && { registrationId: flowRegistrationInfo.id })
    };

    if (replacementTags.bulk_prize_value) {
      eventData.bulkPrize = eventData.bulkPrize ?? {};
      eventData.bulkPrize.code = replacementTags.bulk_prize_value;
    }

    if (replacementTags.bulk_prize_value) {
      eventData.bulkPrize = eventData.bulkPrize ?? {};
      eventData.bulkPrize.value = replacementTags.bulk_prize_value;
    }

    sendEvent('no-flow-page', eventData);
  } else {
    sendEvent('no-flow-page', {});
  }
};
