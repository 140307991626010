export const setStorageItem = (name: string, value: string) => {
  try {
    localStorage.setItem(name, value);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {}
};

export const getStorageItem = (name: string) => {
  try {
    // @ts-ignore
    return localStorage.getItem(name);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {}
};

export const removeStorageItem = (name: string) => {
  try {
    localStorage.removeItem(name);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {}
};

export const getAllStorageItems = () => {
  const localStorageKeys: string[] = [];

  try {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key !== null) {
        localStorageKeys.push(key);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {}

  return localStorageKeys;
};
