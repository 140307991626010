import type { Raw } from 'vue';
import { defineStore } from 'pinia';
import type { SectionBaseModel } from '@/src/components/layout/section/SectionBaseModel';
import { SectionType } from '@/src/typings/enums/enums';
import type { ModelTypes } from '@/src/typings/types/types';

interface TabStrings {
  plural?: string;
  single?: string;
  addNew?: string;
}

export interface TabsCategories {
  id: SectionType;
  label?: string;
  active?: boolean;
  hide?: boolean;
  isActive?: boolean;
  strings?: TabStrings;
}

interface State {
  showPrizeSettingsModal: boolean;
  showGameSettingsModal: boolean;
  showLayoutSettingsModal: boolean;
  isNavigatorActive: boolean;
  activeTabCategory?: SectionType;
  activeFormUrl: string;
  activeModel: Raw<ModelTypes> | undefined;
  isContextMenuVisible: boolean;
  selectedContextItem?: Raw<ModelTypes>;
  loading: boolean;
  contextXPosition: number;
  contextYPosition: number;
  sectionWithGame: Raw<SectionBaseModel> | null;
  navigatorCategories: TabsCategories[];
  deletePrompt?: {
    title?: string;
    description?: string;
    callback: () => Promise<void>;
  };

  error?: {
    title: string;
    description: string;
  };
}

export const useEditingStore = defineStore('editing', {
  state: (): State => ({
    showPrizeSettingsModal: false,
    showGameSettingsModal: false,
    showLayoutSettingsModal: false,
    isNavigatorActive: false,
    activeTabCategory: undefined,
    activeFormUrl: '',
    activeModel: undefined,
    isContextMenuVisible: false,
    selectedContextItem: undefined,
    loading: false,
    contextXPosition: 0,
    contextYPosition: 0,
    sectionWithGame: null,
    navigatorCategories: [
      {
        id: SectionType.SECTION,
        label: 'Sections',
        active: true,
        strings: {
          plural: 'sections',
          single: 'section',
          addNew: 'Add new section'
        }
      },
      {
        id: SectionType.FLOWPAGE,
        isActive: false,
        hide: false,
        label: 'Flow pages',
        strings: {
          plural: 'flow pages',
          single: 'flow page',
          addNew: 'Add new flow page'
        }
      },
      {
        id: SectionType.POPOVER,
        isActive: false,
        hide: false,
        label: 'Popovers',
        strings: {
          plural: 'popovers',
          single: 'popover',
          addNew: 'Add new popover'
        }
      }
    ]
  }),
  getters: {
    activeNavigatorId(state) {
      return state.activeModel?.state.id;
    }
  },
  actions: {
    setActiveModel(model: ModelTypes) {
      this.activeModel = model;
      if (this.activeModel) {
        this.activeModel.setEditingActive();
      }
    },
    hideError() {
      this.error = undefined;
    },
    showError(title: string, description: string) {
      this.error = {
        title,
        description
      };
    },
    hideDeletePrompt() {
      this.deletePrompt = undefined;
    },
    showDeletePrompt(callback: () => Promise<void>, title?: string, description?: string) {
      this.deletePrompt = {
        title,
        description,
        callback
      };
    },
    removeActiveModel() {
      if (this.activeModel?.state.edit) {
        this.activeModel.state.edit.isActive = false;
      }
      this.activeModel = undefined;
    },
    hideContextMenu() {
      this.isContextMenuVisible = false;
      this.selectedContextItem = undefined;
    },
    showContextMenu(isActive?: boolean, event?: MouseEvent, model?: ModelTypes) {
      this.contextXPosition = event ? event.clientX : 0;
      this.contextYPosition = event ? event.clientY : 0;
      if (isActive) {
        this.isContextMenuVisible = true;
        this.selectedContextItem = model;
      }
    }
  }
});
