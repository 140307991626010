import type { AddonLeaderboardv2Model } from '../../addons/leaderboard-v2/Model';
import { useEditingStore } from '@/src/store/editing';
import { useClipboardStore } from '@/src/store/clipboard';
import { BaseModel } from '@/src/models/BaseModel';
import type { ContentColumnData } from '@/src/typings/interfaces/data/grid/column';
import type { AddonLfTextModel } from '@/src/components/addons/lf-text/Model';
import type { AddonLfImageModel } from '@/src/components/addons/lf-image/Model';
import type { AddonGameflowModel } from '@/src/components/addons/gameflow/Model';
import type { AddonLfHeadlineModel } from '@/src/components/addons/lf-headline/Model';
import type { AddonLfAnimatedHeadlineModel } from '@/src/components/addons/lf-animated-headline/Model';
import type { AddonLfButtonModel } from '@/src/components/addons/lf-button/Model';
import type { AddonLfIconModel } from '@/src/components/addons/lf-icon/Model';
import type { AddonHTMLModel } from '@/src/components/addons/html-element/Model';
import type { SettingsModel } from '@/src/components/layout/SettingsModel';
import ActionsModel from '@/src/models/actions/ActionsModel';
import type { AddonLfDividerModel } from '@/src/components/addons/lf-divider/Model';
import { ColumnSettingsModel } from '@/src/components/layout/column/ColumnSettingsModel';
import type { AddonAccordionModel } from '@/src/components/addons/accordion/Model';
import type { AddonRSSModel } from '@/src/components/addons/rss/Model';
import type { AddonShareModel } from '@/src/components/addons/share/Model';
import type { AddonIframeElementModel } from '@/src/components/addons/iframe-element/Model';
import type { AddonCountdownModel } from '@/src/components/addons/countdown/Model';
import type { AddonContactCTAModel } from '@/src/components/addons/contact-cta/Model';
import type { AddonGoogleMapsModel } from '@/src/components/addons/googlemap/Model';
import type { AddonLFShareModel } from '@/src/components/addons/lf-share/Model';
import type { AddonMenuModel } from '@/src/components/addons/menu/Model';
import type { AddonPrizesModel } from '@/src/components/addons/prizes/Model';
import type { AddonRegistrationModel } from '@/src/components/addons/registration/Model';
import type { AddonSliderModel } from '@/src/components/addons/slider/Model';
import type { AddonSlideShowModel } from '@/src/components/addons/slideshow/Model';
import type { AddonSponsorsModel } from '@/src/components/addons/sponsors/Model';
import type { AddonTipAFriendModel } from '@/src/components/addons/tipafriend/Model';
import type { AddonTrustPilotModel } from '@/src/components/addons/trustpilot/Model';
import type { AddonVideoEmbedModel } from '@/src/components/addons/video-embed/Model';
import type { AddonGameplayModel } from '@/src/components/addons/gameplay/Model';
import type { AddonStepGuideModel } from '@/src/components/addons/stepguide/Model';
import type { AddonGigyaModel } from '@/src/components/addons/gigya/Model';
import type { AddonQuestionValidationModel } from '@/src/components/addons/question-validation/Model';
import type { AddonAuthenticationModel } from '@/src/components/addons/authentication/Model';
import type { AddonAgeGateModel } from '@/src/components/addons/age-gate/Model';
import type RowModel from '@/src/components/layout/row/RowModel';
import type { AdvancedAddonsStyleData, CustomCSS } from '@/src/typings/interfaces/data/settings/settings';
import { CustomCSSModel } from '@/src/models/CustomCSSModel';
import { getDeviceData } from '@/src/hooks/useDevice';
import { SectionType } from '@/src/typings/enums/enums';
import { createEditFormUrl, generateUniqueId } from '@/src/utilities/Utilities';
import type { AddonIFrameGamesModel } from '@/src/components/addons/iframe-games/Model';
import AddonModel from '@/src/models/grid/AddonModel';
import type { AddonSocialShareModel } from '@/src/components/addons/social-share/Model';
import type { AddonLeaderboardModel } from '@/src/components/addons/leaderboard/Model';
import type { ReplacementTags } from '@/src/store/campaign';
import { useCampaignStore } from '@/src/store/campaign';
import { Cache } from '@/src/services/cache';
import type { AddonLottieModel } from '@/src/components/addons/lottie/Model';
import { AddonModelMapping } from '@/src/components/layout/column/map';

interface NewAddon {
  model: ContentAddonType;
  id: number;
}

interface ContentAddonEditState {
  isFocus?: boolean;
  isActive?: boolean;
  isCollapsed?: boolean;
}

export interface ContentAddon<Alias extends string> {
  id?: string;
  alias: Alias;
  label?: string;
  edit?: ContentAddonEditState;
  // model: ContentAddonType;
  settings?: {
    advanced?: AdvancedAddonsStyleData;
  };
}

export type ContentAddonType =
  | AddonLeaderboardModel
  | AddonCountdownModel
  | AddonIframeElementModel
  | AddonShareModel
  | AddonRSSModel
  | AddonIFrameGamesModel
  | AddonAccordionModel
  | AddonLfDividerModel
  | AddonLfTextModel
  | AddonLfAnimatedHeadlineModel
  | AddonLfHeadlineModel
  | AddonLfImageModel
  | AddonLfButtonModel
  | AddonLfIconModel
  | AddonHTMLModel
  | AddonContactCTAModel
  | AddonGoogleMapsModel
  | AddonLFShareModel
  | AddonMenuModel
  | AddonPrizesModel
  | AddonRegistrationModel
  | AddonSliderModel
  | AddonSlideShowModel
  | AddonSponsorsModel
  | AddonTipAFriendModel
  | AddonTrustPilotModel
  | AddonVideoEmbedModel
  | AddonGigyaModel
  | AddonStepGuideModel
  | AddonAgeGateModel
  | AddonAuthenticationModel
  | AddonQuestionValidationModel
  | AddonGameplayModel
  | AddonGameflowModel
  | AddonSocialShareModel
  | AddonLottieModel
  | AddonLeaderboardv2Model;

interface ColumnEditState {
  isCollapsed: boolean;
  isActive: boolean;
  isFocus: boolean;
  hasActiveAddon: boolean;
}

interface ColumnConfigState {
  hasRegistrationAddon: boolean;
}

export interface ColumnModelState {
  modelId: string;
  id: string;
  classIdentifier: string;
  size: number;
  originalSize: number;
  edit?: ColumnEditState;
  label: string;
  addons: ContentAddonType[];
  settings: SettingsModel;
  action?: ActionsModel;
  config?: ColumnConfigState;
  customCss: CustomCSSModel | undefined;
}

export default class ColumnModel extends BaseModel<ContentColumnData, ColumnModelState> {
  public sectionId: number | undefined;
  public campaignId: number;
  public row: RowModel;
  public replacementTags?: ReplacementTags;
  private deleted = false;

  constructor(data: ContentColumnData, row: RowModel) {
    super(data);
    this.row = row;
    this.campaignId = row.campaignId;

    if (row.section) {
      this.sectionId = row.section.state.id;
    }

    const reservedIds = Cache.get<string[]>('reserved-column-ids') ?? [];

    // Conflict found or no column id exists. Some other columns already uses this ID.
    // We need to refresh it to avoid conflicts with style or other functionality.
    if (!data.id || reservedIds.includes(data.id)) {
      data.id = generateUniqueId();
    }

    reservedIds.push(data.id);
    Cache.set('reserved-column-ids', reservedIds);

    this.parse(data);
  }

  getValueRoot() {
    return 'settings';
  }

  getTotalAddons() {
    return this.getData().addons.length;
  }

  parse(data: ContentColumnData) {
    const campaignStore = useCampaignStore();
    const state = this.state;
    state.modelId = this.modelId;
    state.id = data.id ?? generateUniqueId();
    state.classIdentifier = `grid__col--${state.id}`;
    state.label = data.settings?.basic?.name || 'Column';
    state.size = state.originalSize = Number(data.size);

    const editState = campaignStore.model?.state.edit;

    if (!state.edit && editState?.enabled) {
      state.edit = {
        isActive: false,
        isFocus: false,
        isCollapsed: true,
        hasActiveAddon: false
      };
    }

    state.config = {
      hasRegistrationAddon: ColumnModel.checkForAddons(data, 'registration')
    };

    const addonsMap: { [key: string]: ContentAddonType } = {};

    if (state.addons) {
      state.addons.forEach((addon) => {
        addonsMap[`${addon.state.id}`] = addon;
      });
    }

    state.addons = data.addons
      .filter((addon) => !!addon.alias)
      .map((addon) => {
        if (typeof AddonModelMapping[addon.alias] === 'undefined') {
          throw new TypeError(`Unrecognized addon "${addon.alias}"`);
        }

        if (addon.id && typeof addonsMap[`${addon.id}`] !== 'undefined') {
          // @ts-ignore
          addonsMap[`${addon.id}`].setData(addon);
          return addonsMap[`${addon.id}`];
        }

        return new AddonModelMapping[addon.alias](addon, this);
      });

    if (state.settings) {
      state.settings.setData(data.settings ?? {});
    } else {
      state.settings = new ColumnSettingsModel(data.settings ?? {}, this.getSection());
    }

    if (data?.settings?.basic?.action && data?.settings?.basic?.action?.type) {
      state.action = new ActionsModel(data.settings.basic.action);
    }

    if (data.settings?.advanced?.customcss) {
      state.customCss = ColumnModel.parseCustomCSSData(data, state.classIdentifier);
    }
  }

  public getSection() {
    return this.row.section;
  }

  public getEditUrl() {
    const editFormUrl = `/edit/campaign/column/${this.campaignId}/${this.sectionId}?row_index=${this.rowIndex}&col_index=${this.index}`;
    return createEditFormUrl(editFormUrl);
  }

  reload() {
    this.row.section.reload();
  }

  setHasActiveAddon(hasActiveAddon: boolean) {
    if (this.state.edit) {
      this.state.edit.hasActiveAddon = hasActiveAddon;
    }
  }

  canDelete(): boolean {
    if (this.index === 0 && this.row.state.columns.length === 1) {
      return false;
    }

    return this.row.state.columns.length >= 1;
  }

  canOpenSectionSettings() {
    return this.getSection().getSectionType() === SectionType.FLOWPAGE;
  }

  canOpenGameSettings() {
    return this.getSection().getSectionType() === SectionType.FLOWPAGE;
  }

  canCopy(): boolean {
    return true;
  }

  canDuplicate(): boolean {
    const row = this.row;
    const rowState = row.state;

    const hasRegistrationAddon = this.getAddons('registration').length > 0;

    return !(rowState.columns && rowState.columns.length >= 4) && !hasRegistrationAddon;
  }

  canPaste(): boolean {
    const clipboardStore = useClipboardStore();
    const row = this.row;
    const rowState = row.state;

    if (clipboardStore.elementInClipboard instanceof ColumnModel) {
      const parentSection = this.getSection();

      if (!parentSection.state.config.hasRegistrationAddon) {
        return true;
      } else {
        const model = clipboardStore.elementInClipboard;

        if (model && model.state && model.state.config) {
          return !model.state.config.hasRegistrationAddon;
        }
      }
    } else if (
      (clipboardStore.elementInClipboard instanceof ColumnModel ||
        clipboardStore.elementInClipboard instanceof AddonModel) &&
      this.state.config
    ) {
      return !this.state.config.hasRegistrationAddon;
    }

    return (
      (clipboardStore.elementInClipboard instanceof ColumnModel && rowState.columns && rowState.columns.length <= 4) ||
      clipboardStore.elementInClipboard instanceof AddonModel
    );
  }

  canMoveUp(): boolean {
    return this.index > 0;
  }

  onMoveUp() {
    this.row.onMoveColumnUp(this.index);
  }

  canMoveDown(): boolean {
    const row = this.row;
    const rowState = row.state;

    return rowState.columns.length - 1 > this.index;
  }

  onMoveDown() {
    this.row.onMoveColumnDown(this.index);
  }

  onMoveAddonUp(currentAddonIndex: number) {
    const editingStore = useEditingStore();
    const data = this.getData();
    const addons = data.addons;
    const currentItem = addons[Number(currentAddonIndex)];

    addons[Number(currentAddonIndex)] = addons[currentAddonIndex - 1];
    addons[currentAddonIndex - 1] = currentItem;
    data.addons = addons;

    this.parse(data);

    editingStore.setActiveModel(this.state.addons[currentAddonIndex - 1]);
  }

  onMoveAddonDown(currentAddonIndex: number) {
    const editingStore = useEditingStore();

    const data = this.getData();
    const addons = data.addons;
    const currentItem = addons[Number(currentAddonIndex)];

    addons[Number(currentAddonIndex)] = addons[currentAddonIndex + 1];
    addons[currentAddonIndex + 1] = currentItem;
    data.addons = addons;

    this.parse(data);

    editingStore.setActiveModel(this.state.addons[currentAddonIndex + 1]);
  }

  onPasteElement() {
    const clipboardStore = useClipboardStore();

    if (!clipboardStore.dataInClipboard) {
      return;
    }

    if (clipboardStore.elementInClipboard instanceof AddonModel) {
      this.onPasteAddon(clipboardStore.dataInClipboard, this.index);
    } else {
      this.row.onPasteColumn(clipboardStore.dataInClipboard, this.index);
    }
  }

  async onPasteAddon(addonData: string, addonIndex: number) {
    const editingStore = useEditingStore();
    const columnData = this.getData();
    const clone = JSON.parse(addonData) as ContentAddon<string>;
    clone.id = generateUniqueId();

    /**
     * If we paste in a copy of the registration, we need to clear the fields
     */
    if (clone.alias === 'registration' && clone.settings) {
      // @ts-ignore
      clone.settings.fields = [];
    }

    // Insert new addon on correct index
    columnData.addons.splice(addonIndex + 1, 0, clone);
    this.parse(columnData);

    await this.saveBlock();

    this.state.addons.forEach((addon, index) => {
      if (index === addonIndex + 1) {
        editingStore.setActiveModel(addon);
      }
    });
  }

  async onDelete() {
    await Promise.all(this.state.addons.map((addon) => addon.onDelete()));
  }

  // Delete column
  async delete() {
    if (this.deleted) {
      return;
    }

    this.deleted = true;

    await this.row.deleteColumn(this.index);
  }

  // Delete addon
  async deleteAddon(addonIndex: number) {
    await this.state.addons[Number(addonIndex)].onDelete();
    const data = this.getData();
    data.addons.splice(addonIndex, 1);
    this.parse(data);
    await this.saveBlock();
  }

  getAddonByIndex(index: number) {
    return this.state.addons[Number(index)];
  }

  async saveBlock() {
    return this.row.section.saveBlock();
  }

  reorderAddons() {
    const data = this.getData();
    data.addons = this.state.addons.map((addon) => {
      // @ts-ignore
      return addon.getData();
    });
    this.parse(data);
  }

  setNewAddonActive(newAddon: NewAddon) {
    const editingStore = useEditingStore();
    editingStore.setActiveModel(newAddon.model);
  }

  async addAddon(addonId: string, addonIndex: number | null) {
    const data = this.getData();
    const newAddonIndex = addonIndex || addonIndex === 0 ? addonIndex + 1 : null;
    const newAddonData = {
      settings: {},
      alias: addonId
    };

    let addonIndexInData: number | null = null;

    if (newAddonIndex || newAddonIndex === 0) {
      addonIndexInData = newAddonIndex;
      data.addons.splice(newAddonIndex, 0, newAddonData);
    } else {
      addonIndexInData = data.addons.length;
      data.addons.push(newAddonData);
    }

    await this.saveBlock();

    // In some addons like registration & sponsors we rely on data
    // that we receive from the server. So in these cases we need to reload
    // the section after saving it.
    await this.getSection().reload();

    return new Promise<ContentAddonType>((resolve) => {
      this.state.addons.forEach((addon, index) => {
        if (index === addonIndexInData) {
          this.setNewAddonActive({
            model: addon,
            id: Number(addon.state.id)
          });

          resolve(addon);
        }
      });
    });
  }

  private static checkForAddons(data: ContentColumnData, alias: string): boolean {
    let hasAddon = false;

    if (data.addons) {
      data.addons.forEach((addon) => {
        if (addon.alias === alias) {
          hasAddon = true;
        }
      });
    }

    return hasAddon;
  }

  private static parseCustomCSSData(data: ContentColumnData, identifier: string): CustomCSSModel | undefined {
    if (data.settings?.advanced?.customcss) {
      const useData = getDeviceData(data.settings.advanced.customcss) as CustomCSS | undefined;

      if (!useData) {
        return undefined;
      }

      if (useData.code && useData.code.length > 0) {
        return ColumnModel.constructCustomCSSState(useData, identifier);
      }

      return undefined;
    }
  }

  private static constructCustomCSSState(data: CustomCSS, identifier: string): CustomCSSModel {
    return new CustomCSSModel(data, identifier);
  }

  public shallSkipInitialParse(): boolean {
    return true;
  }

  public setEditingActive(): void {
    const state = this.state;

    if (state.edit) {
      state.edit.isActive = true;
      state.edit.isCollapsed = false;
    }

    if (this.row.state.edit) {
      this.row.state.edit.isCollapsed = false;
    }

    if (this.row.section.state.edit) {
      this.row.section.state.edit.isCollapsed = false;
    }
  }

  public getAddons<Model extends ContentAddonType = ContentAddonType>(alias: string): Model[] {
    const addonModels: Model[] = [];

    this.state.addons.forEach((addon) => {
      if (addon.alias === alias) {
        addonModels.push(addon as Model);
      }
    });

    return addonModels;
  }

  public get index() {
    return this.row.state.columns.indexOf(this);
  }

  public get rowIndex() {
    return this.row.index;
  }
}
