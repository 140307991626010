<template>
  <component is="style" type="text/css">
    {{ model.state.elementStyling.hoverStyling }}
  </component>
  <div
    ref="buttonContainerRef"
    class="lf-button"
    :style="model.state.elementStyling.align"
    :class="{ [`${model.state.buttonStyle}`]: true, 'lf-button--show-checkmark': showCheckmark }"
  >
    <div v-if="hasCheckmarkUsage" class="lf-button__inner" :style="{ minWidth: buttonInlineStyles.minWidth }">
      <RichTextRenderer
        v-if="!editorLoaded"
        :id="model.state.buttonId"
        class="lf-button__element"
        :is-loading="isDisabled"
        :button-style="model.state.buttonStyle"
        :style="buttonInlineStyles"
        :is-disabled="isDisabled"
        :prevent-stop="model.state.action?.state?.type"
        :extra-replacement-tags="model.column?.replacementTags"
        :html="model.state?.label ?? ''"
        tag="UIButton"
        @click="onButtonClick"
      />

      <component :is="asyncAddonLfButtonEditor" v-if="isEditModeActive && isDemo" :model="model" />

      <div class="lf-button__checkmark" :style="`font-size: ${checkmarkFontSize}; color: ${checkmarkColor};`">
        <span class="fa fa-check"></span>
      </div>
    </div>

    <RichTextRenderer
      v-else-if="!editorLoaded"
      :id="model.state.buttonId"
      class="lf-button__element"
      :is-loading="isDisabled"
      :button-style="model.state.buttonStyle"
      :style="buttonInlineStyles"
      :is-disabled="isDisabled"
      :prevent-stop="model.state.action?.state?.type"
      :extra-replacement-tags="model.column?.replacementTags"
      :html="model.state?.label ?? ''"
      tag="UIButton"
      @click="onButtonClick"
    />
    <component :is="asyncAddonLfButtonEditor" v-if="isEditModeActive && isDemo && !hasCheckmarkUsage" :model="model" />
  </div>
</template>

<script lang="ts">
import type { CSSProperties, PropType } from 'vue';
import { defineAsyncComponent, onBeforeUnmount } from 'vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import hotkeys from 'hotkeys-js';
import type { AddonRegistrationModel } from '../registration/Model';
import type { AddonLfButtonModel } from '@/src/components/addons/lf-button/Model';
import { ActionType, KeyBinding } from '@/src/typings/enums/enums';
import { FormDisableDefaultBehavior } from '@/src/exceptions/FormDisableDefaultBehavior';
import { setFormReplacementTags, submitForm } from '@/src/utilities/Registration';
import RichTextRenderer from '@/src/components/RichTextRenderer.vue';
import useFontObserver from '@/src/hooks/useFontObserver';
import { addDataLayerEvent } from '@/src/utilities/Tracking';
import { useCampaignStore } from '@/src/store/campaign';
import { EmitTipAFriendSubmitEvent } from '@/src/events/tipAFriendSubmit';
import { useUtilityStore } from '@/src/store/utility';

export default defineComponent({
  name: 'AddonLfButton',
  components: { RichTextRenderer },
  inheritAttrs: false,
  props: {
    model: {
      type: Object as PropType<AddonLfButtonModel>,
      required: true
    }
  },
  setup(props) {
    const utilityStore = useUtilityStore();
    const isFormSubmit = ref(false);
    const campaignStore = useCampaignStore();
    const showCheckmark = ref(false);
    const checkmarkColor = ref('#000000');
    const checkmarkFontSize = ref('12px');
    const buttonContainerRef = ref<HTMLDivElement>();
    const editorLoaded = ref(false);

    const isEditModeActive = computed(() => {
      return campaignStore.model?.state.isEditModeActive;
    });

    const isDemo = computed(() => {
      return utilityStore.url.includes('/campaign/view/demo');
    });

    const hasCheckmarkUsage = computed(() => {
      return props.model.state.action?.state.type === ActionType.COPY_TO_CLIPBOARD;
    });

    const isDisabled = computed(() => {
      return isFormSubmit.value || props.model.state.action?.state.evaluating;
    });

    const asyncAddonLfButtonEditor = defineAsyncComponent({
      loader: () =>
        import('@/src/components/addons/lf-button/Editor.vue').then((component) => {
          editorLoaded.value = true;
          return component;
        })
    });

    const onButtonClick = async (event?: Event) => {
      if (isDisabled.value || showCheckmark.value) {
        return;
      }

      // We should only stop propagation if we actually have an action.
      if (event && props.model.state.action && props.model.state.action.state) {
        event.stopPropagation();
      }

      const section = props.model.getSection();

      addDataLayerEvent('button_click', {
        pageId: section.id,
        pageTitle: section.state.title ?? 'Page',
        pageType: section.getSectionType(),
        buttonText: props.model.state.label
      });

      let defaultBehavior = true;

      // We should only attempt to submit registration forms if it's action is manipulating with the flow.
      if (
        props.model.state.action?.state.type &&
        [ActionType.FORCE_SKIP_REGISTRATION, ActionType.GOTO_FLOW_PAGE].includes(props.model.state.action.state.type)
      ) {
        const hasRegistrationAddon = props.model.getSection().getAddons<AddonRegistrationModel>('registration');

        if (hasRegistrationAddon.length > 0 && props.model.state.action?.state.type === ActionType.GOTO_FLOW_PAGE) {
          const section = props.model.getSection();
          isFormSubmit.value = true;

          try {
            await submitForm(section);
            setFormReplacementTags(section);
          } catch (e) {
            if (e instanceof FormDisableDefaultBehavior) {
              defaultBehavior = e.allowContinueInFlow;
            } else {
              // If exception wasn't the exception to disable default behavior we should bubble up the exception.
              throw e;
            }
          }
        }

        const hasTipAFriendAddon = props.model.getSection().getAddons<AddonRegistrationModel>('tipafriend');

        if (hasTipAFriendAddon.length > 0 && props.model.state.action?.state.type === ActionType.GOTO_FLOW_PAGE) {
          const section = props.model.getSection();
          isFormSubmit.value = true;

          try {
            await EmitTipAFriendSubmitEvent(section);
          } catch (e) {
            if (e instanceof FormDisableDefaultBehavior) {
              defaultBehavior = e.allowContinueInFlow;
            } else {
              // If exception wasn't the exception to disable default behavior we should bubble up the exception.
              throw e;
            }
          }
        }
      }

      if (props.model.state.action?.state && defaultBehavior) {
        await props.model.state.action?.triggerAction(props.model, props.model.column?.replacementTags);

        if (props.model.state.action.state.type === ActionType.COPY_TO_CLIPBOARD) {
          if (buttonContainerRef.value) {
            const buttonComputedStyle = window.getComputedStyle(
              buttonContainerRef.value?.querySelector('.btn') ?? buttonContainerRef.value
            );

            checkmarkColor.value = buttonComputedStyle.color;
            checkmarkFontSize.value = buttonComputedStyle.fontSize;
          }

          showCheckmark.value = true;

          window.setTimeout(() => {
            showCheckmark.value = false;
          }, 1500);
        }
      }

      isFormSubmit.value = false;
    };

    const buttonInlineStyles = computed<CSSProperties>(() => {
      const campaignState = campaignStore.model?.state;

      let buttonThickness: number | undefined;

      if (typeof props.model.state.layout?.styles?.normal?.borderWidth === 'undefined') {
        if (
          campaignState?.config?.globalLayout &&
          typeof campaignState.globalLayout?.buttons?.borderThickness === 'number'
        ) {
          buttonThickness = campaignState.globalLayout?.buttons?.borderThickness;
        } else if (
          typeof campaignState?.layout?.buttons?.borderThickness === 'number' &&
          !campaignState.config?.globalLayout
        ) {
          buttonThickness = campaignState.layout.buttons.borderThickness;
        }
      }

      return {
        ...props.model.state.elementStyling.buttonInlineStyle,
        ...props.model.state.elementStyling.normal,
        ...(typeof buttonThickness === 'number' && {
          ...(buttonThickness === 0 && {
            borderStyle: 'none'
          }),
          borderWidth: `${buttonThickness}px`
        })
      };
    });

    const onKeybindingPress = (e: KeyboardEvent) => {
      onButtonClick(e);
    };

    const unbindKeybindings = () => {
      hotkeys.unbind('escape', onKeybindingPress);
      hotkeys.unbind('enter', onKeybindingPress);
    };

    const initKeybindings = () => {
      // Unbind the keybinding before we bind new ones.
      unbindKeybindings();

      if (props.model.state.action?.state.keybinding) {
        switch (props.model.state.action?.state.keybinding) {
          case KeyBinding.ENTER:
            hotkeys('enter', onKeybindingPress);
            break;

          case KeyBinding.ESCAPE:
            hotkeys('escape', onKeybindingPress);
            break;
        }
      }
    };

    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    onMounted(async () => {
      const { isFontLoaded } = useFontObserver();

      if (props.model.state.layout?.styles?.fontFamily) {
        await isFontLoaded(props.model.state.layout.styles.fontFamily);
      }

      if (readyPromiseResolve) {
        readyPromiseResolve();
      }

      await props.model.state.action?.startAutoTrigger(onButtonClick);
    });

    onBeforeUnmount(unbindKeybindings);

    watch(
      () => isEditModeActive.value,
      () => {
        editorLoaded.value = isEditModeActive.value ?? false;
      }
    );

    watch(() => props.model.state.action?.state?.keybinding, initKeybindings, { immediate: true });

    return {
      asyncAddonLfButtonEditor,
      editorLoaded,
      isEditModeActive,
      isDemo,
      isDisabled,
      showCheckmark,
      buttonInlineStyles,
      onButtonClick,
      buttonContainerRef,
      hasCheckmarkUsage,
      checkmarkColor,
      checkmarkFontSize,
      onBeforeEnter: async () => {
        await readyPromise;
      }
    };
  }
});
</script>

<style lang="scss">
.lf-button {
  &__inner {
    display: inline-block;
    position: relative;
  }

  &__checkmark {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 175ms linear;
    pointer-events: none;
  }

  &--show-checkmark {
    .lf-button__checkmark {
      opacity: 1;
    }

    .btn {
      animation: button-checkmark-color 175ms linear;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes button-checkmark-color {
  to {
    color: transparent;
  }
}
</style>
