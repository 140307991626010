import type { RepeatableData } from '@/src/store/repeatable';
import { useRepeatableStore } from '@/src/store/repeatable';
import { shuffle } from '@/src/utilities/Utilities';
import type { RepeatableModel } from '@/src/models/settings/RepeatableModel';

type RepeatableHandler = (model: RepeatableModel) => Promise<RepeatableData[]>;

interface RepeatableHandlers {
  [key: string]: RepeatableHandler;
}

const handlers: RepeatableHandlers = {};

const sort = (rows: RepeatableData[], model: RepeatableModel): RepeatableData[] => {
  if (!model.state.sort?.by) {
    return rows;
  }

  // This can't be an enum as when not sorting by random it's the actual column name of the column it should sort by.
  if (model.state.sort.by === '_rnd') {
    return shuffle<RepeatableData>(rows);
  }

  const sortedData = rows.sort((a, b) => {
    if (!model.state.sort?.by) {
      return 0;
    }

    const sortByValueA = a[model.state.sort.by + '_sorting'] ?? a[model.state.sort.by];
    const sortByValueB = b[model.state.sort.by + '_sorting'] ?? b[model.state.sort.by];

    switch (model.state.sort.direction ?? 'desc') {
      case 'desc':
        if (typeof sortByValueA === 'number' && typeof sortByValueB === 'number') {
          return sortByValueB - sortByValueA;
        }

        if (sortByValueA > sortByValueB) {
          return -1;
        }

        if (sortByValueA < sortByValueB) {
          return 1;
        }
        break;

      case 'asc':
        if (typeof sortByValueA === 'number' && typeof sortByValueB === 'number') {
          return sortByValueA - sortByValueB;
        }

        if (sortByValueA < sortByValueB) {
          return -1;
        }

        if (sortByValueA > sortByValueB) {
          return 1;
        }
        break;
    }

    return 0;
  });

  return sortedData;
};

const limit = (rows: RepeatableData[], model: RepeatableModel): RepeatableData[] => {
  if (model.state.limit && !isNaN(model.state.limit) && model.state.limit > 0) {
    return rows.slice(0, model.state.limit);
  }

  return rows;
};

/**
 * Register a new repeatable handler.
 */
export const registerRepeatable = async (type: string, handler: RepeatableHandler) => {
  handlers[`${type}`] = handler;
  await updateRepeatable(type);
};

/**
 * Update a repeatable type. This will find all registered repeatable and re-fetch their data.
 * Mostly used by games when their data set changes.
 */
export const updateRepeatable = async (type: string) => {
  const repeatableStore = useRepeatableStore();
  const repeatableData = repeatableStore.data;

  let updatedData = false;

  if (Object.keys(repeatableData).length > 0) {
    for (const repeatableDataKey in repeatableData) {
      if (
        Object.prototype.hasOwnProperty.call(repeatableData, repeatableDataKey) &&
        repeatableData[`${repeatableDataKey}`] &&
        repeatableData[`${repeatableDataKey}`].model.state.type === type
      ) {
        const type = repeatableData[`${repeatableDataKey}`].model.state.type;

        repeatableData[`${repeatableDataKey}`].data =
          type && typeof handlers[`${type}`] !== 'undefined'
            ? await handlers[`${type}`](repeatableData[`${repeatableDataKey}`].model)
            : [];

        updatedData = true;
      }
    }
  }

  if (updatedData) {
    repeatableStore.data = repeatableData;
  }
};

/**
 * Get the repeatable data from it's settings.
 */
export const getRepeatableData = (identifier: string, model: RepeatableModel): RepeatableData[] => {
  if (!model.state.type) {
    return [];
  }

  const repeatableStore = useRepeatableStore();
  const repeatableData = repeatableStore.data;

  if (typeof repeatableData[`${identifier}`] === 'undefined') {
    repeatableData[`${identifier}`] = {
      model,
      data: []
    };

    repeatableStore.data = repeatableData;

    if (typeof handlers[model.state.type] !== 'undefined') {
      handlers[model.state.type](model).then((data) => {
        repeatableData[`${identifier}`].data = data;
        repeatableStore.data = repeatableData;
      });
    }
  }

  const data: RepeatableData[] =
    typeof repeatableData[`${identifier}`] !== 'undefined' ? repeatableData[`${identifier}`].data : [];

  // Sort & limit the data. First we sort.. then we limit.
  return limit(sort(data, model), model);
};
