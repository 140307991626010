import { useCampaignStore } from '@/src/store/campaign';
import useAxios from '@/src/hooks/useAxios';

// Polyfill for browser API that is not supported in all browsers
import 'navigator.locks';

interface TrackedObject {
  object?: string;
  object_value?: number | string;
  created_on?: number;
  inSession?: boolean;
  distinct?: boolean;
  entries?: TrackedObject[];
  expire?: number;
}

declare global {
  interface Window {
    api_prefix: string;
  }
}

let firstInit = true;

// collection of entries tracked outside the session
const trackedOutsideSession: TrackedObject[] = [];

// lead score tracking session duration in minutes
const sessionDuration = 30;

// Fetch current leadscore session information
let leadscoreSession: TrackedObject | null = null;

export default function useLeadScore() {
  const campaignStore = useCampaignStore();

  const initLeadScore = async () => {
    try {
      let sessionFromStorage: string | null = null;

      if (campaignStore.model?.state.config && !firstInit && window.localStorage) {
        sessionFromStorage = window.localStorage.getItem(`leadscore-${campaignStore.model?.id}`);
      }

      // If leadscore session information was found, parse it
      if (sessionFromStorage !== null && sessionFromStorage) {
        leadscoreSession = JSON.parse(sessionFromStorage);
      } else {
        // If no session was found, create one with an expiration
        leadscoreSession = {
          expire: new Date().getTime() + 1000 * 60 * sessionDuration,
          entries: []
        };
        firstInit = false;
        writeSessionStorage();
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      // If exception occur
      leadscoreSession = {
        expire: new Date().getTime() + 1000 * 60 * sessionDuration,
        entries: []
      };
    }
  };

  const checkSessionExpiration = () => {
    // If leadscore session expires.. create a new one
    if (leadscoreSession && leadscoreSession.expire) {
      if (new Date().getTime() >= leadscoreSession.expire) {
        leadscoreSession = {
          expire: new Date().getTime() + 1000 * 60 * sessionDuration,
          entries: []
        };

        writeSessionStorage();
      }
    }
  };

  // Helper method for checking if a object & object value is distinct
  const isDistinct = (object: string, object_value: number | string, collection: TrackedObject[]) => {
    return !collection.some((entry) => entry.object === object && entry.object_value === object_value);
  };

  const writeSessionStorage = () => {
    try {
      if (
        campaignStore.model?.id &&
        campaignStore.model?.state.config &&
        campaignStore.model?.state.config.leadScoreEnabled &&
        window.localStorage
      ) {
        window.localStorage.setItem(`leadscore-${campaignStore.model.id}`, JSON.stringify(leadscoreSession));
      }
    } catch (e) {
      throw new Error('cant set item in storage: ' + e);
    }
  };

  const optionsIsDistinct = (options: TrackedObject): boolean => {
    if (options.distinct && options.object_value && options.object) {
      if (!options.inSession && !isDistinct(options.object, options.object_value, trackedOutsideSession)) {
        return false;
      } else if (
        leadscoreSession &&
        leadscoreSession.entries &&
        options.inSession &&
        !isDistinct(options.object, options.object_value, leadscoreSession.entries)
      ) {
        return false;
      }
    }
    return true;
  };

  const track = async (options: TrackedObject, disableSendTracked = false, failureCallback?: () => void) => {
    if (!campaignStore.model?.state?.config?.leadScoreEnabled) {
      return;
    }

    await initLeadScore();

    let trackedObjectSent = false;
    let trackedObject: TrackedObject = {};

    options = options || {};

    // Set default values in options
    options.inSession = options.inSession || false;
    options.object = options.object || '';
    options.object_value = options.object_value || undefined;
    options.distinct = options.distinct || false;

    const optionsDistinct = optionsIsDistinct(options);

    if (optionsDistinct) {
      trackedObject = {
        object: options.object,
        object_value: options.object_value,
        created_on: Math.floor(new Date().getTime() / 1000)
      };

      // If it's not to be saved in session.. save it in memory
      if (!options.inSession) {
        trackedOutsideSession.push(trackedObject);
      } else {
        // If we're to save it in session, first check if session expired
        // & if not write to session storage.
        checkSessionExpiration();

        leadscoreSession?.entries?.push(trackedObject);

        writeSessionStorage();
      }

      if (trackedObject.object && ['FLOW_PAGE', 'ADDON'].includes(trackedObject.object) && !disableSendTracked) {
        // Use browser lock to ensure that the tracked object is sent before any other request.
        // This is to avoid leadscore being granted twice because it sent x2 requests in the same
        // milisecond.
        await navigator.locks.request(`leadscore-${campaignStore.model?.id}`, async () => {
          trackedObjectSent = await sendTracked();
        });

        if (
          !trackedObjectSent &&
          campaignStore.flowRegistrationInfo &&
          campaignStore.flowRegistrationInfo?.id &&
          campaignStore.model?.state.config?.leadScoreEnabled
        ) {
          if (failureCallback) {
            failureCallback();
          }

          Error('something went wrong with sending the tracked object');
        }
      }
    }
  };

  const sendTracked = async () => {
    if (
      !campaignStore.flowRegistrationInfo ||
      !campaignStore.flowRegistrationInfo?.id ||
      !campaignStore.model?.state.config?.leadScoreEnabled
    ) {
      return false;
    }

    const postUrl = `${
      campaignStore.model?.state?.config?.campaignApiRoot ?? ''
    }/api/v1/campaign/leadscore?r=${new Date().getTime()}`;

    const postData = {
      campaign_id: campaignStore.model?.id,
      customer_id: campaignStore.model?.state.config?.customerId,
      registration_id: campaignStore.flowRegistrationInfo?.id,
      tracking: getTracked()
    };

    const { postDataFormData } = useAxios(postUrl, postData);
    let response;

    try {
      response = await postDataFormData();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return false;
    }

    return !!response;
  };

  const getTracked = () => {
    const collective: TrackedObject[] = [];

    trackedOutsideSession.forEach((localTracked) => {
      collective.push(localTracked);
    });

    if (leadscoreSession?.entries) {
      leadscoreSession?.entries.forEach((sessionTracked) => {
        collective.push(sessionTracked);
      });
    }

    collective.sort((a, b) => {
      if (a && b && a.created_on && b.created_on && a.created_on < b.created_on) {
        return -1;
      } else if (a && b && a.created_on && b.created_on && a.created_on > b.created_on) {
        return 1;
      }

      return 0;
    });

    return collective;
  };

  return {
    track,
    getTracked,
    sendTracked
  };
}
