import type {
  BaseFormElementData,
  BaseFormElementSettingsData,
  BaseFormElementState
} from '@/src/components/addons/registration/BaseFormElementModel';
import { BaseFormElementModel } from '@/src/components/addons/registration/BaseFormElementModel';

export type FormElementCaptchaSettingsData = BaseFormElementSettingsData;

export interface FormElementCaptchaData extends BaseFormElementData {
  settings?: FormElementCaptchaSettingsData;
}

export type FormElementCaptchaValue = {
  captcha: string;
  key: string;
};

export type FormElementCaptchaState = BaseFormElementState<FormElementCaptchaValue>;

export class FormElementCaptchaModel extends BaseFormElementModel<
  FormElementCaptchaValue,
  FormElementCaptchaData,
  FormElementCaptchaState
> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseFormElement(data: FormElementCaptchaData) {
    // No settings available we need to handle
  }

  getInitialValue(): undefined {
    return undefined;
  }

  getStringifiedValue(): string | undefined {
    return this.state.value ? `${this.state.value.key}, ${this.state.value.captcha}` : undefined;
  }

  parseStringValue(): undefined {
    return undefined;
  }

  getSerializedPostValue(): FormElementCaptchaValue | string {
    return this.state.value ?? '';
  }

  getSerializedCookieValue(): string {
    return typeof this.state.value === 'object' ? JSON.stringify(this.state.value) : '';
  }

  getValidationRules(): string[] {
    const rules = super.getValidationRules();

    if (!rules.includes('required')) {
      rules.push('required');
    }

    return rules;
  }

  authorSignature(): string {
    return 'Nicky Christensen';
  }
}
